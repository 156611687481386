import React, { useEffect, useContext, useRef } from 'react'

import { ColsContext } from 'src/hooks/cols/context'

import Polaris from 'src/lib/Polaris'

import styles from './checker.module.scss'


const Checker = (props) => {

    const { setCols } = useContext(ColsContext)

    const wrapper = useRef(null)

    useEffect(() => {

        const computedStyle = window.getComputedStyle(wrapper.current, '::before')

        let cols = null

        Polaris.util.onResize(() => {
            if (computedStyle.getPropertyValue('font-family').match(/^cols([\d]+)$/)) {

                const _cols = parseInt(RegExp.$1)

                if (cols !== _cols) {
                    cols = _cols
                    setCols(_cols)
                }
            }
        })
    }, [setCols])

    return (
        <div ref={wrapper} className={styles.checker}>
            {props.children}
        </div>
    )
}

export default Checker
