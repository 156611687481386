import util from '../../../shaders/utility.glsl'

export default `

uniform vec2 resolution;
uniform sampler2D typoTexture;
uniform sampler2D heightmap;
uniform float deltaY;

${util}

float minResolution = min(resolution.x, resolution.y);
float maxResolution = max(resolution.x, resolution.y);


vec3 calcNormal(vec3 v0, vec3 v1, vec3 v2) {
    return cross(v1 - v0, v2 - v0);
}

vec4 raytrace(vec2 waterUV, vec2 typoUV) {
    float step = 1.0 / 512.0;

    vec3 vc = texture2D( heightmap, waterUV ).xyz;
    vec3 vn = texture2D( heightmap, waterUV + vec2( 0.0, + step ) ).xyz;
    vec3 vs = texture2D( heightmap, waterUV + vec2( 0.0, - step ) ).xyz;
    vec3 ve = texture2D( heightmap, waterUV + vec2( + step, 0.0 ) ).xyz;
    vec3 vw = texture2D( heightmap, waterUV + vec2( - step, 0.0 ) ).xyz;

    // vec3 n1 = calcNormal(vc, vn, ve);
    // vec3 n2 = calcNormal(vc, ve, vs);
    // vec3 n3 = calcNormal(vc, vs, vw);
    // vec3 n4 = calcNormal(vc, vw, vn);
    // vec3 normal = normalize((n1 + n2 + n3 + n4) / 4.0);

    vec3 normal = normalize(vec3( 2.0 * (ve.z - vw.z), 2.0 * (vs.z - vn.z), -4.0));

    vec2 uv = vec2( typoUV.x + normal.x * 0.1, typoUV.y + normal.y * 0.1 );

    float shine = 0.8 * dot(-normal, vec3(1.0, 1.0, 1.0));
    float alpha = pow(shine, 12.0);
    float white = sin(alpha);

    return blend( vec4( white, white, white, alpha ), texture2D( typoTexture, uv ) );
}

void main() {
    vec2 pos = (gl_FragCoord.xy * 2.0 - resolution) / minResolution;
    vec2 typoUV = gl_FragCoord.xy / resolution;
    vec2 waterUV = gl_FragCoord.xy / maxResolution;

    typoUV.y += deltaY;

    gl_FragColor = blend(raytrace( waterUV, typoUV ), vec4(0.0, 0.0, 0.0, 1.0));
}
`