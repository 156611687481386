
import * as THREE from 'three'

import gsap from 'gsap'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js'

const defaultProps = {
    // プレイリスト表示時の縮小倍率
    playlist_size: 0.5,

    // カメラ設定
    fov: 45,
    near: 0.1,
    far: 10000
}

class AbstractAnimation {

    constructor(renderer, props = {}) {

        this.props = Object.assign(Object.assign({}, defaultProps), props)

        this.camera = new THREE.PerspectiveCamera(this.props.fov, 1, this.props.near, this.props.far)
        this.scene = new THREE.Scene()

        this.offscreen = new THREE.WebGLRenderTarget(1, 1, {
            magFilter: THREE.LinearFilter,
            minFilter: THREE.LinearFilter
        })

        this.composer = new EffectComposer(renderer, this.offscreen)
        this.renderPass = new RenderPass(this.scene, this.camera, undefined, 0x000001, 1)
        this.fxaaPass = new ShaderPass(FXAAShader)
        this.composer.renderToScreen = false

        this.demoTimeline = null
        this.mainTimeline = null
        this.openTimeline = null
    }

    get texture() {
        return this.composer.readBuffer.texture
    }

    startMain() {
        this.demoTimeline.pause().kill()
        return this.mainTimeline.play(0)
    }

    startDemo() {
        this.mainTimeline.then(() => {
            this.demoTimeline.play(0)
        })
        // this.mainTimeline.pause().kill()
        // return this.demoTimeline.play(0)
    }

    startOpening() {
        this.openTimeline.play()
    }

    kill() {
        this.mainTimeline.pause().seek(0).kill()
        this.demoTimeline.pause().seek(0).kill()
        this.openTimeline.pause().seek(0).kill()
    }

    pauseMain() {
        this.mainTimeline.pause()
    }

    pauseDemo() {
        this.demoTimeline.pause()
    }

    pauseOpening() {
        this.openTimeline.pause()
    }

    resumeMain() {
        this.mainTimeline.resume()
    }

    resumeDemo() {
        this.demoTimeline.resume()
    }

    resize(width, height) {
        this.canvasW = width
        this.canvasH = height
        
        this.camera.aspect = width / height
        this.camera.updateProjectionMatrix()

        this.fxaaPass.uniforms.resolution.value.x = 1 / width
        this.fxaaPass.uniforms.resolution.value.y = 1 / height

        this.composer.setSize(width, height)
    }

    render(deltaTime, time) {
        this.composer.render()
    }
    
    tween(object, props1, props2) {
        if (props2) {
            return gsap.fromTo(object, props1, props2)
        } else {
            return gsap.to(object, props1)
        }
    }
}

export default AbstractAnimation