import * as THREE from 'three'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'

import caustics_vert from './shaders/caustics_vert.glsl'
import caustics_frag from './shaders/caustics_frag.glsl'


class Caustics {

    SIZE = 512

    constructor(renderer, size) {
        this.camera = new THREE.PerspectiveCamera(45, 1, 1, 100)
        this.scene = new THREE.Scene()

        this.camera.position.set(0, 0, 10)
        this.camera.lookAt(0, 0, 0)

        this.offscreen = new THREE.WebGLRenderTarget(1, 1, {
            magFilter: THREE.LinearFilter,
            minFilter: THREE.LinearFilter
        })

        this.composer = new EffectComposer(renderer, this.offscreen)
        this.renderPass = new RenderPass(this.scene, this.camera)

        this.composer.setSize(size, size)
        this.composer.addPass(this.renderPass)
        this.composer.renderToScreen = false

        // コースティクス表示オブジェクト
        this.geometry = new THREE.PlaneBufferGeometry(2, 2)

        this.material = new THREE.ShaderMaterial({
            uniforms: {
                refraction: { value: 1.0 / 1.333 },
                time: { value: 0 },
            },
            transparent: true,
            vertexShader: caustics_vert,
            fragmentShader: caustics_frag
        })

        this.object = new THREE.Mesh(this.geometry, this.material)
        this.object.scale.y = (this.camera.position.z - this.object.position.z) * Math.tan(this.camera.fov / 2 / 180 * Math.PI)
        this.object.scale.x = this.object.scale.y
        this.scene.add(this.object)
        
        this.composer.render()
    }

    get texture() {
        return this.composer.readBuffer.texture
    }

    set time(value) {
        this.material.uniforms.time.value = value
    }

    get time() {
        return this.material.uniforms.time.value
    }


    update() {
        this.composer.render()
    }
}

export default Caustics