import * as THREE from 'three'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

import BlurShaderH from '../../shaders/blur/BlurShaderH'
import BlurShaderV from '../../shaders/blur/BlurShaderV'

const ScaleShader = {
    uniforms: {
        tDiffuse: { value: null },
        scale: { value: 1.0 },
    },
    vertexShader: `
        varying vec2 vUv;
        void main() {
            vUv = uv;
            gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }
    `,
    fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform float scale;
        varying vec2 vUv;

        void main() {
            gl_FragColor = texture2D(tDiffuse, vec2(0.5) + (vUv - vec2(0.5)) / scale);
        }
    `
}


class Back {

    constructor(renderer, texture) {
        this.camera = new THREE.PerspectiveCamera(45, 1, 1, 3)
        this.scene = new THREE.Scene()

        this.camera.position.set(0, 0, 2)
        this.camera.lookAt(0, 0, 0)

        this.offscreen = new THREE.WebGLRenderTarget(1, 1, {
            magFilter: THREE.LinearFilter,
            minFilter: THREE.LinearFilter
        })

        this.composer = new EffectComposer(renderer, this.offscreen)
        this.renderPass = new RenderPass(this.scene, this.camera)
        this.scalePass = new ShaderPass(ScaleShader)
        this.blurPassH = new ShaderPass(BlurShaderH)
        this.blurPassV = new ShaderPass(BlurShaderV)

        this.composer.addPass(this.renderPass)
        this.composer.addPass(this.scalePass)
        this.composer.addPass(this.blurPassH)
        this.composer.addPass(this.blurPassV)
        this.composer.renderToScreen = false

        this.temp = new THREE.Mesh(new THREE.PlaneBufferGeometry(2, 2), new THREE.MeshBasicMaterial({
            map: texture,
            depthTest: false,
            depthWrite: false,
            transparent: true
        }))

        this.scene.add(this.temp)

        this.blur = 1
        this.scale = 1

        // 描画結果
        this.object = new THREE.Mesh(new THREE.PlaneBufferGeometry(2, 2), new THREE.MeshBasicMaterial({
            depthTest: false,
            depthWrite: false,
            transparent: true
        }))
    }

    set blur(radius) {
        this._blur = radius

        const weight = new Array(10);

        let t = 0.0;
        let d = radius * radius / 100;

        for (let i = 0; i < 10; i++) {
            var r = 1.0 + 2.0 * i;
            var w = Math.exp(-0.5 * (r * r) / d);
            weight[i] = w;
            if (i > 0) { w *= 2.0; }
            t += w;
        }
        for (let i = 0; i < 10; i++) {
            weight[i] /= t;
        }

        this.blurPassH.uniforms.radius.value = radius
        this.blurPassV.uniforms.radius.value = radius

        this.blurPassH.uniforms.weight.value = weight
        this.blurPassV.uniforms.weight.value = weight

        this.needsUpdate = true
    }

    get blur() {
        return this._blur;
    }

    set scale(val) {
        this._scale = val
        this.scalePass.uniforms.scale.value = val
        this.needsUpdate = true
    }

    get scale() {
        return this._scale
    }

    update(width, height, camera) {
        this.camera.aspect = width / height
        this.camera.updateProjectionMatrix()
        this.composer.setSize(width, height)

        this.blurPassH.uniforms.resolution.value.x = this.blurPassV.uniforms.resolution.value.x = width
        this.blurPassH.uniforms.resolution.value.y = this.blurPassV.uniforms.resolution.value.y = height

        const _t = this.camera.position.z * Math.tan(this.camera.fov / 2 / 180 * Math.PI)
        this.temp.scale.y = _t
        this.temp.scale.x = _t * this.camera.aspect

        const _h = (camera.position.z - this.object.position.z) * Math.tan(camera.fov / 2 / 180 * Math.PI)
        this.object.scale.y = _h
        this.object.scale.x = _h * this.camera.aspect

        this.needsUpdate = true
    }

    render() {
        if (this.needsUpdate) {
            this.composer.render()
            this.object.material.map = this.composer.readBuffer.texture
            this.needsUpdate = false
        }
    }
}

export default Back