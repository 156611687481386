/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const React = require("react")

const ColsProvider = require('./src/hooks/cols/provider').default
const CursorProvider = require('./src/hooks/cursor/provider').default
const StatesProvider = require('./src/hooks/states/provider').default

const Checker = require('./src/components/cols/checker').default
const Header = require('./src/components/header/index').default
const Visual = require('./src/components/visual/index').default
const Cursor = require('./src/components/cursor/index').default

exports.wrapRootElement = ({ element }) => {
    return (
        <ColsProvider>
            <StatesProvider>
                <CursorProvider>
                    {element}
                </CursorProvider>
            </StatesProvider>
        </ColsProvider>
    )
}


exports.wrapPageElement = ({ element }) => {
    return (
        <Checker>
            <Cursor>
                <Visual />
                <Header />
                {element}
            </Cursor>
        </Checker>
    )
}

// exports.replaceComponentRenderer = ({ props }) => {

//     const { component } = props.pageResources

//     return (
//         <Transition location={props.location}>
//             {React.createElement(component, props)}
//         </Transition>
//     )
// }