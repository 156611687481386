import * as THREE from 'three'

import gsap from 'gsap'

import Polaris from 'src/lib/Polaris'

import AbstractAnimation from '../abstract'

import Char from '../char'


class Type16 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer)

        this.scene.fog = new THREE.Fog(0x000000, 2, 4)

        this.composer.addPass(this.renderPass)

        const tanka = [
            `君や来し`,
            `我や行きけむ`,
            `思ほえず`,
            `夢かうつつか`,
            `寝てかさめてか`
        ]

        this.chars = tanka.reduce((chars, line, i) => {

            line.split('').forEach((text, j) => {
                const size = text.match(/^[ぁ-んー　]*$/) ? 0.1 : 0.2
                const char = new Char(text, { size: size, height: 0, face: { color: 0xffffff, opacity: 1.0 }, line: { color: 0xffffff, opacity: 0.2 } })

                const qx = new THREE.Quaternion()
                const qy = new THREE.Quaternion()
                const qz = new THREE.Quaternion()

                qx.setFromAxisAngle(new THREE.Vector3(1, 0, 0), -Math.PI / 2)
                qy.setFromAxisAngle(new THREE.Vector3(0, 1, 0), Polaris.util.rand(-60, 60) / 180 * Math.PI)
                qz.setFromAxisAngle(new THREE.Vector3(0, 0, 1), Polaris.util.rand(-60, 60) / 180 * Math.PI)

                char.object.quaternion.multiply(qz)
                char.object.quaternion.multiply(qy)
                char.object.quaternion.multiply(qx)

                char.x = Polaris.util.rand(-700, +700) / 1000
                char.z = Polaris.util.rand(-700, +700) / 1000

                this.scene.add(char.object)

                chars.push(char)
            })

            return chars
        }, [])

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createMainTimeline() {
        return gsap.timeline({ paused: true }).add(() => {
            this.chars.forEach((char) => {
                char.y = -1.500
                char.opacity = 0
            })
        }).add([
            this.chars.map((char, i) => {
                return gsap.timeline().delay(i * 0.4).add([
                    this.tween(char, { y: 0.200, duration: 6, ease: `sine.out` }),
                    this.tween(char, { opacity: 1, duration: 2, ease: `power2.in` })
                ]).add([
                    this.tween(char, { y: 0.100, duration: 4, ease: `sine.inOut` })
                ]).add([
                    this.tween(char, { y: 0.999, duration: 6, ease: `sine.in` }),
                    this.tween(char, { opacity: 0, delay: 4, duration: 2, ease: `power2.in` })
                ])
            })
        ])
    }

    createDemoTimeline() {
        return this.createMainTimeline().repeat(-1)
    }

    createOpenTimeline() {
        return gsap.timeline({ paused: true }).add(() => {
            this.chars.forEach((char) => {
                char.y = - 0.1 * Math.random()
                char.opacity = 1
            })
        }).add([
            this.chars.map((char, i) => {
                return gsap.timeline().add([
                    this.tween(char, { y: 0.2 * Math.random(), duration: 2, ease: `sine.inOut` }),
                ])
            })
        ])
    }

    resize(width, height) {
        const scale = width > height ? 1.0 : 1.5

        this.camera.position.set(0, 1.500 * scale, 2.000 * scale)
        this.camera.far = 4 * scale

        this.scene.fog.near = 2 * scale
        this.scene.fog.far = 4 * scale

        this.camera.lookAt(0, 0, 0)

        super.resize(width, height)
    }
}

export default Type16