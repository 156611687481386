import noise from '../../../shaders/noise.glsl'

export default `
uniform int mode;
uniform float offset;

${noise}

void main() {
    vec2 uv = gl_FragCoord.xy / resolution.xy;

    // float idParticle = uv.y * resolution.x + uv.x;

    vec3 pos = texture2D( texturePosition, uv ).xyz;
    vec3 vel = texture2D( textureVelocity, uv ).xyz;

    if (mode == 0) {
        vel = vec3(-1.0, 0.0, 0.0);
    } else {
        vec3 p = vec3( pos.x + offset, pos.y, fbm3(pos) );
        vel += 1.0 * curlNoise( p * 0.02 );
        vel *= 0.95;
        vel.x -= 0.05;
    }

    gl_FragColor = vec4( vel, 1.0 );
}
`