import * as THREE from 'three'

import gsap from 'gsap'

import Polaris from 'src/lib/Polaris'

import AbstractAnimation from '../abstract'

import Typography from './typography'

import WAVE from './wave2.json'


class Type11 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer)

        const size = Math.min(2048, Math.max(window.screen.availWidth, window.screen.availHeight) * Polaris.device.pixelRatio)

        this.scene.fog = new THREE.Fog(0x000000, 1.5, 3.5)

        this.composer.addPass(this.renderPass)

        this.typography = new Typography(renderer, size, size)
        this.typography.object.scale.set(1, 1, 1)
        this.typography.object.rotation.x = -Math.PI / 2
        this.scene.add(this.typography.object)

        this.waveIndex = 0

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createMainTimeline() {
        return gsap.timeline({ paused: true }).add(() => {
            for (let j = 0; j < 512; j++) {
                for (let i = 0; i < 512; i++) {
                    this.typography.geometry.attributes.position.array[(512 * 3) * (512 - j - 1) + (i * 3 + 2)] = 0
                }
            }
        }).add([
            this.tween(this.typography.object.position, { x: 0, duration: 30, ease: `linear`, onUpdate: this.update.bind(this) })
        ])
    }

    createDemoTimeline() {
        return this.createMainTimeline().repeat(-1)
    }

    createOpenTimeline() {
        return this.createMainTimeline()
    }

    update() {
        for (let j = 0; j < 512; j++) {
            for (let i = 0; i < 512; i++) {
                this.typography.geometry.attributes.position.array[(512 * 3) * (512 - j - 1) + (i * 3 + 2)] = this.typography.geometry.attributes.position.array[(512 * 3) * (512 - j - 2) + (i * 3 + 2)]
            }
        }

        for (let i = 0; i < 512; i++) {
            this.typography.geometry.attributes.position.array[i * 3 + 2] = WAVE[this.waveIndex][i] * 0.001
        }

        this.typography.geometry.attributes.position.needsUpdate = true

        this.waveIndex = (this.waveIndex + 1) % WAVE.length
    }

    resize(width, height) {
        const scale = width > height ? 1.0 : 1.7

        this.camera.position.y = 0.700 * scale
        this.camera.position.z = 1.400 * scale
        this.camera.far = 3.5 * scale
        this.camera.lookAt(0, 0, 0)

        this.scene.fog.near = 1.5 * scale
        this.scene.fog.far = 3.5 * scale

        super.resize(width, height)

        this.typography.update()
    }
}

export default Type11