import noise from '../../../shaders/noise.glsl'

export default `
uniform sampler2D tDiffuse;
uniform float seed;
uniform float time;

varying vec2 vUv;

${noise}

void main() {
    float nx = 0.01 * fbm3(7.0 * vUv + 0.15 * time + seed);
    float ny = 0.0;

    gl_FragColor = texture2D(tDiffuse, vUv + vec2(nx, ny));
}

`