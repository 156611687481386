export default `

void main() {
    vec2 cellSize = 1.0 / resolution.xy;

    vec2 uv = gl_FragCoord.xy / resolution.xy;

    vec4 height = texture2D( textureWater, uv );

    height += texture2D( textureWater, uv + vec2( 0.0, + cellSize.y ) );
	height += texture2D( textureWater, uv + vec2( 0.0, - cellSize.y ) );
	height += texture2D( textureWater, uv + vec2( + cellSize.x, 0.0 ) );
    height += texture2D( textureWater, uv + vec2( - cellSize.x, 0.0 ) );

	gl_FragColor = height / 5.0;
}
`