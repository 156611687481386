export default `
uniform float uvRatio;
uniform sampler2D positionTexture;
uniform sampler2D previousTexture;

void main() {
    vec4 pos;

    if (position.z == 0.0) {
        pos = texture2D( positionTexture, position.xy * uvRatio );
    } else {
        pos = texture2D( previousTexture, position.xy * uvRatio ) + vec4(1.0, 0.0, 0.0, 0.0);
    }

    gl_Position = projectionMatrix * modelViewMatrix * vec4( pos.xyz, 1.0 );
}
`