import React, { useRef, useEffect, useContext, useCallback } from 'react'

import gsap from 'gsap'

import usePrev from 'src/hooks/usePrev'

import $ from 'jquery'

import { useLocation } from "@reach/router"
import { StatesContext } from 'src/hooks/states/context'
import { CursorContext } from 'src/hooks/cursor/context'

import Config from 'src/lib/visual/config'

import Pointer from './pointer'

import styles from './index.module.scss'


const Wrapper = ({ children }) => {

    const pointer = useRef()

    const timer = useRef(null)

    const toppage = useRef(false)

    // マウスポインタの位置を格納
    const param = useRef({
        cx: null,
        cy: null,
        tx: null,
        ty: null
    })

    const { pathname } = useLocation()

    const { finished, setSleep } = useContext(StatesContext)

    const { setHover, setText } = useContext(CursorContext)

    const prevPathname = usePrev(pathname)


    const startSleepTimer = useCallback(() => {
        clearTimeout(timer.current)
        setSleep(false)

        if (toppage.current) {
            timer.current = setTimeout(() => {
                setSleep(true)
            }, 3000)
        }
    }, [setSleep])


    // トップページ判定
    useEffect(() => {
        toppage.current = RegExp(`^/(index.html)?$`).test(pathname)
    }, [pathname])


    // 初回
    useEffect(() => {
        if (finished) {
            startSleepTimer()
            setHover(false)
        }
    }, [finished, setHover, startSleepTimer])


    // ページ遷移でスリープタイマー開始
    useEffect(() => {
        if (pathname !== prevPathname && finished) {
            startSleepTimer()
            setHover(false)
        }
    }, [finished, pathname, prevPathname, setHover, startSleepTimer])


    // マウスイベント登録
    useEffect(() => {
        if (finished) {
            const mouseMove = (e) => {
                param.current.tx = e.clientX
                param.current.ty = e.clientY
                startSleepTimer()
            }

            const mouseLeave = (e) => {
                param.current.tx = null
                param.current.ty = null
                setHover(false)
            }

            $(document.body).on('mousemove', mouseMove)
            $(document.body).on('mouseleave', mouseLeave)
            $(document.body).on('mousedown touchstart', startSleepTimer)
        }
    }, [finished, setHover, startSleepTimer])


    // リンクにマウスオーバーしたときにポインターを変化させる
    useEffect(() => {
        if (finished) {
            $(document.body).on('mouseenter', 'a, button', () => {
                setHover(true)
                setText('')
            })
            $(document.body).on('mouseleave', 'a, button', () => {
                setHover(false)
                setText('')
            })
        }
    }, [finished, setHover, setText])


    // マウスカーソルにポインターを追随させるアニメーション
    useEffect(() => {
        if (finished) {
            gsap.ticker.add((time, deltaTime) => {
                if (param.current.tx !== null) {
                    const step = Math.max(Math.min(deltaTime / 1000, 50 / 1000), Config.deltaTime) * 15

                    if (param.current.cx === null) param.current.cx = param.current.tx
                    if (param.current.cy === null) param.current.cy = param.current.ty

                    param.current.cx += (param.current.tx - param.current.cx) * step
                    param.current.cy += (param.current.ty - param.current.cy) * step

                    pointer.current.style.left = param.current.cx + 'px'
                    pointer.current.style.top = param.current.cy + 'px'
                }
            })
        }
    }, [finished])


    return (
        <div className={styles.wrapper}>
            {children}
            <Pointer ref={pointer} />
        </div>
    )
}

export default Wrapper