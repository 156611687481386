import * as THREE from 'three'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js'

import Char from '../char'


class Typography {

    constructor(renderer, width, height) {
        this.camera = new THREE.PerspectiveCamera(45, 1, 1, 2.5)
        this.scene = new THREE.Scene()

        this.camera.aspect = width / height
        this.camera.position.set(0, 0, 2.5)
        this.camera.lookAt(0, 0, 0)
        this.camera.updateProjectionMatrix()

        this.offscreen = new THREE.WebGLRenderTarget(1, 1, {
            magFilter: THREE.LinearFilter,
            minFilter: THREE.LinearFilter
        })

        this.composer = new EffectComposer(renderer, this.offscreen)
        this.renderpass = new RenderPass(this.scene, this.camera)
        this.afterimgPass = new AfterimagePass(0.97)
        this.fxaaPass = new ShaderPass(FXAAShader)

        this.fxaaPass.uniforms.resolution.value.x = 1 / width
        this.fxaaPass.uniforms.resolution.value.y = 1 / height

        this.composer.setSize(width, height)
        this.composer.addPass(this.renderpass)
        this.composer.addPass(this.afterimgPass)
        this.composer.addPass(this.fxaaPass)
        this.composer.renderToScreen = false

        const text = [
            `天雲の`,
            `よそにのみして`,
            `経ることは`,
            `わがゐる山の`,
            `風はやみなり`
        ]

        text.forEach((line, i) => {
            line.split('').forEach((text, j) => {
                const char = new Char(text, { size: 0.120, height: 0, face: { color: 0xffffff, opacity: 1.0 }, line: false })
                char.x = 0.300 * (2 - i)
                char.y = 0.200 * (3 - j)
                char.z = 0
                this.scene.add(char.object)
            })
        })

        this.composer.render()
    }

    get texture() {
        return this.composer.readBuffer.texture
    }
}

export default Typography