import React, { useContext } from 'react'

import { StatesContext } from 'src/hooks/states/context'

import styles from './guide.module.scss'


const Guide = () => {

    const { playlistVisible } = useContext(StatesContext)

    return (
        <div className={styles.guide} aria-hidden={!playlistVisible}>
            <span></span>
        </div>
    )
}

export default Guide