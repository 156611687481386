
import { PlaneGeometry, MeshBasicMaterial, Mesh } from 'three'


class Screen {

    constructor(geometry, material) {
        this.geometry = geometry ? geometry : new PlaneGeometry(2, 2)
        this.material = material ? material : new MeshBasicMaterial({ map: null, transparent: true })
        this.object = new Mesh(this.geometry, this.material)
    }

    set texture(texture) {
        this.object.material.map = texture
    }

    fit(width, height, camera) {
        const _h = (camera.position.z - this.object.position.z) * Math.tan(camera.fov / 2 / 180 * Math.PI)
        this.object.scale.y = _h
        this.object.scale.x = _h / height * width
    }
}

export default Screen