import noise from '../../../shaders/noise.glsl'

export default `
uniform float deltaTime;
uniform int mode;
uniform float seed;

${noise}

void main() {
    vec2 uv = gl_FragCoord.xy / resolution.xy;

    vec3 pos = texture2D( texturePosition, uv ).xyz;
    vec3 vel = texture2D( textureVelocity, uv ).xyz;

    if (mode == 0) {

    } else {
        vel += 120.0 * curlNoise( pos * 0.005 + seed ) * deltaTime;
    }
    
    vel *= 0.95;

    gl_FragColor = vec4( vel, 1.0 );
}
`