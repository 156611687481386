import noise from '../../../shaders/noise.glsl'

export default `
uniform float intensity;
varying vec2 vUv;

${noise}

void main() {
    float color = 0.9 + 0.1 * rand(vUv);
    float radius = length(2.0 * vUv - vec2(1.0));
    float opacity = 0.2 * (0.2 + intensity) * (1.0 - radius);

    gl_FragColor = vec4(color, color, color, opacity);
}

`