export default `
uniform int swapindex;
uniform float seek;
uniform vec3 anchors[10];


vec3 cubicBezier(vec3 c0, vec3 c1, vec3 c2, vec3 c3) {
    float t = seek;
    float s = 1.0 - t;
    float tt = t * t;
    float ss = s * s;
    float ttt = tt * t;
    float sss = ss * s;

    return vec3(
        ttt * c3.x + 3.0 * tt * s * c2.x + 3.0 * t * ss * c1.x + sss * c0.x,
        ttt * c3.y + 3.0 * tt * s * c2.y + 3.0 * t * ss * c1.y + sss * c0.y,
        ttt * c3.z + 3.0 * tt * s * c2.z + 3.0 * t * ss * c1.z + sss * c0.z
    );
}


void main() {
    vec2 uv = gl_FragCoord.xy / resolution.xy;
    vec3 pos, p0, p1;

    if (swapindex == 0) {
        p0 = texture2D( textureOriginal1, uv ).xyz;
        p1 = texture2D( textureOriginal2, uv ).xyz;
    } else {
        p0 = texture2D( textureOriginal2, uv ).xyz;
        p1 = texture2D( textureOriginal1, uv ).xyz;
    }

    if (uv.x < 0.1) {
        pos = cubicBezier(p0, anchors[0], anchors[1], p1);
    } else if (uv.x < 0.2) {
        pos = cubicBezier(p0, anchors[1], anchors[2], p1);
    } else if (uv.x < 0.3) {
        pos = cubicBezier(p0, anchors[2], anchors[3], p1);
    } else if (uv.x < 0.4) {
        pos = cubicBezier(p0, anchors[3], anchors[4], p1);
    } else if (uv.x < 0.5) {
        pos = cubicBezier(p0, anchors[4], anchors[5], p1);
    } else if (uv.x < 0.6) {
        pos = cubicBezier(p0, anchors[5], anchors[6], p1);
    } else if (uv.x < 0.7) {
        pos = cubicBezier(p0, anchors[6], anchors[7], p1);
    } else if (uv.x < 0.8) {
        pos = cubicBezier(p0, anchors[7], anchors[8], p1);
    } else if (uv.x < 0.9) {
        pos = cubicBezier(p0, anchors[8], anchors[9], p1);
    } else {
        pos = cubicBezier(p0, anchors[9], anchors[0], p1);
    }

    gl_FragColor = vec4( pos, 1.0 );
}
`