import React, { useContext, useEffect, useCallback } from 'react'

import { StatesContext } from 'src/hooks/states/context'
import { CursorContext } from 'src/hooks/cursor/context'

import Closer from './closer'
import Guide from './guide'

import styles from './index.module.scss'


const Playlist = React.forwardRef(({ stage }, ref) => {

    const { playlistVisible, setPlaylistVisibility } = useContext(StatesContext)

    const { setHover, setText } = useContext(CursorContext)


    const onClick = useCallback((e) => {
        if (stage.click(e.pageX, e.pageY)) {
            setPlaylistVisibility(false)
        }
    }, [stage, setPlaylistVisibility])


    const onMouseMove = useCallback((e) => {
        if (stage.mouseMove(e.pageX, e.pageY)) {
            ref.current.style.cursor = 'none'
            setHover(true)
            setText('View')
        } else {
            ref.current.style.cursor = ''
            setHover(false)
            setText('')
        }
    }, [stage, ref, setHover, setText])


    useEffect(() => {
        if (!playlistVisible) {
            ref.current.style.cursor = ''
            setHover(false)
        }
    }, [ref, playlistVisible, setHover])


    return (
        <div className={styles.playlist} aria-hidden={!playlistVisible}>

            <div ref={ref} className={styles.wrapper} role="button" tabIndex={-1} onClick={onClick} onMouseMove={onMouseMove} onKeyDown={null}>
                <div></div>
            </div>

            <Closer />

            <Guide />
            
        </div>
    )
})

export default Playlist