import React from 'react'

import Link from '../link/index'

import Anchor from '../anchor/index'

import { Scrollin, ScrollItem } from '../scrollin/index'

import styles from './index.module.scss'


const Footer = ({ color }) => (
    <footer className={styles.footer} data-color={color}>
        <Scrollin>
            <div className={styles.upper}>
                <dl className={styles.col3}>
                    <ScrollItem tagName="dt">SOCIAL</ScrollItem>
                    <dd>
                        <ul>
                            <ScrollItem tagName="li" delay={100}><Link href="https://note.com/akashikurihara">note</Link></ScrollItem>
                            <ScrollItem tagName="li" delay={200}><Link href="https://ja-jp.facebook.com/people/%E6%A0%97%E5%8E%9F%E6%98%8E%E5%BF%97/100011679516167">Facebook</Link></ScrollItem>
                        </ul>
                    </dd>
                </dl>
                <dl className={styles.col3}>
                    <ScrollItem tagName="dt">CONTACT</ScrollItem>
                    <dd>
                        <ul>
                            <ScrollItem tagName="li" delay={100}><Link href="mailto:info@t-b-r.co.jp">info@<wbr />t-b-r.co.jp</Link></ScrollItem>
                        </ul>
                    </dd>
                </dl>
            </div>
            <div className={styles.lower}>

                <ScrollItem className={styles.col3} delay={350}>
                    <span className={styles.copyright}>©2020 Akashi Kurihara</span>
                </ScrollItem>

                <ScrollItem className={styles.col3} delay={350}>
                    <Link href="/privacypolicy/">Privacy Policy</Link>
                </ScrollItem>

                {color !== 'black' && (
                    <ScrollItem className={styles.back2top} delay={350}>
                        <Anchor id="">BACK TO TOP</Anchor>
                    </ScrollItem>
                )}
            </div>
        </Scrollin>
    </footer>
)

export default Footer
