import Loader from './Loader/index'
import Type01 from './Type01/index'
import Type02 from './Type02/index'
import Type03 from './Type03/index'
import Type04 from './Type04/index'
import Type05 from './Type05/index'
import Type06 from './Type06/index'
import Type07 from './Type07/index'
import Type08 from './Type08/index'
import Type09 from './Type09/index'
import Type10 from './Type10/index'
import Type11 from './Type11/index'
import Type12 from './Type12/index'
import Type13 from './Type13/index'
import Type14 from './Type14/index'
import Type15 from './Type15/index'
import Type16 from './Type16/index'
import Type17 from './Type17/index'
import Type18 from './Type18/index'
import Type19 from './Type19/index'
import Type20 from './Type20/index'
import Type21 from './Type21/index'
import Type22 from './Type22/index'
import Type23 from './Type23/index'
import Type24 from './Type24/index'

function getClass(index) {
    switch (index) {
        case '01': return Type01
        case '02': return Type02
        case '03': return Type03
        case '04': return Type04
        case '05': return Type05
        case '06': return Type06
        case '07': return Type07
        case '08': return Type08
        case '09': return Type09
        case '10': return Type10
        case '11': return Type11
        case '12': return Type12
        case '13': return Type13
        case '14': return Type14
        case '15': return Type15
        case '16': return Type16
        case '17': return Type17
        case '18': return Type18
        case '19': return Type19
        case '20': return Type20
        case '21': return Type21
        case '22': return Type22
        case '23': return Type23
        case '24': return Type24
        default: return Type02
    }
}

export {
    Loader,
    Type01,
    Type02,
    Type03,
    Type04,
    Type05,
    Type06,
    Type07,
    Type08,
    Type10,
    Type11,
    Type12,
    Type13,
    Type14,
    Type15,
    Type16,
    getClass
}