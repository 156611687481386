import * as THREE from 'three'

import gsap from 'gsap'

import Polaris from 'src/lib/Polaris'

import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

import AbstractAnimation from '../abstract'

import Seaweed from './seaweed'
import Caustics from './caustics'
import Bubbles from './bubbles'

import water_vert from './shaders/water_vert.glsl'
import water_frag from './shaders/water_frag.glsl'


class Type10 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer, { playlist_size: 1.0 })

        this.SIZE = 512

        this.camera.position.set(0, 300, 1500)
        this.camera.lookAt(0, 200, 0)

        this.waterPass = new ShaderPass({
            uniforms: {
                tDiffuse: { value: null },
                seed: { value: Math.random() },
                time: { value: 0.0 }
            },
            vertexShader: water_vert,
            fragmentShader: water_frag
        })

        this.composer.addPass(this.renderPass)
        this.composer.addPass(this.waterPass)
        this.composer.addPass(this.fxaaPass)

        this.caustics = new Caustics(renderer, 1024)
        this.bubbles = new Bubbles(renderer)

        this.object = new THREE.Mesh(new THREE.PlaneBufferGeometry(2, 2), new THREE.MeshBasicMaterial({ map: this.caustics.texture }))
        this.object.scale.x = 800
        this.object.scale.y = 800
        this.object.rotation.x = -Math.PI / 2
        this.object.position.y = 0
        this.scene.add(this.object)

        this.scene.add(this.bubbles.points)


        const texts = [
            `みるめなき`,
            `わが身を浦と`,
            `知らねばや`,
            `離れなで海人の`,
            `足たゆく来る`,
        ]

        const dpr = Polaris.util.clamp(Polaris.device.pixelRatio, 1.0, 2.0)

        this.seaweeds = Array.from(texts).map((line, i) => {
            return Array.from(line).map((text, j) => {
                const seaweed = new Seaweed(renderer, text, 30, dpr)
                seaweed.points.position.x = 200 * (2 - i)
                seaweed.points.position.z = i % 2 === 0 ? 0 : -500
                seaweed.points.position.y = 60 * (line.length - j)
                seaweed.visible = false
                this.scene.add(seaweed.points)
                return seaweed
            })
        })

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.update()
        this.render()
    }

    createMainTimeline() {
        const timeline = gsap.timeline({ paused: true, onUpdate: () => this.update() })

        // 海藻のドットサンプリング
        timeline.add(() => {
            this.seaweeds.forEach((chars) => {
                chars.forEach((char) => {
                    char.seek = 0
                    char.opacity = 0
                    char.sampling()
                })
            })
        })

        timeline.add([

            // 出現アニメーション
            this.seaweeds.map((chars, i) => {
                return chars.map((char, j) => {
                    return gsap.timeline().delay(1.5 + i * 2.5 + j * 0.1).add(() => {
                        char.visible = true
                    }).add([
                        this.tween(char, { seek: 1, duration: 3.0, ease: `power2.out` }),
                        this.tween(char, { opacity: 0.8, duration: 3.0, ease: `power2.in` })
                    ])
                })
            }),

            // 消去アニメーション
            this.seaweeds.map((chars, i) => {
                return chars.map((char, j) => {
                    return gsap.timeline().delay(15 + j * 0.05).add(() => {
                        char.bubbling()
                    }).add([
                        this.tween(char, { opacity: 0.01, duration: 1.0, ease: `power2.out` })
                    ])
                })
            }),

            this.tween({}, { duration: 20 })
        ])

        return timeline
    }

    createDemoTimeline() {
        return this.createMainTimeline().repeat(-1)
    }

    createOpenTimeline() {
        return gsap.timeline({
            paused: true, onUpdate: () => {
                this.bubbles.update()
                this.caustics.update()
            }
        }).add([
            this.tween({}, { duration: 3, ease: `linear` })
        ])
    }

    resize(width, height) {
        super.resize(width, height)

        if (width > height) {
            this.camera.position.z = 1500
        } else {
            this.camera.position.z = 2500
        }
    }
    
    update() {
        this.seaweeds.forEach((chars) => {
            chars.forEach((char) => char.update())
        })

        this.bubbles.update()
        this.caustics.update()
    }

    render(deltaTime, time) {
        this.caustics.time = time
        this.waterPass.material.uniforms.time.value = time
        super.render(deltaTime, time)
    }
}

export default Type10