import * as THREE from 'three'

import gsap from 'gsap'

import Polaris from 'src/lib/Polaris'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

import AbstractAnimation from '../abstract'

import Typography from './typography'
import Floor from './floor'
import Mirror from './mirror'
import BlurPass from './blurpass'

import final_vert from './shaders/final_vert.glsl'
import final_frag from './shaders/final_frag.glsl'


class Type17 extends AbstractAnimation {

    NUM = 30

    constructor(renderer) {
        super(renderer, { far: 3.2 })

        this.camera.position.set(0, 0, 1.5)
        this.camera.lookAt(0, 0, 0)
        
        this.light = new THREE.PointLight(0xffffff, 2, 0, 2)
        this.light.position.set(0, 0.5, 0)
        this.scene.add(this.light)

        this.ambient = new THREE.AmbientLight(0xffffff, 1)
        this.scene.add(this.ambient)

        
        //
        this.maskComposer = new EffectComposer(renderer, new THREE.WebGLRenderTarget(1, 1))
        this.maskComposer.addPass(this.renderPass)
        this.maskComposer.renderToScreen = false

        // 
        this.blurPass = new BlurPass(200)
        this.blurComposer = new EffectComposer(renderer, new THREE.WebGLRenderTarget(1, 1))
        this.blurComposer.addPass(this.renderPass)
        this.blurComposer.addPass(this.blurPass.pass)
        this.blurComposer.renderToScreen = false

        // 
        this.mainComposer = new EffectComposer(renderer, new THREE.WebGLRenderTarget(1, 1))
        this.mainComposer.addPass(this.renderPass)
        this.mainComposer.renderToScreen = false

        //
        this.finalPass = new ShaderPass({
            uniforms: {
                maskTexture: { value: null },
                blurTexture: { value: null },
                mainTexture: { value: null }
            },
            vertexShader: final_vert,
            fragmentShader: final_frag
        })
        this.composer.addPass(this.finalPass)



        this.typo = new Typography(renderer, 1500, 1500)
        this.root = new THREE.Object3D()
        this.scene.add(this.root)

        this.mirrors = new Array(this.NUM).fill('').map((_, i) => {
            const mirror = new Mirror(this.typo.texture, i / this.NUM, 1 / this.NUM)
            mirror.object.position.z = Polaris.util.rand(-500, 500) / 1000
            this.root.add(mirror.object)
            return mirror
        })

        this.floor = new Floor()
        this.floor.object.scale.x = 1.5
        this.floor.object.scale.y = 1.5
        this.floor.object.position.y = -0.4
        this.scene.add(this.floor.object)

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createMainTimeline() {
        return gsap.timeline({ paused: true, onUpdate: () => this.update() }).add([
            // カメラ回転
            this.tween(this.root.rotation, { y: -Math.PI }, { y: Math.PI, duration: 40, ease: `linear` }),

            // 切片の回転
            this.mirrors.map((mirror) => {
                const rotation = (Math.random() > 0.5 ? 1 : -1) * Polaris.util.rand(1, 10)
                return this.tween(mirror.object.rotation, { y: 0 }, { y: 4 * rotation * Math.PI, duration: 40, ease: `linear` })
            }),
        ])
    }

    createDemoTimeline() {
        return this.createMainTimeline().repeat(-1)
    }

    createOpenTimeline() {
        return this.createMainTimeline()
    }

    resize(width, height) {
        const ratio = width > height ? 0.95 : 0.5

        this.mirrors.forEach((mirror, i) => {
            mirror.scaleY = (this.camera.position.z - mirror.object.position.z) * Math.tan(this.camera.fov / 2 / 180 * Math.PI) * ratio
            mirror.scaleX = mirror.scaleY / this.mirrors.length
            mirror.object.position.x = 2 * mirror.scaleX * (i - this.mirrors.length / 2)
        })

        this.blurPass.resize(width, height)

        this.maskComposer.setSize(width, height)
        this.blurComposer.setSize(width, height)
        this.mainComposer.setSize(width, height)

        super.resize(width, height)
    }

    update() {
        this.floor.update()
    }

    render() {
        // ミラーテキスト部分を黒くして描画
        this.floor.visible = true

        this.mirrors.forEach((mirror) => {
            mirror.darken()
        })

        this.maskComposer.render()
        this.blurComposer.render()

        // ミラーテキスト部分のみ描画
        this.floor.visible = false

        this.mirrors.forEach((mirror) => {
            mirror.restore()
        })

        this.mainComposer.render()

        // 合成して描画
        this.finalPass.material.uniforms.maskTexture.value = this.maskComposer.readBuffer.texture
        this.finalPass.material.uniforms.blurTexture.value = this.blurComposer.readBuffer.texture
        this.finalPass.material.uniforms.mainTexture.value = this.mainComposer.readBuffer.texture
        this.composer.render()
    }
}

export default Type17