export default `
uniform sampler2D maskTexture;
uniform float strength;

varying vec2 vUv;


float rand(vec2 v, float seed){
    return fract(sin(dot(v.xy ,vec2(12.9898,78.233))) * 43758.5453 + seed);
}

void main() {
    vec3  destColor = vec3(0.0);
    float random = rand(vUv, strength);
    vec2  ray = vUv - vec2(0.5);
    float totalWeight = 0.0;

    float nFrag = 1.0 / 16.0;
    
    for(float i = 0.0; i <= 16.0; i++){
        float percent = (i + random) * nFrag;
        float weight = percent - percent * percent;
        vec2  t = vUv - ray * percent * strength * nFrag;
        destColor += texture2D( maskTexture, t ).rgb * weight;
        totalWeight += weight;
    }

    gl_FragColor = vec4( destColor / totalWeight, 1.0 );
}
`