import noise from '../../../shaders/noise.glsl'

export default `
uniform float seed;

${noise}

void main() {
    vec2 uv = gl_FragCoord.xy / resolution.xy;
    vec3 pos = texture2D( texturePosition, uv ).xyz;
    vec3 vel = texture2D( textureVelocity, uv ).xyz;

    vec3 p = vec3( pos.xy, simplex(pos.xy + seed) );
    vel += 2.0 * curlNoise( p * 0.01 ) + vec3(0.0, 0.5, -2.0);
    vel *= vec3(0.95, 0.97, 0.95);

    gl_FragColor = vec4( vel, 1.0 );
}
`