import util from '../../../shaders/utility.glsl'
import noise from '../../../shaders/noise.glsl'

export default `
#extension GL_EXT_shader_texture_lod : enable
#extension GL_OES_standard_derivatives : enable

// uniform sampler2D heightmap;
uniform float refraction;
uniform float time;
// uniform float dif;

varying vec2 vUv;

${util}
${noise}

float raycast(vec3 normal, vec3 point, vec3 light) {

    // 光線ベクトル
    vec3 ray = point - light;

    // 光源から水面へのベクトル
    vec3 surface = light + 0.5 * ray;

    vec3 beforeRef = refract(ray, vec3(0., 0., 1.), refraction);
    vec3 beforePos = surface + beforeRef * (0.5 / beforeRef.z);

    vec3 afterRef = refract(ray, normal, refraction);
    vec3 afterPos = surface + afterRef * (0.5 / afterRef.z);

    float beforeArea = length(dFdx(beforePos)) * length(dFdy(beforePos));
    float afterArea = length(dFdx(afterPos)) * length(dFdy(afterPos));

    return max(beforeArea / afterArea, .001);
}

void main() {
    // vec3 vc = texture2D( heightmap, vUv ).xyz;
    // vec3 vn = texture2D( heightmap, vUv + vec2( 0.0, + dif ) ).xyz;
    // vec3 vs = texture2D( heightmap, vUv + vec2( 0.0, - dif ) ).xyz;
    // vec3 ve = texture2D( heightmap, vUv + vec2( + dif, 0.0 ) ).xyz;
    // vec3 vw = texture2D( heightmap, vUv + vec2( - dif, 0.0 ) ).xyz;
    // vec3 normal = 1.0 * normalize(vec3( 2.0 * (ve.z - vw.z), 2.0 * (vs.z - vn.z), 4.0));
    
    vec3 normal = 1.0 * vec3(0.0, 0.0, fbm3(vec3(4.0 * vUv, 0.2 * time)));
    vec3 point = vec3(2.0 * (vUv - 0.5), 0.0);
    vec3 light = vec3(0.0, 0.0, 1.0);

    float color = 0.5 * raycast(normal, point, light);
    float alpha = 1.0 - length(point);

    color = pow(color, 0.25);

    vec3 c = vec3(color) * vec3(0.1, 0.12, 0.14);

    gl_FragColor = blend(vec4( c, alpha ), vec4(0., 0., 0., 1.));
}
`