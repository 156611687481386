import * as THREE from 'three'

import gsap from 'gsap'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

import AbstractAnimation from '../abstract'

import Char from '../char'

import sun_vert from './shaders/sun_vert.glsl'
import sun_frag from './shaders/sun_frag.glsl'

import godray_vert from './shaders/godray_vert.glsl'
import godray_frag from './shaders/godray_frag.glsl'

import final_vert from './shaders/final_vert.glsl'
import final_frag from './shaders/final_frag.glsl'


class Type22 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer)

        this.camera.position.set(0, 0, 1)
        this.camera.lookAt(0, 0, 0)
        this.camera.far = 2


        // 文字描画用
        this.typoComposer = new EffectComposer(renderer, new THREE.WebGLRenderTarget(1, 1))
        this.typoComposer.addPass(this.renderPass)
        this.typoComposer.renderToScreen = false


        // マスク描画用
        this.maskComposer = new EffectComposer(renderer, new THREE.WebGLRenderTarget(1, 1))
        this.maskComposer.addPass(this.renderPass)
        this.maskComposer.renderToScreen = false


        // ゴッドレイ描画用
        this.godrayComposer = new EffectComposer(renderer, new THREE.WebGLRenderTarget(1, 1))
        this.godrayPass = new ShaderPass({
            uniforms: {
                maskTexture: { value: null },
                strength: { value: 0 }
            },
            vertexShader: godray_vert,
            fragmentShader: godray_frag
        })
        this.godrayComposer.addPass(this.godrayPass)
        this.godrayComposer.renderToScreen = false


        // 最終的に合成して描画
        this.finalPass = new ShaderPass({
            uniforms: {
                godrayTexture: { value: null },
                typoTexture: { value: null }
            },
            vertexShader: final_vert,
            fragmentShader: final_frag
        })

        this.composer.addPass(this.finalPass)


        // 文字
        const text = [
            `むつましと`,
            `君はしらなみ`,
            `みづがきの`,
            `久しき世より`,
            `いはひそめてき`
        ]

        let count = 0

        this.chars = []

        this.orbits = Array.from(text).map((line, i) => {

            const orbit = new THREE.Object3D()
            const wrapper = new THREE.Object3D()

            wrapper.rotation.z = (1 - 2 * Math.random()) * Math.PI * 0.2
            wrapper.add(orbit)

            this.scene.add(wrapper)

            Array.from(line).forEach((text) => {
                const char = new Char(text, { size: 0.05, height: 0.015, face: { color: 0xffffff }, line: { color: 0x030303 } })
                const radius = i % 2 === 0 ? 0.35 : 0.45
                const radian = (30 + count * 11) / 360 * 2 * Math.PI

                char.x = radius * Math.sin(radian)
                char.z = radius * Math.cos(radian)
                char.object.rotation.y = radian
                char.originalFaceMaterial = char.face.material
                char.originalLineMaterial = char.line.material
                char.blackMaterial = new THREE.MeshBasicMaterial({ color: 0x000000, transparent: true })

                this.chars.push(char)

                orbit.add(char.object)

                count++
            })

            return orbit
        })

        // 太陽付き背景
        this.sun = new THREE.Mesh(new THREE.PlaneBufferGeometry(2, 2), new THREE.ShaderMaterial({
            uniforms: {
                resolution: { value: new THREE.Vector2(1, 1) },
                radius: { value: 0.1 }
            },
            transparent: true,
            vertexShader: sun_vert,
            fragmentShader: sun_frag
        }))

        this.sun.position.z = -0.5
        this.scene.add(this.sun)


        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render(0, 0)
    }

    createMainTimeline() {
        const timeline = gsap.timeline({ paused: true })

        timeline.add([
            this.orbits.map((orbit) => {
                return this.tween(orbit.rotation, { y: 0 }, { y: - 2 * Math.PI, duration: 30, ease: `linear` })
            })
        ])

        return timeline
    }

    createDemoTimeline() {
        return this.createMainTimeline().repeat(-1)
    }

    createOpenTimeline() {
        return this.createMainTimeline()
    }

    resize(width, height) {
        super.resize(width, height)

        const _h = (this.camera.position.z - this.sun.position.z) * Math.tan(this.camera.fov / 2 / 180 * Math.PI)
        this.sun.scale.y = _h
        this.sun.scale.x = _h / height * width
        this.sun.material.uniforms.resolution.value.x = width
        this.sun.material.uniforms.resolution.value.y = height

        this.typoComposer.setSize(width, height)
        this.maskComposer.setSize(width, height)
        this.godrayComposer.setSize(width, height)
    }

    render(deltaTime, time) {
        this.godrayPass.material.uniforms.strength.value = 12 + 2 * Math.sin(time / 5 * Math.PI)

        // 文字だけ描画
        this.chars.forEach((char) => {
            char.face.material = char.originalFaceMaterial
            char.line.material = char.originalLineMaterial
        })
        this.sun.visible = false
        this.typoComposer.render()

        // マスクテクスチャ生成
        const v = new THREE.Vector3()
        this.chars.forEach((char) => {
            char.object.getWorldPosition(v)
            char.blackMaterial.opacity = Math.max(0, v.z / 0.2)
            char.face.material = char.blackMaterial
            char.line.material = char.blackMaterial
        })
        this.sun.visible = true
        this.maskComposer.render()

        // ゴッドレイ描画
        this.godrayPass.material.uniforms.maskTexture.value = this.maskComposer.readBuffer.texture
        this.godrayComposer.render()

        // 合成して描画
        this.finalPass.material.uniforms.typoTexture.value = this.typoComposer.readBuffer.texture
        this.finalPass.material.uniforms.godrayTexture.value = this.godrayComposer.readBuffer.texture
        this.composer.render()
    }
}

export default Type22