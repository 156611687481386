import utility from '../../../shaders/utility.glsl'
import noise from '../../../shaders/noise.glsl'

export default `

uniform sampler2D tDiffuse;
uniform sampler2D noiseMap;
uniform float time;
uniform float seek;

varying vec2 vUv;

${utility}
${noise}

float noise(vec2 uv) {
	return texture2D( noiseMap, uv ).x;
}

float baseNoise(vec2 v, float time) {
	return fbm2(vec3(v, time));
}

void main() {
    float nx, ny, rx, ry;

    // 袖
    nx = baseNoise(vUv * 5.0 + vec2(1.0), time * 0.2) * 0.01;
    ny = baseNoise(vUv * 5.0 + vec2(1.0), time * 0.2) * 0.01;
    rx = ry = 0.0;

    if (seek > 0.0) {
        // rx = rand(vUv + vec2(1.0)) * 0.005 * seek;
        // ry = rand(vUv - vec2(1.0)) * 0.005 * seek;
        nx += noise(vUv) * 0.03 * seek;
        ny += noise(vUv) * 0.03 * seek;
    }

    vec4 color1 = texture2D(tDiffuse, vec2(vUv.x + nx + rx, vUv.y + ny + ry));


    // 橘
    nx = baseNoise(vUv * 5.0 + vec2(2.0), time * 0.2) * 0.01;
    ny = baseNoise(vUv * 5.0 + vec2(2.0), time * 0.2) * 0.01;
    rx = ry = 0.0;

    if (seek > 0.0) {
        nx *= (1.0 + 5.0 * seek);
        ny *= (1.0 + 5.0 * seek);
        // rx = rand(vUv + vec2(2.0)) * 0.01 * seek;
        // ry = rand(vUv - vec2(2.0)) * 0.01 * seek;
        nx += noise(vUv) * 1.0 * seek;
        ny += noise(vUv) * 1.0 * seek;
    }
    
    vec4 color2 = texture2D(tDiffuse, vec2(vUv.x + nx + rx, vUv.y + ny + ry));


    // 色合成
    color1.w *= 0.1;

    if (color1.x == 0.0) {
        color2.w *= 0.2;
    }
    
    gl_FragColor = blend( blend(color1, color2), vec4(0.0, 0.0, 0.0, 1.0) );
}

`