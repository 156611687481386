export default `

uniform sampler2D tDiffuse;
uniform float radius;
uniform float weight[10];
uniform vec2 resolution;

vec3 getColor(float d) {
    vec2 coord = vec2(gl_FragCoord.x, gl_FragCoord.y + d);
    return texture2D(tDiffuse, coord / resolution).rgb;
}

void main() {
    vec3 dst = vec3(0.0);

    if (radius > 1.0) {
        dst += getColor(+9.0) * weight[9];
        dst += getColor(+8.0) * weight[8];
        dst += getColor(+7.0) * weight[7];
        dst += getColor(+6.0) * weight[6];
        dst += getColor(+5.0) * weight[5];
        dst += getColor(+4.0) * weight[4];
        dst += getColor(+3.0) * weight[3];
        dst += getColor(+2.0) * weight[2];
        dst += getColor(+1.0) * weight[1];
        dst += getColor(+0.0) * weight[0];
        dst += getColor(-1.0) * weight[1];
        dst += getColor(-2.0) * weight[2];
        dst += getColor(-3.0) * weight[3];
        dst += getColor(-4.0) * weight[4];
        dst += getColor(-5.0) * weight[5];
        dst += getColor(-6.0) * weight[6];
        dst += getColor(-7.0) * weight[7];
        dst += getColor(-8.0) * weight[8];
        dst += getColor(-9.0) * weight[9];
    } else {
        dst += getColor(0.0);
    }
    gl_FragColor = vec4(dst, 1.0);
}

`