import * as THREE from 'three'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'

import Char from '../char'


class Typography {

    constructor(renderer) {
        this.camera = new THREE.PerspectiveCamera(45, 1, 0.01, 4)
        this.scene = new THREE.Scene()

        this.camera.position.set(0, 0, 2)
        this.camera.lookAt(0, 0, 0)

        this.offscreen = new THREE.WebGLRenderTarget(1, 1, {
            magFilter: THREE.LinearFilter,
            minFilter: THREE.LinearFilter
        })

        this.composer = new EffectComposer(renderer, this.offscreen)
        this.renderpass = new RenderPass(this.scene, this.camera)

        this.composer.addPass(this.renderpass)
        this.composer.renderToScreen = false

        const text = [
            `名にし負はば`,
            `いざこと問はむ`,
            `都鳥`,
            `わが思ふ人は`,
            `ありやなしやと`
        ]

        const offset = [0, 1, 3, 0, 2]

        this.chars = text.reduce((carry, chars, i) => {
            return Array.from(chars).reduce((carry, text, j) => {
                const char = new Char(text, { size: 0.060, height: 0, face: { color: 0xffffff, opacity: 1.0 }, line: false })
                char.x = 0.200 * (2 - i)
                char.y = 0.120 * (3 - j) - offset[i] * 0.1
                char.z = 0
                this.scene.add(char.object)
                carry.push(char)
                return carry
            }, carry)
        }, [])
    }

    get texture() {
        return this.composer.readBuffer.texture
    }

    resize(w, h) {
        if (w > h) {
            this.camera.position.set(0, 0, 2)
        } else {
            this.camera.position.set(0, 0, 3)
        }

        this.camera.lookAt(0, 0, 0)
        this.camera.aspect = w / h
        this.camera.updateProjectionMatrix()

        this.composer.setSize(w, h)
        this.composer.render()
    }

    update() {
        this.composer.render()
    }
}

export default Typography