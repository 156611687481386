import * as THREE from 'three'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js'

import CustomShader from './shaders/CustomShader'

import Char from '../char'

import NoiseMap from '../../shaders/noisemap/fbm3'


class Typography {

    constructor(renderer, width, height) {
        this.camera = new THREE.PerspectiveCamera(45, 1, 1, 3)
        this.scene = new THREE.Scene()

        this.camera.aspect = width / height
        this.camera.position.set(0, 0, 2.5)
        this.camera.lookAt(0, 0, 0)
        this.camera.updateProjectionMatrix()

        this.offscreen = new THREE.WebGLRenderTarget(1, 1, {
            magFilter: THREE.LinearFilter,
            minFilter: THREE.LinearFilter
        })

        this.noiseMap = new NoiseMap(renderer, width)

        this.composer = new EffectComposer(renderer, this.offscreen)
        this.renderpass = new RenderPass(this.scene, this.camera)
        this.shaderPass = new ShaderPass(CustomShader)
        this.fxaaPass = new ShaderPass(FXAAShader)

        this.fxaaPass.uniforms.resolution.value.x = 1 / width
        this.fxaaPass.uniforms.resolution.value.y = 1 / height

        this.shaderPass.uniforms.noiseMap.value = this.noiseMap.texture

        this.composer.setSize(width, height)
        this.composer.addPass(this.renderpass)
        this.composer.addPass(this.fxaaPass)
        this.composer.addPass(this.shaderPass)
        this.composer.renderToScreen = false

        const text = [
            `あだなりと`,
            `名にこそ立てれ`,
            `櫻花`,
            `年にまれなる`,
            `人も待ちけり`
        ]

        const offset = [0, 1, 2, 0, 1]

        text.forEach((line, i) => {
            line.split('').forEach((text, j) => {
                const char = new Char(text, { size: 0.100, height: 0, face: { color: 0xffffff, opacity: 1.0 }, line: false })
                char.x = 0.250 * (2 - i)
                char.y = 0.200 * (3 - j - offset[i])
                this.scene.add(char.object)
            })
        })

        this.object = new THREE.Mesh(new THREE.PlaneBufferGeometry(2, 2), new THREE.MeshBasicMaterial({ map: null }))

        this.update()
    }

    get texture() {
        return this.composer.readBuffer.texture
    }

    update() {
        this.composer.render()
        this.object.material.map = this.texture
    }
}

export default Typography