import { Vector2 } from 'three'

import gsap from 'gsap'

import Polaris from 'src/lib/Polaris'

import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'

import AbstractAnimation from '../abstract'

import StepChar from './stepchar'


class Type18 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer)

        this.camera.position.set(0, 0, 0.500)
        this.camera.lookAt(0, 0, 0)
        this.camera.far = 0.5

        this.bloomPass = new UnrealBloomPass(new Vector2(2, 2), 0.4, 0.1, 0.0)

        this.composer.addPass(this.renderPass)
        this.composer.addPass(this.bloomPass)

        this.step = 0.150

        const text = `忘れては夢かとぞ思ふ思ひきや雪踏みわけて君を見むとは`

        this.chars = Array.from(text).map((text, i) => {
            const char = new StepChar(text, 0.100)
            char.x = 0.050 * (i % 2 === 0 ? -1 : 1) + Polaris.util.rand(-10, 10) / 1000
            char.y = this.step * (i + 1)
            this.scene.add(char.object)
            return char
        })

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createMainTimeline() {
        const timeline = gsap.timeline({ paused: true, onUpdate: () => this.update() })

        timeline.add(() => {
            this.camera.position.y = 0

            this.chars.forEach((char) => {
                char.opacity = 0
                char.threshold = 1
            })
        }).add([
            // 歩行カメラ移動
            this.chars.map((char, i) => {
                return gsap.timeline().add([
                    this.tween({}, { duration: i * 2 }) // delay
                ]).add([
                    this.tween(this.camera.position, { y: this.step * (i + 1), duration: 1.5, ease: `power1.inOut` })
                ])
            }),

            // 文字表示アニメーション
            this.chars.map((char, i) => {
                return gsap.timeline().add(
                    this.tween({}, { duration: i * 2 + 0.9 }) // delay
                ).add([
                    this.tween(char, { threshold: 0, duration: 0.3, ease: `linear` }),
                    this.tween(char, { opacity: 1, duration: 0.3, ease: `power2.out` })
                ]).add([
                    this.tween(char, { opacity: 0.01, duration: 1.5, ease: `circ.out`, delay: 2 })
                ])
            })
        ])

        return timeline
    }

    createDemoTimeline() {
        return this.createMainTimeline().repeat(-1)
    }

    createOpenTimeline() {
        return gsap.timeline({ paused: true, onUpdate: () => this.update() }).add(() => {
            this.chars.forEach((char) => {
                char.opacity = 1
                char.threshold = 0
            })
        }).add([
            // 歩行カメラ移動
            this.chars.map((char, i) => {
                return gsap.timeline().add([
                    this.tween({}, { duration: i * 2 })
                ]).add([
                    this.tween(this.camera.position, { y: this.step * (i + 1), duration: 1.5, ease: `power1.inOut` })
                ])
            })
        ])
    }

    update() {
        this.camera.lookAt(0, this.camera.position.y, 0)
    }
}

export default Type18