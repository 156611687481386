import * as THREE from 'three'

import gsap from 'gsap'

import Polaris from 'src/lib/Polaris'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

import AbstractAnimation from '../abstract'

import DotsChar from './dotschar'

import compose_vert from './shaders/compose_vert.glsl'
import compose_frag from './shaders/compose_frag.glsl'


class Type01 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer, { playlist_size: 1.0 })

        const dpr = Polaris.util.clamp(Polaris.device.pixelRatio, 1.0, 2.0)

        this.camera.position.set(0, 1200, 1200)
        this.camera.lookAt(0, 0, 0)

        this.darkMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 })
        this.materials = {}

        this.bloomPass = new UnrealBloomPass(new THREE.Vector2(4, 4), 0.5, 0.2, 0.01)
        
        this.finalPass = new ShaderPass({
            uniforms: {
                baseTexture: { value: null },
                bloomTexture: { value: null }
            },
            vertexShader: compose_vert,
            fragmentShader: compose_frag
        }, `baseTexture`)

        this.bloomComposer = new EffectComposer(renderer, this.offscreen)
        this.bloomComposer.renderToScreen = false

        this.bloomComposer.addPass(this.renderPass)
        this.bloomComposer.addPass(this.bloomPass)

        this.composer.addPass(this.renderPass)
        this.composer.addPass(this.finalPass)
        this.composer.addPass(this.fxaaPass)


        this.finalPass.uniforms.bloomTexture.value = this.bloomComposer.readBuffer.texture
        this.darkenNonBloomed = this.darkenNonBloomed.bind(this)
        this.restoreMaterial = this.restoreMaterial.bind(this)

        const tanka = [
            `月やあらぬ`,
            `春や昔の`,
            `春ならぬ`,
            `わが身一つは`,
            `もとの身にして`
        ]

        this.lines = tanka.map((line, i) => {
            return Array.from(line).map((text, j) => {
                const char = new DotsChar(renderer, text, 150, 50, dpr)

                char.x = 200 * (j - line.length / 2)
                char.y = (char.geometry.boundingBox.max.y - char.geometry.boundingBox.min.y) / 2
                char.z = 200 - 400 * Math.random()

                if (Math.random() > 0.5) {
                    char.object.rotation.x = - Math.PI / 2
                    char.object.rotation.z = Math.PI * (0.3 - 0.6 * Math.random())
                    char.y = 25
                } else {
                    char.object.rotation.y = Math.PI * (0.3 - 0.6 * Math.random())
                }

                this.scene.add(char.object)
                this.scene.add(char.points)

                char.object.visible = false
                char.points.visible = false

                return char
            })
        })

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createMainTimeline() {
        const timeline = gsap.timeline({ paused: true })

        // 文字消去
        timeline.add(() => {
            this.lines.forEach((chars) => {
                chars.forEach((char) => {
                    char.object.visible = false
                    char.points.visible = false
                })
            })
        })

        // 文字表示アニメーション
        this.lines.forEach((chars) => {

            const subTimeline = gsap.timeline()

            subTimeline.delay(0.5).add([
                chars.map((char, i) => {
                    return gsap.timeline({ onUpdate: () => char.update() }).delay(i * 0.1).add(() => {
                        char.object.visible = true
                        char.points.visible = false
                        char.line.material.color = { r: 0, g: 0, b: 0 }
                    }).add([
                        this.tween(char, { faceOpacity: 0 }, { faceOpacity: 1, duration: 1, ease: `power2.in` }),
                        this.tween(char, { lineOpacity: 0 }, { lineOpacity: 1, duration: 1, ease: `power2.in` })
                    ]).add([
                        this.tween({}, { duration: 1.0 })
                    ]).add(() => {
                        char.sampling()
                        char.points.visible = true
                        char.lineOpacity = 0.8
                        char.line.material.color = { r: 1, g: 1, b: 1 }
                    }).add([
                        this.tween(char, { faceOpacity: 1 }, { faceOpacity: 0, duration: 0.5, ease: `power2.out` }),
                        this.tween(char, { dotsOpacity: 1 }, { dotsOpacity: 0, duration: 4.0, ease: `power2.out` })
                    ]).add([
                        this.tween(char, { lineOpacity: 0, duration: 1, ease: `power2.out`, delay: 1 })
                    ]).add(() => {
                        char.object.visible = false
                        char.points.visible = false
                    })
                })
            ])

            timeline.add(subTimeline)
        })

        // 文字が消えた後も1秒アニメーション
        timeline.add([
            this.tween({}, { duration: 1, ease: `linear` })
        ])

        return timeline
    }

    createDemoTimeline() {
        return this.createMainTimeline().repeat(-1)
    }

    createOpenTimeline() {
        return this.createMainTimeline()
    }

    resize(width, height) {
        this.bloomComposer.setSize(width, height)

        if (width > height) {
            this.camera.position.set(0, 1200, 1200)
        } else {
            this.camera.position.set(0, 2400, 2400)
        }
        this.camera.lookAt(0, 0, 0)

        super.resize(width, height)
    }

    render() {
        // bloom部分のみ描画
        this.scene.traverse(this.darkenNonBloomed)
        this.bloomComposer.render()
        this.scene.traverse(this.restoreMaterial)

        // 合成して描画
        this.composer.render()
    }

    darkenNonBloomed(obj) {
        if (obj.isLineSegments) {
            this.materials[obj.uuid] = obj.material;
            obj.material = this.darkMaterial;
        }
    }

    restoreMaterial(obj) {
        if (this.materials[obj.uuid]) {
            obj.material = this.materials[obj.uuid];
            delete this.materials[obj.uuid];
        }
    }
}

export default Type01