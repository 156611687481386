
import * as THREE from 'three'

import font from '../data/font'


class Char {

    defaultProps = {
        size : 100,
        height: 1,
        face: {
            color: 0x000000,
            opacity: 1.0
        },
        line: {
            color: 0xffffff,
            opacity: 1.0
        }
    }

    constructor(text, props) {

        this.props = Object.assign(this.defaultProps, props)

        this.geometry = new THREE.TextBufferGeometry(text, {
            font: font,
            size: this.props.size,
            height: this.props.height,
            curveSegments: 6,
        })

        this.geometry.center()

        this.edges = new THREE.EdgesGeometry(this.geometry)

        this.object = new THREE.Object3D()

        // 表面
        if (this.props.face) {
            this.props.face = Object.assign({ color: 0x000000, opacity: 1 }, this.props.face)
            const material = new THREE.MeshBasicMaterial({ transparent: true, color: this.props.face.color, opacity: this.props.face.opacity })
            this.face = new THREE.Mesh(this.geometry, material)
            this.object.add(this.face)
        }
        
        // 輪郭線
        if (this.props.line) {
            this.props.line = Object.assign({ color: 0xffffff, opacity: 1 }, this.props.line)
            const material = new THREE.LineBasicMaterial({ transparent: true, color: this.props.line.color, opacity: this.props.line.opacity })
            this.line = new THREE.LineSegments(this.edges, material)
            this.object.add(this.line)
        }

        this.opacity = 1.0
    }

    dispose() {
        this.edges.dispose()
        this.geometry.dispose()

        if (this.face) {
            this.object.remove(this.face)
            this.face.material.dispose()
        }
        if (this.line) {
            this.object.remove(this.line)
            this.line.material.dispose()
        }
    }

    set visible(visilbe) {
        this.object.visible = visilbe
    }

    get visible() {
        return this.object.visible
    }

    get opacity() {
        return this._opacity
    }

    set opacity(value) {
        this._opacity = Math.min(Math.max(0, value), 1)
        if (this.face) this.face.material.opacity = this.props.face.opacity * this._opacity
        if (this.line) this.line.material.opacity = this.props.line.opacity * this._opacity
    }

    get x() {
        return this.object.position.x
    }

    get y() {
        return this.object.position.y
    }

    get z() {
        return this.object.position.z
    }

    set x(value) {
        this.object.position.x = value
    }

    set y(value) {
        this.object.position.y = value
    }

    set z(value) {
        this.object.position.z = value
    }
}

export default Char