import React from 'react'

import { Link } from 'gatsby'

import styles from './index.module.scss'

const isOuterLink = new RegExp('^https?://')
const isMailLink = new RegExp('^mailto:')
const isHashLink = new RegExp('^#')


const CustomLink = (props) => {

    const className = styles.link + (props.className ? ' ' + props.className : '')

    const content = (<span>{props.children}</span>)

    if (props.href) {
        if (isOuterLink.test(props.href)) {
            return <a {...props} className={className} target="_blank" rel="noopener noreferrer">{content}</a>

        } else if (isMailLink.test(props.href)) {
            return <a {...props} className={className}>{content}</a>

        } else if (isHashLink.test(props.href)) {
            return <a {...props} className={className}>{content}</a>

        } else {
            return <Link to={props.href} {...props} className={className}>{content}</Link>
        }
    } else {
        return <button {...props} className={className}>{content}</button>
    }

    
}

export default CustomLink