import { Vector2 } from 'three'

import gsap from 'gsap'

import Polaris from 'src/lib/Polaris'

import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'

import AbstractAnimation from '../abstract'

import Typography from './typography'


class Type06 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer, { playlist_size: 1.0 })

        this.camera.far = 4

        const size = Math.min(2048, window.screen.availWidth * Polaris.device.pixelRatio)

        this.bloomPass = new UnrealBloomPass(new Vector2(4, 4), 0.5, 0.5, 0.9)

        this.composer.addPass(this.renderPass)
        this.composer.addPass(this.bloomPass)

        this.typography = new Typography(renderer, size, size)
        this.typography.object.scale.set(1, 1, 1)
        this.scene.add(this.typography.object)

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createMainTimeline() {
        return gsap.timeline({ paused: true, onUpdate: () => this.update() }).add(() => {
            this.typography.shaderPass.uniforms.time0.value = 0
            this.typography.shaderPass.uniforms.time1.value = 0
            this.typography.shaderPass.uniforms.time2.value = 0
        }).add([
            // シェーダーアニメーション
            this.tween(this.typography.shaderPass.uniforms.time0, { value: 1, duration: 20, ease: `linear` }), // ノイズ
            this.tween(this.typography.shaderPass.uniforms.time1, { value: 1, duration: 7, ease: `linear` }), // 揺らぎ
            this.tween(this.typography.shaderPass.uniforms.time2, { value: 1, duration: 5, ease: `sine.inOut`, delay: 5.5 }), // 開花

            // カメラワーク
            gsap.timeline().add(() => {
                this.camera.position.set(0.300, 0.460, 0.750)
            }).add([
                this.tween(this.camera.position, { x: 0.250, y: 0.460, duration: 2, ease: `linear` })
            ]).add(() => {
                this.camera.position.set(-0.350, -0.100, 0.750)
            }).add([
                this.tween(this.camera.position, { x: -0.350, y: -0.050, duration: 2, ease: `linear` })
            ]).add(() => {
                this.camera.position.set(-0.250, 0.465, 0.750)
            }).add([
                this.tween(this.camera.position, { x: -0.300, y: 0.465, duration: 2, ease: `linear` })
            ]).add(() => {
                this.camera.position.set(0, 0, 0.750)
            }).add([
                this.tween(this.camera.position, { z: 3.500, duration: 3, ease: `sine.inOut` })
            ])
        ])
    }

    createDemoTimeline() {
        return gsap.timeline({ paused: true, onUpdate: () => this.update(), repeat: -1 }).add(() => {
            this.camera.position.set(0, 0, 2.800)
            this.typography.shaderPass.uniforms.time1.value = 0
            this.typography.shaderPass.uniforms.time2.value = 0
        }).add([
            this.tween(this.typography.shaderPass.uniforms.time1, { value: 1, duration: 4, ease: `linear` }),
            this.tween(this.typography.shaderPass.uniforms.time2, { value: 1, delay: 1, duration: 5, ease: `sine.inOut` })
        ])
    }

    createOpenTimeline() {
        return gsap.timeline({ paused: true, onUpdate: () => this.update() }).add(() => {
            this.camera.position.set(0, 0, 2.800)
        }).add([
            this.tween(this.typography.shaderPass.uniforms.time1, { value: 0.8 }, { value: 1, duration: 0.5, ease: `linear` }),
            this.tween(this.typography.shaderPass.uniforms.time2, { value: 0.8 }, { value: 1, duration: 0.5, ease: `sine.out` })
        ])
    }

    update() {
        this.typography.update()
        this.camera.lookAt(this.camera.position.x, this.camera.position.y, 0)
    }
}

export default Type06