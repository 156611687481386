import * as THREE from 'three'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'

import Char from '../char'


class Typography {

    constructor(renderer, width, height) {
        this.camera = new THREE.PerspectiveCamera(45, 1, 1, 2.5)
        this.scene = new THREE.Scene()

        this.camera.aspect = width / height
        this.camera.position.set(0, 0, 2.5)
        this.camera.lookAt(0, 0, 0)
        this.camera.updateProjectionMatrix()

        this.offscreen = new THREE.WebGLRenderTarget(1, 1, {
            magFilter: THREE.LinearFilter,
            minFilter: THREE.LinearFilter
        })

        this.composer = new EffectComposer(renderer, this.offscreen)
        this.renderpass = new RenderPass(this.scene, this.camera)

        this.composer.setSize(width, height)
        this.composer.addPass(this.renderpass)
        this.composer.renderToScreen = false

        const text = [
            `おもほえず`,
            `袖にみなとの`,
            `さわぐかな`,
            `もろこし船の`,
            `寄りしばかりに`
        ]

        text.forEach((line, i) => {
            line.split('').forEach((text, j) => {
                const char = new Char(text, { size: 0.100, height: 0, face: { color: 0xffffff, opacity: 1.0 }, line: false })
                char.x = 0.200 * (2 - i)
                char.y = 0.200 * (3 - j)
                char.z = 0.1
                this.scene.add(char.object)
            })
        })

        this.geometry = new THREE.PlaneBufferGeometry(2, 2, 512 - 1, 512 - 1)

        this.object = new THREE.Mesh(this.geometry, new THREE.MeshBasicMaterial({ transparent: true, opacity: 0.8, map: this.composer.readBuffer.texture }))
    }

    get texture() {
        return this.composer.readBuffer.texture
    }

    update() {
        this.composer.render()
    }
}

export default Typography