import React, { useContext } from 'react'

import { CursorContext } from 'src/hooks/cursor/context'

import styles from './pointer.module.scss'


const Pointer = React.forwardRef((props, ref) => {

    const { hover, text } = useContext(CursorContext)

    return (
        <div ref={ref} className={styles.pointer} aria-hidden={!hover}>{text}</div>
    )
})

export default Pointer