

import { GPUComputationRenderer } from 'three/examples/jsm/misc/GPUComputationRenderer.js'

import compute_fbm3 from './fbm3.glsl'


class NoiseMap {

    constructor(renderer, size, seed = 0) {

        this.gpuCompute = new GPUComputationRenderer(size, size, renderer)

        this.noiseVariable = this.gpuCompute.addVariable('textureNoise', compute_fbm3, this.gpuCompute.createTexture())

        this.noiseVariable.material.uniforms['seed'] = { value: seed }

        this.gpuCompute.setVariableDependencies(this.noiseVariable, [this.noiseVariable])
        this.gpuCompute.init()
        this.gpuCompute.compute()
    }

    get texture() {
        return this.gpuCompute.getCurrentRenderTarget(this.noiseVariable).texture
    }
}

export default NoiseMap