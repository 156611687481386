import * as THREE from 'three'

import gsap from 'gsap'

import Polaris from 'src/lib/Polaris'

// import { AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass.js'
// import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'

import { Curves } from 'three/examples/jsm/curves/CurveExtras.js';

import AbstractAnimation from '../abstract'

import Typography from './typography'


class Type24 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer, { fov: 90, far: 1500 })

        const size = Math.min(2048, Math.max(window.screen.availWidth, window.screen.availHeight) * Polaris.device.pixelRatio)

        this.debug = 0

        this.composer.addPass(this.renderPass)
        // this.composer.addPass(new AfterimagePass(0.8))
        // this.composer.addPass(new UnrealBloomPass(new THREE.Vector2(2, 2), 0.5, 0.1, 0.1))

        this.typography = new Typography(renderer, size, size)
        
        this.scale = 30

        // this.wireGeometry = new THREE.TubeBufferGeometry(new Curves.KnotCurve(), 200, 5, 16, true)
        // this.wireMaterial = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, wireframe: true, color: 0x030303 })
        // this.wire = new THREE.Mesh(this.wireGeometry, this.wireMaterial)
        // this.wire.scale.set(this.scale, this.scale, this.scale)
        // this.scene.add(this.wire)

        this.tubeGeometry = new THREE.TubeBufferGeometry(new Curves.KnotCurve(), 1000, 5, 64, true)
        this.tubeMaterial = new THREE.MeshPhysicalMaterial({ side: THREE.DoubleSide, map: this.typography.texture, roughness: 0.5, metalness: 0, clearcoat: 0, clearcoatRoughness: 0.1 })
        this.tube = new THREE.Mesh(this.tubeGeometry, this.tubeMaterial)
        this.tube.scale.set(this.scale, this.scale, this.scale)
        this.scene.add(this.tube)


        this.light1 = new THREE.PointLight(0xffffff, 2.5, 0, 2.0)
        this.scene.add(this.light1)

        this.light2 = new THREE.PointLight(0xffffff, 2.0, 1000, 2.0)
        this.scene.add(this.light2)

        this.light3 = new THREE.SpotLight(0xffffff, 30.0, 1000, Math.PI / 2, 1.0, 2.0)
        this.scene.add(this.light3)
        this.scene.add(this.light3.target)

        this.binormal = new THREE.Vector3()
        this.position = new THREE.Vector3()
        this.lookAt = new THREE.Vector3()
        this.normal = new THREE.Vector3()

        this.animationProps = { time: 0 }

        // if (this.debug) {
        //     this.typography.object.position.set(0, 0, 0)
        //     this.typography.object.scale.set(1024, 1024, 1)
        //     this.scene.add(this.typography.object)
        // }

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
    }

    createMainTimeline() {
        return gsap.timeline({ paused: true }).add([
            this.tween(this.animationProps, { time: 0 }, { time: 1, duration: 20, ease: `linear` })
        ])
    }

    createDemoTimeline() {
        return gsap.timeline({ paused: true, repeat: -1 }).add([
            this.tween(this.animationProps, { time: 0 }, { time: 1, duration: 20, ease: `linear` })
        ])
    }

    createOpenTimeline() {
        return gsap.timeline({ paused: true }).add([
            this.tween(this.animationProps, { time: 0.5 }, { time: 1, duration: 20, ease: `linear` })
        ])
    }

    render() {
        // if (this.debug) {
        //     this.camera.position.set(0, 0, 1000)
        //     this.camera.lookAt(0, 0, 0)
        //     this.composer.render()
        //     return
        // }

        const length = this.tubeGeometry.parameters.path.getLength()

        // animate camera along spline
        const t = this.animationProps.time % 1

        this.tubeGeometry.parameters.path.getPointAt(t, this.position)
        this.position.multiplyScalar(this.scale)

        // interpolation
        var segments = this.tubeGeometry.tangents.length
        var pickt = t * segments
        var pick = Math.floor(pickt)
        var pickNext = (pick + 1) % segments

        this.binormal.subVectors(this.tubeGeometry.binormals[pickNext], this.tubeGeometry.binormals[pick])
        this.binormal.multiplyScalar(pickt - pick).add(this.tubeGeometry.binormals[pick])

        var dir = this.tubeGeometry.parameters.path.getTangentAt(t)
        var offset = 15;

        this.normal.copy(this.binormal).cross(dir)

        // we move on a offset on its binormal
        this.position.add(this.normal.clone().multiplyScalar(offset))
        this.camera.position.copy(this.position)
        this.light1.position.copy(this.position)

        // using arclength for stablization in look ahead
        this.tubeGeometry.parameters.path.getPointAt((t + 30 / length) % 1, this.lookAt);
        this.lookAt.multiplyScalar(this.scale)

        // camera orientation 2 - up orientation via normal
        this.lookAt.copy(this.position).add(dir)
        this.camera.matrix.lookAt(this.camera.position, this.lookAt, this.normal)
        this.camera.quaternion.setFromRotationMatrix(this.camera.matrix)

        this.tubeGeometry.parameters.path.getPointAt((t + 40 / length) % 1, this.light2.position)
        this.light2.position.multiplyScalar(this.scale)

        this.tubeGeometry.parameters.path.getPointAt((t + 20 / length) % 1, this.light3.position)
        this.light3.position.multiplyScalar(this.scale)

        this.tubeGeometry.parameters.path.getPointAt((t + 40 / length) % 1, this.light3.target.position)
        this.light3.target.position.multiplyScalar(this.scale)

        // this.ball.position.copy(this.light3.target.position)

        this.composer.render()
    }
}

export default Type24