export default `
// uniform float devicePixelRatio;
// uniform sampler2D waterTexture;

// void main2() {
//     vec4 pos = texture2D( waterTexture, uv );

//     vec4 mvPosition = modelViewMatrix * vec4( pos.xyz, 1.0 );

//     gl_PointSize = devicePixelRatio * 1.5;

//     gl_Position = projectionMatrix * mvPosition;
// }

void main() {
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}


`