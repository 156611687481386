import noise from '../../../shaders/noise.glsl'

export default `
uniform float time;
varying vec2 vUv;

${noise}

void main() {
    float color = 0.1 + 0.05 * rand(vUv) + 0.1 * fbm3(4.0 * vUv + 0.0001 * time);
    float radius = length(2.0 * vUv - vec2(1.0));
    float opacity = 0.95 * (1.0 - radius);

    gl_FragColor = vec4(color, color, color, opacity);
}

`