import * as THREE from 'three'

import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js'
// import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js'

import Char from '../char'


class LinePath extends THREE.Curve {

    constructor(t0, t1) {
        super()
        this.t0 = t0
        this.t1 = t1
    }

    getPoint(t) {
        return new THREE.Vector3(
            this.t0[0] + (this.t1[0] - this.t0[0]) * t,
            this.t0[1] + (this.t1[1] - this.t0[1]) * t,
            this.t0[2] + (this.t1[2] - this.t0[2]) * t,
        )
    }
}


class WireChar extends Char {

    constructor(text, size) {
        super(text, { size: size, height: 1, line: { color: 0xffffff }, face: false })

        const tubes = []

        for (let i = 0; i < this.edges.attributes.position.count; i += 2) {
            const p0 = [this.edges.attributes.position.array[3 * i + 0], this.edges.attributes.position.array[3 * i + 1], this.edges.attributes.position.array[3 * i + 2]]
            const p1 = [this.edges.attributes.position.array[3 * i + 3], this.edges.attributes.position.array[3 * i + 4], this.edges.attributes.position.array[3 * i + 5]]

            const line = new LinePath(p0, p1)
            const tube = new THREE.TubeBufferGeometry(line, 1, 0, 0, false)//LineGeometry()

            // tube.setPositions([
            //     this.edges.attributes.position.array[3 * i + 0],
            //     this.edges.attributes.position.array[3 * i + 1],
            //     this.edges.attributes.position.array[3 * i + 2],
            //     this.edges.attributes.position.array[3 * i + 3],
            //     this.edges.attributes.position.array[3 * i + 4],
            //     this.edges.attributes.position.array[3 * i + 5]
            // ])

            tubes.push(tube)
        }

        this.wire = new THREE.Mesh(BufferGeometryUtils.mergeBufferGeometries(tubes).toNonIndexed())
    }
}

export default WireChar