import * as THREE from 'three'

import { GPUComputationRenderer } from 'three/examples/jsm/misc/GPUComputationRenderer.js'
import { MeshSurfaceSampler } from 'three/examples/jsm/math/MeshSurfaceSampler.js';

import compute_original1 from './shaders/compute_original1.glsl'
import compute_original2 from './shaders/compute_original2.glsl'

import compute_position from './shaders/compute_position.glsl'
import compute_previous from './shaders/compute_previous.glsl'

import line_vert from './shaders/line_vert.glsl'
import line_frag from './shaders/line_frag.glsl'


class Lines {

    SIZE = 64

    constructor(renderer, dpr) {

        this.gpgpu = new GPUComputationRenderer(this.SIZE, this.SIZE, renderer)

        this.originalVariable1 = this.gpgpu.addVariable('textureOriginal1', compute_original1, this.gpgpu.createTexture())
        this.originalVariable2 = this.gpgpu.addVariable('textureOriginal2', compute_original2, this.gpgpu.createTexture())

        this.positionVariable = this.gpgpu.addVariable('texturePosition', compute_position, this.gpgpu.createTexture())
        this.previousVariable = this.gpgpu.addVariable('texturePrevious', compute_previous, this.gpgpu.createTexture())

        this.positionVariable.material.uniforms['swapindex'] = { value: 0 }
        this.positionVariable.material.uniforms['seek'] = { value: 0 }
        this.positionVariable.material.uniforms['anchors'] = { value: [] }

        this.gpgpu.setVariableDependencies(this.originalVariable1, [this.originalVariable1])
        this.gpgpu.setVariableDependencies(this.originalVariable2, [this.originalVariable2])
        this.gpgpu.setVariableDependencies(this.positionVariable, [this.originalVariable1, this.originalVariable2, this.positionVariable])
        this.gpgpu.setVariableDependencies(this.previousVariable, [this.previousVariable, this.positionVariable])
        this.gpgpu.init()


        const geometry = new THREE.BufferGeometry()
        const vertices = []

        for (let y = 0; y < this.SIZE; y++) {
            for (let x = 0; x < this.SIZE; x++) {
                vertices.push(x, y, 0) // 始点
                vertices.push(x, y, 1) // 終点
            }
        }

        geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3))

        this.lines = new THREE.LineSegments(geometry, new THREE.ShaderMaterial({
            uniforms: {
                positionTexture: { value: null },
                previousTexture: { value: null },
                uvRatio: { value: 1 / (this.SIZE - 1) },
                opacity: { value: 0.7 }
            },
            depthTest: false,
            depthWrite: false,
            transparent: true,
            vertexShader: line_vert,
            fragmentShader: line_frag
        }))
    }

    set seek(value) {
        this.positionVariable.material.uniforms.seek.value = value
    }

    get seek() {
        return this.positionVariable.material.uniforms.seek.value
    }

    sampling(mesh) {
        const sampler = new MeshSurfaceSampler(mesh)

        sampler.build()

        let originalArray

        if (this.positionVariable.material.uniforms.swapindex.value === 0) {
            this.originalVariable1.initialValueTexture = this.gpgpu.createTexture()
            originalArray = this.originalVariable1.initialValueTexture.image.data
        } else {
            this.originalVariable2.initialValueTexture = this.gpgpu.createTexture()
            originalArray = this.originalVariable2.initialValueTexture.image.data
        }

        const _position = new THREE.Vector3()
        const _normal = new THREE.Vector3()

        for (let y = 0, k = 0; y < this.SIZE; y++) {
            for (let x = 0; x < this.SIZE; x++) {
                sampler.sample(_position, _normal)
                originalArray[k + 0] = _position.x
                originalArray[k + 1] = _position.y
                originalArray[k + 2] = _position.z
                originalArray[k + 3] = 1
                k += 4
            }
        }

        // 制御点更新
        for (let i = 0; i < 10; i++) {
            this.positionVariable.material.uniforms.anchors.value[i] = new THREE.Vector3(
                2000 - 4000 * Math.random(),
                2000 - 4000 * Math.random(),
                2000 - 4000 * Math.random(),
            )
        }

        if (this.positionVariable.material.uniforms.swapindex.value === 0) {
            this.gpgpu.renderTexture(this.gpgpu.variables[0].initialValueTexture, this.gpgpu.variables[0].renderTargets[0])
            this.gpgpu.renderTexture(this.gpgpu.variables[0].initialValueTexture, this.gpgpu.variables[0].renderTargets[1])
        } else {
            this.gpgpu.renderTexture(this.gpgpu.variables[1].initialValueTexture, this.gpgpu.variables[1].renderTargets[0])
            this.gpgpu.renderTexture(this.gpgpu.variables[1].initialValueTexture, this.gpgpu.variables[1].renderTargets[1])
        }

        this.positionVariable.material.uniforms.swapindex.value += 1
        this.positionVariable.material.uniforms.swapindex.value %= 2
    }

    update() {
        this.lines.material.uniforms.previousTexture.value = this.gpgpu.getCurrentRenderTarget(this.previousVariable).texture
        this.gpgpu.compute()
        this.lines.material.uniforms.positionTexture.value = this.gpgpu.getCurrentRenderTarget(this.positionVariable).texture
    }
}

export default Lines