export default `
uniform vec2 resolution;
uniform float radius;

void main() {
    vec2 pos = (gl_FragCoord.xy * 2.0 - resolution) / min(resolution.x, resolution.y);

    float a = length(pos) / radius;

    gl_FragColor = vec4( 1.0, 1.0, 1.0, 1.0 / max(1.0, a) );
}
`