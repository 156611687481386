import gsap from 'gsap'

import Polaris from 'src/lib/Polaris'

import AbstractAnimation from '../abstract'

import DotsChar from './dotschar'
import Mountain from './mountain'


class Type08 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer, { playlist_size: 1.0 })

        const dpr = Polaris.util.clamp(Polaris.device.pixelRatio, 1.0, 2.0)

        this.camera.position.set(0, 150, 1200)
        this.camera.lookAt(0, 150, 0)

        this.composer.addPass(this.renderPass)

        this.mountain = new Mountain(renderer, dpr)
        this.mountain.update()
        this.scene.add(this.mountain.particles)

        const tanka = [
            `風吹けば`,
            `沖つ白浪`,
            `龍田山`,
            `夜半にや君が`,
            `ひとり越ゆらむ`
        ]

        const offsetsX = [0, -700, 100, -200, -300]
        const offsetsZ = [0, -500, 500, -1000, -800]

        this.chars = tanka.map((line, i) => {
            const dz = Polaris.util.rand(-50, 50)

            return Array.from(line).map((text, j) => {
                const char = new DotsChar(renderer, text, 100, dpr)
                char.points.position.x = offsetsX[i] + 200 * j
                char.points.position.y = 50 + dz
                char.points.position.z = offsetsZ[i] -2000
                char.offset = 200 * j
                char.visible = false
                this.scene.add(char.points)
                return char
            })
        })

        this.composer.render()

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createMainTimeline() {
        const timeline = gsap.timeline({ paused: true, onUpdate: () => this.update() })

        // 文字消去
        timeline.add(() => {
            this.chars.forEach((chars) => {
                chars.forEach((char) => char.visible = false)
            })
        })

        // 文字表示アニメーション
        this.chars.forEach((chars) => {

            const subTimeline = gsap.timeline()

            subTimeline.delay(0.5).add([
                chars.map((char, i) => {
                    return gsap.timeline({ onUpdate: () => char.update() }).delay(i * 0.1).add(() => {
                        char.sampling()
                        char.mode = 0
                        char.visible = true
                    }).add([
                        this.tween(char, { opacity: 0 }, { opacity: 1, duration: 1, ease: `power2.in` })
                    ]).add([
                        this.tween({}, { duration: 0.5 })
                    ]).add(() => {
                        char.mode = 1
                    }).add([
                        this.tween(char, { opacity: 1 }, { opacity: 0, duration: 1, ease: `power2.out` })
                    ]).add(() => {
                        char.visible = false
                    })
                })
            ])

            timeline.add(subTimeline)
        })

        // 文字が消えた後も1秒アニメーション
        timeline.add([
            this.tween({}, { duration: 1, ease: `linear` })
        ])

        return timeline
    }

    createDemoTimeline() {
        return this.createMainTimeline().repeat(-1)
    }

    createOpenTimeline() {
        return gsap.timeline({ paused: true, onUpdate: () => this.update() }).add([
            this.tween({}, { duration: 3, ease: `linear` })
        ])
    }

    update() {
        this.mountain.update()
    }
}

export default Type08