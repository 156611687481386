import gsap from 'gsap'

import { AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

import Passthrough from '../../shaders/passthrough/index'

import Polaris from 'src/lib/Polaris'

import AbstractAnimation from '../abstract'

import WireChar from './wirechar'

import Lines from './lines'


class Type12 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer, { playlist_size: 1.0 })

        const dpr = Polaris.util.clamp(Polaris.device.pixelRatio, 1.0, 2.0)

        this.camera.position.set(0, 0, 1500)
        this.camera.lookAt(0, 0, 0)

        this.afterimgPass = new AfterimagePass(0.97)
        this.throughPass = new ShaderPass(Passthrough)

        this.composer.addPass(this.renderPass)
        this.composer.addPass(this.afterimgPass)
        this.composer.addPass(this.throughPass)


        const text = `いにしへのしずのおだまきくりかえしむかしを今になすよしもがな`

        this.chars = Array.from(text).map((text) => {
            return new WireChar(text, 300)
        })

        this.lines = new Lines(renderer, dpr)
        this.lines.sampling(this.chars[0].wire)
        this.lines.update()
        this.scene.add(this.lines.lines)

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createMainTimeline() {
        const timeline = gsap.timeline({ paused: true, onUpdate: () => this.lines.update() })

        this.chars.forEach((char, i) => {
            timeline.add(() => {
                this.lines.sampling(char.wire)
            }).add([
                this.tween(this.lines, { seek: 0 }, { seek: 1, duration: 1.5, ease: `power4.inOut` })
            ]).add([
                this.tween({}, { duration: 0.1 })
            ])
        })
        
        return timeline
    }

    createDemoTimeline() {
        return this.createMainTimeline().repeat(-1)
    }

    createOpenTimeline() {
        const timeline = gsap.timeline({ paused: true, onUpdate: () => this.lines.update() })

        for (let i = 0; i < 3; i++) {
            timeline.add([
                this.tween(this.lines, { seek: 0 }, { seek: 1, duration: 1, ease: `power4.inOut` })
            ])
        }

        return timeline
    }
}

export default Type12