import * as THREE from 'three'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js'

import gsap from 'gsap'

import Polaris from 'src/lib/Polaris'
import Config from './config'

import tanka from './data/tanka'

import Menu from './menu/index'
import Screen from './screen/index'
import Drag from './util/drag'

import { Loader, getClass } from './animations/index'


class Visual {

    screens = []

    // 
    index = 0

    // 再生中のスクリーンインデックス
    current = 0

    // 再生順格納配列
    sequence = Polaris.util.sequence(0, tanka.length - 1)

    // ローディング完了フラグ
    loaded = false

    // オープニングアニメーション中フラグ
    opening = true

    // フォーカス中フラグ
    focusing = false

    // フォーカス完了フラグ
    focused = false

    // アニメーション中フラグ
    animated = false

    // 描画更新フラグ
    needsUpdate = true

    // シャッフルフラグ
    shuffled = false

    listeners = {}

    // アニメーション用変数
    props = {
        wave1: 0,
        wave2: 0,
        time: 0,
        speed: 1
    }


    /*******************************************************************************************************************************
     * 初期化系
     *******************************************************************************************************************************/

    constructor(canvas, playlist) {
        this.scene = new THREE.Scene()
        this.camera = new THREE.PerspectiveCamera(120, 1, 500, 2500)

        this.camera.position.set(0, 0, 1000)
        this.camera.lookAt(0, 0, 0)

        this.renderer = new THREE.WebGLRenderer({ canvas: canvas, alpha: true, antialias: false, stencilBuffer: false })
        this.composer = new EffectComposer(this.renderer)
        this.renderPass = new RenderPass(this.scene, this.camera)
        this.fxaaPass = new ShaderPass(FXAAShader)

        this.composer.addPass(this.renderPass)
        this.composer.addPass(this.fxaaPass)
        this.composer.renderToScreen = true
        this.wrapper = playlist


        // プレイリスト用のラッパー
        this.playlist = new THREE.Object3D()
        this.scene.add(this.playlist)

        // マウス判定用の変数
        this.cursor = new THREE.Vector2()
        this.raycaster = new THREE.Raycaster()

        // ローディング
        this.loader = new Loader(this.renderer)
        this.loader.object.position.set(0, 0, 500)
        this.loader.object.visible = true
        this.scene.add(this.loader.object)

        // メニュー背景
        this.menu = new Menu(this.renderer)
        this.menu.object.position.set(0, 0, 500)
        this.menu.object.visible = false
        this.scene.add(this.menu.object)
    }

    get pixelRatio() {
        return Polaris.util.clamp(Polaris.device.pixelRatio, 1.0, 2.0)
    }

    get landscape() {
        return this.canvasW > this.canvasH
    }

    load() {

        // リサイズイベント登録
        Polaris.util.onResize(() => {
            this.onResize()
        })

        // 描画イベント登録
        gsap.ticker.fps(Config.fps)
        gsap.ticker.add(this.tick.bind(this))

        // アンチエイリアスを無効化
        this.fxaaPass.enabled = false
        
        this.loader.progress(0)

        const promiseChain = tanka.reduce((resolver, param, i) => {
            return resolver.then(() => {
                return new Promise((resolve) => {
                    const screen = new Screen(new (getClass(param.id))(this.renderer))
                    this.screens.push(screen)

                    setTimeout(() => {
                        // ローディング数字の描画
                        this.loader.progress((i + 1) / tanka.length)
                        this.composer.render()
                        resolve()
                    }, 50)
                })
            })
        }, this.loader.fadeIn())
        
        return promiseChain.then(() => {
            return new Promise((resolive) => setTimeout(resolive, 1000))
        }).then(() => {

            this.loader.fadeOut().then(() => {
                this.scene.remove(this.loader.object)

                // ロード完了後にアンチエイリアスを有効化
                this.fxaaPass.enabled = true
            })

            this.loaded = true

            // カーソル判定用のオブジェクト
            this.screenObjects = this.screens.map((screen) => {
                this.playlist.add(screen.object)
                return screen.object
            })

            // スクロールイベント登録
            this.wrapper.addEventListener('scroll', () => {
                this.onScroll()
            })

            // プレイリストをドラッグ&ホイール可能に
            this.drag = new Drag(this.wrapper)

            // スクリーンサイズ調整の実行
            this.onResize()
        })
    }


    /*******************************************************************************************************************************
     * イベント系関数
     *******************************************************************************************************************************/

    onResize() {
        // DOMエリアのサイズ
        this.stageW = window.innerWidth
        this.stageH = window.innerHeight

        // 描画canvasサイズの実効値
        this.canvasW = this.stageW * this.pixelRatio
        this.canvasH = this.stageH * this.pixelRatio

        // 短歌エリアサイズ
        if (this.landscape) {
            this.screenW = this.canvasW
            this.screenH = this.canvasW * 9 / 16

            if (this.screenH > this.canvasH) {
                this.screenH = this.canvasH
            }
        } else {
            this.screenW = this.canvasW
            this.screenH = this.canvasH
        }

        // カメラ更新
        this.camera.aspect = this.canvasW / this.canvasH
        this.camera.updateProjectionMatrix()
        this.cameraAspect = Math.tan(this.camera.fov / 2 / 180 * Math.PI)


        // canvasサイズ調整
        this.renderer.setSize(this.canvasW, this.canvasH)
        this.composer.setSize(this.canvasW, this.canvasH)

        this.fxaaPass.uniforms.resolution.value.x = 1 / this.canvasW
        this.fxaaPass.uniforms.resolution.value.y = 1 / this.canvasH

        // Retina対応
        this.renderer.domElement.style.width = this.stageW + 'px'
        this.renderer.domElement.style.height = this.stageH + 'px'

        // ローディングのフィッティング
        this.loader.resize(this.canvasW, this.canvasH, this.camera.position.z, this.cameraAspect)

        // メニュー背景のフィッティング
        this.menu.resize(this.canvasW, this.canvasH, this.camera.position.z, this.cameraAspect)
        

        if (this.loaded) {
            this.updateScreenSize()

            // スクリーン間隔の更新
            if (this.landscape) {
                this.screenGap = this.screens[0].object.scale.x * 2.1
                this.scrollRate = this.screens[0].object.scale.x * 4.0 / window.innerWidth

                this.screens.forEach((screen, i) => {
                    screen.object.position.x = this.screenGap * +i
                    screen.object.position.y = 0
                })

                this.wrapper.firstElementChild.style.width = this.screenGap * (this.screens.length - 1) / this.scrollRate + window.innerWidth + 'px'
                this.wrapper.firstElementChild.style.height = ''
            } else {
                this.screenGap = this.screens[0].object.scale.y * 2.1
                this.scrollRate = this.screenGap / window.innerHeight

                this.screens.forEach((screen, i) => {
                    screen.object.position.y = this.screenGap * -i
                    screen.object.position.x = 0
                })

                this.wrapper.firstElementChild.style.width = ''
                this.wrapper.firstElementChild.style.height = this.screenGap * (this.screens.length - 1) / this.scrollRate + window.innerHeight + 'px'
            }

            // スクロール位置の更新
            if (this.focused) {
                if (this.landscape) {
                    this.wrapper.scrollLeft = this.current * this.screenGap / this.scrollRate
                } else {
                    this.wrapper.scrollTop = this.current * this.screenGap / this.scrollRate
                }
            }

            this.drag.resize(this.canvasW, this.canvasH)
        }

        // スクロール量の調整
        this.onScroll()

        this.needsUpdate = true
    }

    onScroll() {
        if (this.landscape) {
            this.playlist.position.x = - this.wrapper.scrollLeft * this.scrollRate
            this.playlist.position.y = 0
        } else {
            this.playlist.position.y = + this.wrapper.scrollTop * this.scrollRate
            this.playlist.position.x = 0
        }
        this.needsUpdate = true
    }

    updateScreenSize() {
        this.screens.forEach((screen, i) => {
            // 拡大時かつ選択中とその両隣のみ高画質で表示
            if (this.focusing && Math.abs(i - this.current) <= 1) {
                screen.resize(this.screenW, this.screenH, this.camera)
            } else {
                const scale = this.opening ? 0.5 : screen.animation.props.playlist_size
                screen.resize(this.screenW * scale, this.screenH * scale, this.camera)
            }
        })
    }

    updateText() {
        if ('change' in this.listeners) {
            this.listeners['change'].forEach((callback) => {
                callback(`${tanka[this.current].source}「${tanka[this.current].text}」`)
            })
        }
    }

    tick(time, deltaTime) {
        deltaTime = Polaris.util.clamp(deltaTime / 1000, Config.deltaTime, 50 / 1000)

        if (this.loaded) {
            if (this.needsUpdate) {
                
                // スクリーンの表示判定
                if (this.focused && !this.animated) {
                    this.screens.forEach((screen, i) => {
                        screen.object.visible = (i === this.current)
                    })
                } else {
                    const half = (this.camera.position.z - this.playlist.position.z) * this.cameraAspect * (this.landscape ? this.canvasW / this.canvasH : 1)

                    this.screens.forEach((screen, i) => {
                        let visible

                        if (this.landscape) {
                            const l = this.playlist.position.x + screen.object.position.x - screen.object.scale.x * 1.05
                            const r = this.playlist.position.x + screen.object.position.x + screen.object.scale.x * 1.05
                            visible = (r >= - half && half >= l)
                        } else {
                            const t = this.playlist.position.y + screen.object.position.y + screen.object.scale.y * 1.05
                            const b = this.playlist.position.y + screen.object.position.y - screen.object.scale.y * 1.05
                            visible = (t >= - half && half >= b)
                        }

                        if (this.focused) {
                            screen.object.visible = visible
                        } else {
                            if (screen.object.visible !== visible) {
                                screen.object.visible = visible

                                if (i !== this.current) {
                                    if (this.opening) {
                                        if (visible) {
                                            screen.animation.startOpening()
                                        } else {
                                            screen.animation.pauseOpening()
                                        }
                                    } else {
                                        if (visible) {
                                            screen.animation.resumeDemo()
                                        } else {
                                            screen.animation.pauseDemo()
                                        }
                                    }
                                }
                            }
                        }
                    })
                }

                // はためきアニメーション
                this.props.time += deltaTime * this.props.speed

                if (this.landscape) {
                    this.screens.reduce((carry, screen) => screen.waveX(carry, this.props), { z: this.props.time, y: 0 })
                } else {
                    this.screens.reduce((carry, screen) => screen.waveY(carry, this.props), { y: this.props.time, z: 0 })
                }

                this.needsUpdate = (this.props.wave1 !== 0 || this.props.wave2 !== 0)
            }

            // メニューアニメーション更新
            if (this.menu.object.visible) {
                this.menu.tick(deltaTime, time)
            }

            // アニメーション更新
            this.screens.forEach((screen) => {
                screen.tick(deltaTime, time)
            })
        } else {
            this.loader.render()
        }

        this.composer.render()
    }

    getIntersects(x, y) {
        this.cursor.x = (x / this.stageW) * 2 - 1
        this.cursor.y = 1 - (y / this.stageH) * 2
        this.raycaster.setFromCamera(this.cursor, this.camera)
        return this.raycaster.intersectObjects(this.screenObjects)
    }


    /*******************************************************************************************************************************
     * 制御系の関数
     *******************************************************************************************************************************/

    click(x, y) {
        if (!this.drag.dragging) {
            const intersects = this.getIntersects(x, y)

            if (intersects.length > 0) {
                this.current = this.screenObjects.indexOf(intersects[0].object)

                this.index = this.sequence.reduce((carry, index, i) => {
                    return index === this.current ? i : carry
                }, 0)

                return true
            }
        }
    }

    mouseMove(x, y) {
        const intersects = this.getIntersects(x, y)

        if (intersects.length > 0) {
            return true
        }
    }

    pause() {
        if (this.currentTimeline) {
            this.currentTimeline.pause()
        }
    }

    resume() {
        if (this.currentTimeline) {
            this.currentTimeline.resume()
        }
    }

    next() {
        this.changeScreen(this.index + 1)
    }

    prev() {
        this.changeScreen(this.index - 1)
    }

    head() {
        this.changeScreen(0)
    }

    last() {
        this.changeScreen(tanka.length - 1)
    }

    repeat(num) {
        if (this.currentTimeline) {
            if (num < 0) {
                this.currentTimeline.repeat(-1)
            } else {
                this.currentTimeline.restart()
                this.currentTimeline.repeat(num)
            }
        }
    }

    shuffle(shuffled) {
        this.shuffled = shuffled

        this.sequence = Polaris.util.sequence(0, tanka.length - 1)

        if (this.shuffled) {
            this.sequence = Polaris.util.shuffle(this.sequence)
        } else {
            this.index = this.current
        }
    }

    show() {
        this.focusing = true
        this.focused = true
        this.playlist.position.z = 0
        this.props.wave1 = 0
        this.props.wave2 = 0
        this.props.speed = 1

        this.onResize()
        this.startMain()
        this.killTimeline()
    }

    hide() {
        this.pause()
        this.killTimeline()
    }

    test(index) {
        this.focusing = true
        this.focused = true
        this.current = index
        this.updateScreenSize()
        this.updateText()
        this.onResize()

        this.screens[this.current].animation.kill()
        this.screens[this.current].animation.startMain().then(() => {
            setTimeout(() => {
                if (this.focused) {
                    this.test(this.current)
                }
            })
        })
    }

    onChange(callback) {
        if ('change' in this.listeners) {
            this.listeners['change'].push(callback)
        } else {
            this.listeners['change'] = [callback]
        }
    }


    /*******************************************************************************************************************************
     * アニメーション系の関数
     *******************************************************************************************************************************/

    scroll(index, duration, ease) {
        if (this.landscape) {
            return gsap.to(this.wrapper, { scrollLeft: this.screenGap * index / this.scrollRate, duration: duration, ease: ease })
        } else {
            return gsap.to(this.wrapper, { scrollTop: this.screenGap * index / this.scrollRate, duration: duration, ease: ease })
        }
    }

    startOpening() {
        this.props.wave1 = 80
        this.props.wave2 = 0.4

        if (this.landscape) {
            this.playlist.position.x = -this.screenGap * (tanka.length + 1)
            this.playlist.position.z = -1000
        } else {
            this.playlist.position.y = +this.screenGap * (tanka.length + 1)
            this.playlist.position.z = -1000
        }

        return this.createTimeline().add([
            // スクロールアニメーション
            gsap.to(this.playlist.position, { x: 0, y: 0, duration: tanka.length * 0.25, ease: `sine.inOut` })
        ]).add(() => {
            this.focusing = true
            this.updateText()
        }).add([
            // スケールアニメーション
            gsap.to(this.playlist.position, { z: 0, duration: 1.5, ease: `power2.inOut`, delay: 1.0 }),

            // ウェーブアニメーション
            gsap.timeline().add(gsap.to(this.props, { wave1: 150, duration: 0.5, ease: `power2.inOut` })).add(gsap.to(this.props, { wave1: 0.0, duration: 2.5, ease: `power2.inOut` })),
            gsap.timeline().add(gsap.to(this.props, { wave2: 0.6, duration: 0.5, ease: `power2.inOut` })).add(gsap.to(this.props, { wave2: 0.0, duration: 2.5, ease: `power2.inOut` })),
            gsap.timeline().add(gsap.to(this.props, { speed: 6.0, duration: 0.5, ease: `power2.out`   })).add(gsap.to(this.props, { speed: 1.0, duration: 0.5, ease: `power2.inOut`, delay: 2.0 })),

            // アニメーション開始
            gsap.delayedCall(0.5, () => {
                this.startMain()
            })
        ]).add(() => {
            this.focused = true

            // その他のアニメーションを完全停止し、非表示にする
            this.screens.forEach((screen, i) => {
                if (i !== this.current) {
                    screen.animation.kill()
                    screen.visible = false
                }
            })
        })
    }

    startMain() {
        this.opening = false

        // 高画質に切り替え
        this.updateScreenSize()
        this.updateText()

        if (this.currentTimeline) {
            this.currentTimeline.kill()
        }

        this.currentTimeline = this.screens[this.current].animation.startMain()

        this.currentTimeline.then(() => {
            // フォーカス中なら次のシーンへ
            if (this.focused) {
                this.changeScreen(this.index + 1)
            }
        })
    }


    focus() {
        this.focusing = true

        // 高画質に切り替え
        this.updateScreenSize()

        this.updateText()

        return this.createTimeline().add([
            // スクロールアニメーション
            this.scroll(this.current, 1, `power4.inOut`),

            // スケールアニメーション
            gsap.to(this.playlist.position, { z: 0, duration: 2.4, ease: `power2.inOut` }),

            gsap.to(this.screens[this.current], { noise: 1.0, duration: 2.4, delay: 0.0, ease: `sine.in` }),
            gsap.to(this.screens[this.current], { opacity: 0.0, duration: 1.5, delay: 0.4, ease: `sine.inOut` }),

            // ウェーブアニメーション
            gsap.timeline().add(gsap.to(this.props, { wave1: 150, duration: 0.8, ease: `power2.inOut` })).add(gsap.to(this.props, { wave1: 0, duration: 1.6, ease: `power2.inOut` })),
            gsap.timeline().add(gsap.to(this.props, { wave2: 0.6, duration: 0.8, ease: `power2.inOut` })).add(gsap.to(this.props, { wave2: 0, duration: 1.6, ease: `power2.inOut` })),
            gsap.timeline().add(gsap.to(this.props, { speed: 6.0, duration: 0.8, ease: `power2.out`   })).add(gsap.to(this.props, { speed: 1, duration: 0.8, ease: `power2.inOut`, delay: 0.8 }))
        ]).add(() => {
            this.focused = true

            // メインアニメーションループ開始
            this.startMain()
            this.screens[this.current].noise = 0

            // その他のアニメーションを完全停止し、非表示にする
            this.screens.forEach((screen, i) => {
                if (i !== this.current) {
                    screen.animation.kill()
                    screen.visible = false
                    screen.reset()
                }
            })
        }).add([
            gsap.to(this.screens[this.current], { opacity: 1.0, duration: 0.5, ease: `power2.in` })
        ])
    }

    unfocus() {
        this.focused = false

        this.screens.forEach((screen, i) => {
            if (i !== this.current) screen.reset()
        })

        return this.createTimeline().add([
            // スケールアニメーション
            gsap.to(this.playlist.position, { z: -1000, duration: 1.8, ease: `power2.inOut` }),

            gsap.to(this.screens[this.current], { noise: 0.0, duration: 0.0, ease: `power2.inOut` }),
            gsap.to(this.screens[this.current], { opacity: 1.0, duration: 1.2, delay: 0.6, ease: `power2.out` }),

            // ウェーブアニメーション
            gsap.timeline().add(gsap.to(this.props, { wave1: 150, duration: 0.6, ease: `power2.inOut` })).add(gsap.to(this.props, { wave1: 80., duration: 1.2, ease: `power2.inOut` })),
            gsap.timeline().add(gsap.to(this.props, { wave2: 0.6, duration: 0.6, ease: `power2.inOut` })).add(gsap.to(this.props, { wave2: 0.4, duration: 1.2, ease: `power2.inOut` })),
            gsap.timeline().add(gsap.to(this.props, { speed: 6.0, duration: 0.6, ease: `power2.out`   })).add(gsap.to(this.props, { speed: 1.0, duration: 0.6, ease: `power2.inOut`, delay: 0.6 }))
        ]).add(() => {
            this.focusing = false

            // デモアニメーション開始
            this.screens[this.current].animation.startDemo()

            // 低画質に切り替え
            this.updateScreenSize()
        })
    }

    changeScreen(index) {
        this.index = (index % this.screens.length + this.screens.length) % this.screens.length
        this.current = this.sequence[this.index]

        // メインアニメーションループ開始
        this.startMain()

        this.screens.forEach((screen) => {
            screen.reset()
        })

        return this.createTimeline().add([
            // スクロールアニメーション
            this.scroll(this.current, 1.25, `power4.inOut`),

            // スケールアニメーション
            gsap.to(this.playlist.position, { z: -400, duration: 0.5, ease: `sine.inOut` }).then(() => {
                gsap.to(this.playlist.position, { z: 0, duration: 1.0, ease: `sine.inOut` })
            }),

            // ウェーブアニメーション
            gsap.timeline().add(gsap.to(this.props, { wave1: 120, duration: 0.5, ease: `power2.inOut` })).add(gsap.to(this.props, { wave1: 0.0, duration: 1.0, ease: `power2.inOut` })),
            gsap.timeline().add(gsap.to(this.props, { wave2: 0.1, duration: 0.5, ease: `power2.inOut` })).add(gsap.to(this.props, { wave2: 0.0, duration: 1.0, ease: `power2.inOut` })),
            gsap.timeline().add(gsap.to(this.props, { speed: 6.0, duration: 0.5, ease: `power2.out`   })).add(gsap.to(this.props, { speed: 1.0, duration: 0.5, ease: `power2.inOut`, delay: 0.5 }))
        ])
    }

    showMenu() {
        this.menu.object.visible = true
        this.needsUpdate = true
    }

    hideMenu() {
        this.menu.object.visible = false
        this.needsUpdate = true
    }

    killTimeline() {
        if (this._timeline) {
            this._timeline.kill()
            this._timeline.clear()
            this._timeline = null
        }
    }

    createTimeline() {
        this.killTimeline()

        if (this.drag) {
            this.drag.cancel()
        }

        this.animated = true

        this._timeline = gsap.timeline({
            onStart: () => {
                this.animated = true
            },
            onUpdate: () => {
                this.needsUpdate = true
            },
            onComplete: () => {
                this.animated = false
            },
            onInterrupt: () => {
                this.opening = false
            }
        })

        return this._timeline
    }
}

export default Visual