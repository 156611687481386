
import * as THREE from 'three'

import Config from '../../config'

import { GPUComputationRenderer } from 'three/examples/jsm/misc/GPUComputationRenderer.js'
import { MeshSurfaceSampler } from 'three/examples/jsm/math/MeshSurfaceSampler.js';

import Char from '../char'

import seaweed_sampling from './shaders/seaweed_sampling.glsl'
import seaweed_velocity from './shaders/seaweed_velocity.glsl'
import seaweed_position from './shaders/seaweed_position.glsl'
import seaweed_vert from './shaders/seaweed_vert.glsl'
import seaweed_frag from './shaders/seaweed_frag.glsl'


class Seaweed {

    SIZE = 20

    constructor(renderer, text, size, dpr) {
        this.char = new Char(text, { size: size, height: 0, line: false })
        this.sampler = new MeshSurfaceSampler(this.char.face)
        this.sampler.build()

        this.gpgpu = new GPUComputationRenderer(this.SIZE, this.SIZE, renderer)

        this.samplingVariable = this.gpgpu.addVariable('textureSampling', seaweed_sampling, this.gpgpu.createTexture())
        this.velocityVariable = this.gpgpu.addVariable('textureVelocity', seaweed_velocity, this.gpgpu.createTexture())
        this.positionVariable = this.gpgpu.addVariable('texturePosition', seaweed_position, this.gpgpu.createTexture())

        this.velocityVariable.material.uniforms['seed'] = { value: Math.random() }
        this.velocityVariable.material.uniforms['bubbling'] = { value: false }
        this.velocityVariable.material.uniforms['sampling'] = { value: false }
        this.positionVariable.material.uniforms['sampling'] = { value: false }
        this.positionVariable.material.uniforms['deltaTime'] = { value: Config.deltaTime }
        this.positionVariable.material.uniforms['seek'] = { value: 0 }

        this.gpgpu.setVariableDependencies(this.samplingVariable, [this.samplingVariable])
        this.gpgpu.setVariableDependencies(this.velocityVariable, [this.velocityVariable, this.positionVariable])
        this.gpgpu.setVariableDependencies(this.positionVariable, [this.positionVariable, this.velocityVariable, this.samplingVariable])

        // sampling
        const _array = this.samplingVariable.initialValueTexture.image.data
        const _position = new THREE.Vector3()
        const _normal = new THREE.Vector3()

        for (let y = 0, k = 0; y < this.SIZE; y++) {
            for (let x = 0; x < this.SIZE; x++) {
                this.sampler.sample(_position, _normal)
                _array[k + 0] = _position.x
                _array[k + 1] = _position.y
                _array[k + 2] = _position.z
                _array[k + 3] = 1
                k += 4
            }
        }

        this.gpgpu.init()
        this.char.dispose()

        this.points = new THREE.Points(new THREE.PlaneBufferGeometry(2, 2, this.SIZE - 1, this.SIZE - 1), new THREE.ShaderMaterial({
            uniforms: {
                devicePixelRatio: { value: dpr },
                positionTexture: { value: this.gpgpu.getCurrentRenderTarget(this.positionVariable).texture },
                opacity: { value: 1.0 }
            },
            depthTest: false,
            depthWrite: false,
            transparent: true,
            vertexShader: seaweed_vert,
            fragmentShader: seaweed_frag
        }))
    }

    set visible(value) {
        this.points.visible = value
    }

    set offset(value) {
        this.positionVariable.material.uniforms.offset.value = value
    }

    get offset() {
        return this.positionVariable.material.uniforms.offset.value
    }

    set opacity(value) {
        this.points.material.uniforms.opacity.value = value
    }

    get opacity() {
        return this.points.material.uniforms.opacity.value
    }

    set seek(value) {
        this.positionVariable.material.uniforms.seek.value = value
    }

    get seek() {
        return this.positionVariable.material.uniforms.seek.value
    }

    sampling() {
        this.velocityVariable.material.uniforms.sampling.value = true
        this.positionVariable.material.uniforms.sampling.value = true
    }

    bubbling() {
        this.velocityVariable.material.uniforms.bubbling.value = true
    }

    update() {
        this.gpgpu.compute()
        this.velocityVariable.material.uniforms.sampling.value = false
        this.velocityVariable.material.uniforms.bubbling.value = false
        this.positionVariable.material.uniforms.sampling.value = false
    }
}

export default Seaweed