import * as THREE from 'three'

import Config from '../../config'

import { GPUComputationRenderer } from 'three/examples/jsm/misc/GPUComputationRenderer.js'
import { MeshSurfaceSampler } from 'three/examples/jsm/math/MeshSurfaceSampler.js';

import compute_position from './shaders/compute_position.glsl'
import compute_velocity from './shaders/compute_velocity.glsl'

import dotschar_vert from './shaders/dotschar_vert.glsl'
import dotschar_frag from './shaders/dotschar_frag.glsl'

import Char from '../char'


class DotsChar extends Char {

    SIZE = 100

    constructor(renderer, text, size, height, dpr) {
        super(text, {
            size: size,
            height: height,
            face: { color: 0xffffff, opacity: 1.0 },
            line: { color: 0x000000, opacity: 1.0 }
        })

        this.gpgpu = new GPUComputationRenderer(this.SIZE, this.SIZE, renderer)

        this.sampler = new MeshSurfaceSampler(this.face)
        this.sampler.build()

        this.positionVariable = this.gpgpu.addVariable('texturePosition', compute_position, this.gpgpu.createTexture())
        this.velocityVariable = this.gpgpu.addVariable('textureVelocity', compute_velocity, this.gpgpu.createTexture())


        this.positionVariable.material.uniforms['deltaTime'] = { value: Config.deltaTime }
        this.velocityVariable.material.uniforms['seed'] = { value: 100 * Math.random() }

        this.gpgpu.setVariableDependencies(this.positionVariable, [this.positionVariable, this.velocityVariable])
        this.gpgpu.setVariableDependencies(this.velocityVariable, [this.positionVariable, this.velocityVariable])


        this.points = new THREE.Points(new THREE.PlaneBufferGeometry(2, 2, this.SIZE - 1, this.SIZE - 1), new THREE.ShaderMaterial({
            uniforms: {
                devicePixelRatio: { value: dpr },
                positionTexture: { value: null },
                opacity: { value: 0.0 }
            },
            depthTest: false,
            depthWrite: false,
            transparent: true,
            vertexShader: dotschar_vert,
            fragmentShader: dotschar_frag
        }))
    }

    set visible(visilbe) {
        this.object.visible = visilbe
    }

    set lineOpacity(value) {
        this._lineOpacity = value
        this.line.material.opacity = this.props.line.opacity * this._lineOpacity
    }

    get lineOpacity() {
        return this._lineOpacity
    }

    set faceOpacity(value) {
        this._faceOpacity = value
        this.face.material.opacity = this.props.face.opacity * this._faceOpacity
    }

    get faceOpacity() {
        return this._faceOpacity
    }

    set dotsOpacity(value) {
        this._dotsOpacity = value
        this.points.material.uniforms.opacity.value = this._dotsOpacity
    }

    get dotsOpacity() {
        return this._dotsOpacity
    }

    sampling() {
        this.positionVariable.initialValueTexture = this.gpgpu.createTexture()
        this.velocityVariable.initialValueTexture = this.gpgpu.createTexture()

        const positionArray = this.positionVariable.initialValueTexture.image.data

        const _position = new THREE.Vector3()
        const _normal = new THREE.Vector3()

        for (let y = 0, k = 0; y < this.SIZE; y++) {
            for (let x = 0; x < this.SIZE; x++) {
                this.sampler.sample(_position, _normal)
                _position.applyMatrix4(this.object.matrixWorld)
                positionArray[k + 0] = _position.x
                positionArray[k + 1] = _position.y
                positionArray[k + 2] = _position.z
                positionArray[k + 3] = 1
                k += 4
            }
        }

        this.gpgpu.init()

        this.points.material.uniforms.positionTexture.value = this.gpgpu.getCurrentRenderTarget(this.positionVariable).texture
    }

    update() {
        if (this.points.visible && this.dotsOpacity > 0) {
            this.gpgpu.compute()
        }
    }
}

export default DotsChar