import React from 'react'

import { ColsContext } from './context'


const Provider = (props) => {

    const [ cols, setCols ] = React.useState(null)

    return (
        <ColsContext.Provider value={{ cols, setCols }}>
            {props.children}
        </ColsContext.Provider>
    )
}

export default Provider
