import utility from '../../../shaders/utility.glsl'

export default `
uniform sampler2D screen;
uniform vec2 resolution;
uniform vec4 drops[NUM]; // vec4(xyz, r)
uniform float refraction;

${utility}

float minResolution = min(resolution.x, resolution.y);

// 光源位置
// vec3 light = vec3(0.0, 1.0, 2.0);
vec3 light = vec3(0.0, 0.0, 0.0);


// 光線を表す構造体
struct Ray {
    vec4 color;
    vec3 origin;
    vec3 direction;
};

// 球と光線の交点を表す構造体
struct Inter {
    int index;
    bool inner;
    vec3 position1;
    vec3 position2;
    vec3 normal1;
    vec3 normal2;
};

vec4 getColor(Ray ray) {
    float s = - ray.origin.z / ray.direction.z;
    float px = ray.origin.x + ray.direction.x * s;
    float py = ray.origin.y + ray.direction.y * s;

    if (-1.0 < px && px < 1.0 && -1.0 < py && py < 1.0) {
        return texture2D(screen, 0.5 * COS_FOV * minResolution * vec2(px, py) / resolution + vec2(0.5));
    } else {
        return vec4(0.0, 0.0, 0.0, 1.0);
    }
}

float fresnel(float ri, float cosine) {
    float r0 = (1.0 - ri) / (1.0 + ri);
    r0 = r0 * r0;
    return r0 + (1.0 - r0) * pow(1.0 - cosine, 5.0);
}

Inter nearestIntersection(Ray ray) {
    Inter inter;
    inter.index = -1;
    float maxZ = 0.0;
    float near = 10.0;

    for (int i=0; i<NUM; i++) {
        vec3 s = ray.origin - drops[i].xyz;
        float B = dot(s, ray.direction);
        float C = dot(s, s) - drops[i].w * drops[i].w;
        float D = B * B - C;

        if (D > 0.0) {
            float q = sqrt(D);
            float t1 = - B - q;
            float t2 = - B + q;

            if (t1 >= 0.0 || t2 >= 0.0) {
                if (t1 < near) {
                    near = t1;
                    inter.index = i;
                    inter.inner = t1 < 0.0;
                    inter.position1 = ray.origin + t1 * ray.direction;
                    inter.position2 = ray.origin + t2 * ray.direction;
                    inter.normal1 = normalize(inter.position1 - drops[i].xyz);
                    inter.normal2 = normalize(inter.position2 - drops[i].xyz);
                }
            }
        }
    }

    return inter;
}

vec4 raytrace(Ray ray) {
    Inter inter;
    vec3 reflected;
    vec3 refracted;
    bool hit = false;

    for (int i=0; i<NUM*2; i++) {
        inter = nearestIntersection(ray);

        if (inter.index != -1) {
            hit = true;

            if (inter.inner) {
                // 水滴から空気へ屈折
                refracted = refract(ray.direction, -inter.normal2, refraction);
                ray.origin = inter.position2 + refracted * 0.001;
                ray.direction = refracted;
            } else {
                // 反射光を加算
                // reflected = reflect(ray.direction, inter.normal1);
                float f = fresnel(refraction, max(0.0, dot(-ray.direction, inter.normal1)));
                // vec3 specular = f * vec3(1.0, 1.0, 1.0) * pow(max(0.0, dot(inter.normal1, inter.position1 - light)), 5.0);

                float specular = pow(max(0.0, f * dot(inter.normal1, inter.position1 - light)), 1.5);
                ray.color = blend(vec4(1.0, 1.0, 1.0, specular), ray.color);

                // 空気から水滴へ屈折
                refracted = refract(ray.direction, inter.normal1, 1.0 / refraction);
                ray.origin = inter.position1 + refracted * 0.001;
                ray.direction = refracted;
            }

            if (refracted.x == 0.0 && refracted.y == 0.0) {
                break;
            }
        } else {
            ray.color = blend(ray.color, getColor(ray));
            break;
        }
    }

    if (hit) {
        return ray.color;
    } else {
        return vec4(0.0);
    }
}


void main() {
    vec2 p = (gl_FragCoord.xy * 2.0 - resolution) / minResolution;

    vec3 camera = vec3(0.0,  0.0, 2.0);

    Ray ray;
    ray.color = vec4(0.0);
    ray.origin = camera;
    ray.direction = normalize(vec3(SIN_FOV * p.x, SIN_FOV * p.y, - COS_FOV));

    gl_FragColor = raytrace(ray);
}

`