import * as THREE from 'three'

import { GPUComputationRenderer } from 'three/examples/jsm/misc/GPUComputationRenderer.js'

import Typography from './typography'

import compute_noisemap from './shaders/compute_noisemap.glsl'

import screen_vert from './shaders/screen_vert.glsl'
import screen_frag from './shaders/screen_frag.glsl'


class GpuScreen {

    SIZE = 128

    constructor(renderer, size) {

        this.gpgpu = new GPUComputationRenderer(this.SIZE, this.SIZE, renderer)

        this.typography = new Typography(renderer, size, size)

        this.noisemapVariable = this.gpgpu.addVariable('textureNoisemap', compute_noisemap, this.initTexture())

        this.noisemapVariable.material.uniforms['time'] = { value: 0 }

        this.gpgpu.setVariableDependencies(this.noisemapVariable, [this.noisemapVariable])
        this.gpgpu.init()

        this.object = new THREE.Mesh(new THREE.PlaneBufferGeometry(2, 2, this.SIZE - 1, this.SIZE - 1), new THREE.ShaderMaterial({
            uniforms: {
                noisemapTexture: { value: this.gpgpu.getCurrentRenderTarget(this.noisemapVariable).texture },
                typography: { value: this.typography.texture }
            },
            transparent: true,
            vertexShader: screen_vert,
            fragmentShader: screen_frag
        }))
    }

    get time() {
        return this.noisemapVariable.material.uniforms.time.value
    }

    set time(value) {
        this.noisemapVariable.material.uniforms.time.value = value
    }

    initTexture() {
        const texture = this.gpgpu.createTexture()

        const size = this.SIZE - 1

        for (let y = 0, k = 0; y <= size; y++) {
            for (let x = 0; x <= size; x++) {
                // ノイズ成分
                texture.image.data[k + 0] = 0
                texture.image.data[k + 1] = 0

                // オリジナル位置情報
                texture.image.data[k + 2] = 2 * (x - size / 2) / size
                texture.image.data[k + 3] = 2 * (y - size / 2) / size
                k += 4
            }
        }
        return texture
    }

    update() {
        this.gpgpu.compute()
    }
}

export default GpuScreen