import * as THREE from 'three'

import Config from '../../config'

import { GPUComputationRenderer } from 'three/examples/jsm/misc/GPUComputationRenderer.js'
import { MeshSurfaceSampler } from 'three/examples/jsm/math/MeshSurfaceSampler.js';

import compute_original from './shaders/compute_original.glsl'
import compute_position from './shaders/compute_position.glsl'
import compute_velocity from './shaders/compute_velocity.glsl'

import dots_vert from './shaders/dots_vert.glsl'
import dots_frag from './shaders/dots_frag.glsl'


class Dots {

    SIZE = 200

    constructor(renderer, dpr) {

        this.gpgpu = new GPUComputationRenderer(this.SIZE, this.SIZE, renderer)

        this.originalVariable = this.gpgpu.addVariable('textureOriginal', compute_original, this.gpgpu.createTexture())
        this.positionVariable = this.gpgpu.addVariable('texturePosition', compute_position, this.gpgpu.createTexture())
        this.velocityVariable = this.gpgpu.addVariable('textureVelocity', compute_velocity, this.gpgpu.createTexture())

        this.positionVariable.material.uniforms['deltaTime'] = { value: Config.deltaTime }
        this.velocityVariable.material.uniforms['deltaTime'] = { value: Config.deltaTime }

        this.positionVariable.material.uniforms['mode'] = { value: 0 }
        this.velocityVariable.material.uniforms['mode'] = { value: 0 }
        this.velocityVariable.material.uniforms['seed'] = { value: 0 }

        this.gpgpu.setVariableDependencies(this.originalVariable, [this.originalVariable])
        this.gpgpu.setVariableDependencies(this.positionVariable, [this.originalVariable, this.positionVariable, this.velocityVariable])
        this.gpgpu.setVariableDependencies(this.velocityVariable, [this.originalVariable, this.positionVariable, this.velocityVariable])
        this.gpgpu.init()

        this.points = new THREE.Points(new THREE.PlaneBufferGeometry(2, 2, this.SIZE - 1, this.SIZE - 1), new THREE.ShaderMaterial({
            uniforms: {
                devicePixelRatio: { value: dpr },
                positionTexture: { value: this.gpgpu.getCurrentRenderTarget(this.positionVariable).texture },
                opacity: { value: 0.3 }
            },
            depthTest: false,
            depthWrite: false,
            transparent: true,
            vertexShader: dots_vert,
            fragmentShader: dots_frag
        }))
    }

    set mode(value) {
        this.positionVariable.material.uniforms.mode.value = value
        this.velocityVariable.material.uniforms.mode.value = value
    }

    set seed(value) {
        this.velocityVariable.material.uniforms.seed.value = value
    }

    set opacity(value) {
        this.points.material.uniforms.opacity.value = value
    }

    get opacity() {
        return this.points.material.uniforms.opacity.value
    }

    sampling(mesh) {
        const sampler = new MeshSurfaceSampler(mesh)

        sampler.build()

        // originalTextureのみ更新
        this.originalVariable.initialValueTexture = this.gpgpu.createTexture()

        const _array = this.originalVariable.initialValueTexture.image.data
        const _position = new THREE.Vector3()
        const _normal = new THREE.Vector3()

        for (let y = 0, k = 0; y < this.SIZE; y++) {
            for (let x = 0; x < this.SIZE; x++) {
                sampler.sample(_position, _normal)
                _array[k + 0] = _position.x
                _array[k + 1] = _position.y
                _array[k + 2] = _position.z
                _array[k + 3] = 1
                k += 4
            }
        }

        this.gpgpu.renderTexture(this.gpgpu.variables[0].initialValueTexture, this.gpgpu.variables[0].renderTargets[0])
        this.gpgpu.renderTexture(this.gpgpu.variables[0].initialValueTexture, this.gpgpu.variables[0].renderTargets[1])
    }

    update() {
        this.gpgpu.compute()
    }
}

export default Dots