import noise from '../../../shaders/noise.glsl'

export default `
uniform float time;

${noise}

void main() {
    vec2 uv = gl_FragCoord.xy / resolution.xy;

    vec2 position = texture2D( textureNoisemap, uv ).zw;
    vec2 noisemap = position;

    vec2 nuv = uv * 2.0 + time * 2.0;

    noisemap.x -= 0.15 * simplex( nuv );

    gl_FragColor = vec4( noisemap, position );
}
`