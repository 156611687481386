import noise from '../../../shaders/noise.glsl'

export default `

uniform float time;

${noise}

float noise(vec2 uv) {
    return fbm4(uv);
}

void main() {
    vec2 uv = gl_FragCoord.xy / resolution.xy;

    vec3 potential = texture2D( texturePotential, uv ).xyz;

    potential.y = 300.0 * noise(vec2(uv.x * 2.0, uv.y - time));

    gl_FragColor = vec4(potential, 1.0);

}
`