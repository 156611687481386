import React, { useContext, useEffect, useRef } from 'react'

import { StatesContext } from 'src/hooks/states/context'

import gsap from 'gsap'

import Polaris from 'src/lib/Polaris'

import styles from './marquee.module.scss'


const Marquee = (props) => {

    const el = useRef()

    const { finished } = useContext(StatesContext)

    useEffect(() => {
        if (finished) {
            let tl = null

            const kill = () => {
                if (tl) tl.kill()
            }

            const loop = (offset) => {
                kill()

                tl = gsap.timeline().add([
                    gsap.to({}, { duration: 1 })
                ]).add([
                    gsap.fromTo(el.current, { left: 0 }, { left: -offset, duration: offset * 0.05, ease: `linear` })
                ]).add([
                    gsap.to({}, { duration: 1 })
                ]).add(() => {
                    loop(offset)
                })
            }

            const resizeID = Polaris.util.onResize(() => {
                const offset = Math.max(0, el.current.clientWidth - el.current.parentNode.clientWidth)

                el.current.style.left = ''
                
                if (offset > 0 && window.getComputedStyle(el.current.parentNode).overflow === 'hidden') {
                    loop(offset)
                } else {
                    kill()
                }
            })

            return () => {
                kill()
                Polaris.util.offResize(resizeID)
            }
        }
    }, [finished])

    return (
        <div ref={el} className={styles.marquee}>{props.children}</div>
    )
}

export default Marquee