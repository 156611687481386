
export default `
uniform float devicePixelRatio;
uniform sampler2D positionTexture;

varying float opacity;


void main() {
    vec4 pos = texture2D( positionTexture, uv );

    vec4 mvPosition = modelViewMatrix * vec4( pos.x, pos.y * 2.0, pos.z, 1.0 );

    gl_PointSize = devicePixelRatio * 1.0;

    gl_Position = projectionMatrix * mvPosition;

    if( length(pos.xz) + pos.y * 0.5 < 700.0 ) {
        opacity = 0.10;
    } else {
        opacity = 0.01;
    }
}
`