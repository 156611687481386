import React, { useContext, useState, useEffect } from 'react'

import { StatesContext } from 'src/hooks/states/context'

import Opener from './opener'
import Buttons from './buttons'
import NowPlaying from './nowplaying'

import styles from './index.module.scss'


const Controller = ({ stage }) => {

    const { finished, playlistVisible, navmenuVisible } = useContext(StatesContext)

    const [ hidden, setHidden ] = useState(false)

    const classNames = [styles.controller]

    if (navmenuVisible) {
        classNames.push(styles.hidden)
    }

    // プレイリストを非表示にしたときに遅れて表示させる処理
    useEffect(() => {
        if (playlistVisible) {
            setHidden(true)
        } else {
            setTimeout(() => {
                setHidden(false)
            }, 2500)
        }
    }, [playlistVisible, setHidden])


    return (
        <div className={classNames.join(' ')} aria-hidden={!finished || hidden}>
            <div className={styles.content}>
                <Opener />
                <NowPlaying />
                <Buttons stage={stage} />
            </div>
        </div>
    )
}

export default Controller