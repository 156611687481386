import * as THREE from 'three'

import v_shader from './shaders/vert_shader.glsl'
import f_shader from './shaders/frag_shader.glsl'


class Screen {

    SIZE = 64

    constructor(animation) {
        this.animation = animation

        this.geometry = new THREE.PlaneBufferGeometry(2, 2, this.SIZE - 1, this.SIZE - 1)

        this.material = new THREE.ShaderMaterial({
            uniforms: {
                texture: { value: this.animation.texture },
                aspect: { value: 1 },
                noise: { value: 0 },
                opacity: { value: 1 }
            },
            transparent: true,
            vertexShader: v_shader,
            fragmentShader: f_shader
        })

        this.array = this.geometry.attributes.position.array

        this.object = new THREE.Mesh(this.geometry, this.material)
    }

    set noise(value) {
        this.material.uniforms.noise.value = value
    }

    get noise() {
        return this.material.uniforms.noise.value
    }

    set opacity(value) {
        this.material.uniforms.opacity.value = value
    }

    get opacity() {
        return this.material.uniforms.opacity.value
    }

    reset() {
        this.noise = 0
        this.opacity = 1
    }

    resize(width, height, camera) {
        this.animation.resize(width, height)

        this.material.uniforms.aspect.value = width / height

        const _h = (camera.position.z - this.object.position.z) * Math.tan(camera.fov / 2 / 180 * Math.PI)
        this.object.scale.y = _h
        this.object.scale.x = _h / height * width
    }

    waveX(carry, props) {
        for (let x = 0; x < this.SIZE; x++) {
            carry.z += 0.05

            for (let y = 0; y < this.SIZE; y++) {
                this.array[(y * this.SIZE + x) * 3 + 0] = 2 * x / (this.SIZE - 1) - 1
                this.array[(y * this.SIZE + x) * 3 + 1] = 1 - 2 * y / (this.SIZE - 1) + props.wave2 * Math.sin(carry.z * 0.2)
                this.array[(y * this.SIZE + x) * 3 + 2] = props.wave1 * Math.sin(carry.z + y * 0.02)
            }
        }

        this.geometry.attributes.position.needsUpdate = true

        return carry
    }

    waveY(carry, props) {
        for (let y = 0; y < this.SIZE; y++) {
            carry.y += 0.05

            for (let x = 0; x < this.SIZE; x++) {
                this.array[(y * this.SIZE + x) * 3 + 0] = 2 * x / (this.SIZE - 1) - 1 + props.wave2 * Math.sin(carry.y * 0.2)
                this.array[(y * this.SIZE + x) * 3 + 1] = 1 - 2 * y / (this.SIZE - 1)
                this.array[(y * this.SIZE + x) * 3 + 2] = props.wave1 * Math.sin(carry.y + x * 0.02)
            }
        }

        this.geometry.attributes.position.needsUpdate = true

        return carry
    }

    tick(deltaTime, time) {
        if (this.object.visible) {
            this.animation.render(deltaTime, time)
        }
    }


}

export default Screen