import * as THREE from 'three'

import v_shader from './shaders/raytracing_vert.glsl'
import f_shader from './shaders/raytracing_frag.glsl'

class Raytrace {

    constructor(drops, texture) {
        this.drops = drops.map((drop) => {
            return new THREE.Vector4(drop.x, drop.y, drop.z, drop.r)
        })

        this.geometry = new THREE.PlaneBufferGeometry(2, 2)

        this.material = new THREE.ShaderMaterial({
            defines: {
                NUM: this.drops.length,
                SIN_FOV: Math.sin(60 * 0.5 / 180 * Math.PI),
                COS_FOV: Math.cos(60 * 0.5 / 180 * Math.PI)
            },
            uniforms: {
                drops: { value: this.drops },
                screen: { value: texture },
                resolution: { value: new THREE.Vector2(0, 0) },
                refraction: { value: 1.3330 }
            },
            depthTest: false,
            depthWrite: false,
            transparent: true,
            vertexShader: v_shader,
            fragmentShader: f_shader
        })

        this.object = new THREE.Mesh(this.geometry, this.material)
    }

    update(width, height, camera) {
        this.material.uniforms.resolution.value.x = width
        this.material.uniforms.resolution.value.y = height

        const _h = (camera.position.z - this.object.position.z) * Math.tan(camera.fov / 2 / 180 * Math.PI)
        this.object.scale.y = _h
        this.object.scale.x = _h / height * width
    }
}


export default Raytrace