import React, { useContext } from 'react'

import { Link } from 'gatsby'

import { useLocation } from "@reach/router"
import { StatesContext } from 'src/hooks/states/context'

import Footer from 'src/components/footer/index'

import styles from './navsp.module.scss'


const LinkButton = ({ to, children }) => {
    
    const { pathname } = useLocation()

    const { setPlaylistVisibility, setNavmenuVisibility } = useContext(StatesContext)

    const close = () => {
        setPlaylistVisibility(false)
        setNavmenuVisibility(false)
    }

    if (pathname === to) {
        return <button onClick={close}>{children}</button>
    } else {
        return <Link to={to}>{children}</Link >
    }
}

const NavSP = (props) => {
    return (
        <div className={styles.navsp} aria-hidden={!props.opened}>
            <div className={styles.inner}>
                <div className={styles.menu}>
                    <ul>
                        <li><LinkButton to="/">Home</LinkButton></li>
                        {/* <li><button onClick={showPlaylist}>Playlist</button></li> */}
                        <li><LinkButton to="/books/">Books</LinkButton></li>
                        <li><a href="https://note.com/akashikurihara" target="_blank" rel="noopener noreferrer">Blog</a></li>
                    </ul>
                </div>

                <Footer color="black" />
            </div>
        </div>
    )
}

export default NavSP
