import { World } from 'oimo'

import gsap from 'gsap'

import Polaris from 'src/lib/Polaris'

import AbstractAnimation from '../abstract'

import Char from '../char'


class Type03 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer, { playlist_size: 1.0, near: 100, far: 3000 })

        this.camera.position.set(0, 1200, 0)
        this.camera.lookAt(0, 0, 0)

        this.composer.addPass(this.renderPass)

        const text = [
            `からころも`,
            `きつつなれにし`,
            `つましあれば`,
            `はるばるきぬる`,
            `たびをしぞおもふ`,
        ]

        this.chars = text.reduce((carry, chars, i) => {
            return Array.from(chars).reduce((carry, text, j) => {
                const char = new Char(text, { size: 150, height: 50, line: { color: 0xa0a0a0 }, face: false })
                char.lineNum = i
                char.textNum = j
                carry.push(char)
                return carry
            }, carry)
        }, [])

        this.world = new World({
            broadphase: 3,
            worldscale: 1,
            gravity: [0, -400, 0]
        })

        this.ground = this.world.add({
            type: 'box',
            size: [10000, 100, 10000],
            pos: [0, -50, 0],
            friction: 1.0,
            restitution: 0.1,
        })

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createMainTimeline() {
        return gsap.timeline({ paused: true }).add(() => {
            // 文字を消去
            this.chars.forEach((char) => {
                if (char.body) {
                    this.scene.remove(char.object)
                    char.body.dispose()
                    char.body = null
                }
            })
        }).add([
            this.tween({}, { duration: 30, ease: `linear` }),

            this.chars.map((char, i) => {
                if (char.body) {
                    this.scene.remove(char.object)
                    char.body.dispose()
                    char.body = null
                }

                let px, py, pz, rx, ry, rz, delay = i * 0.3 + char.lineNum * 2.5

                if (char.textNum === 0) {
                    py = 1500
                    px = 120 * Math.sin(2 * char.lineNum / 5 * 2 * Math.PI)
                    pz = 120 * Math.cos(2 * char.lineNum / 5 * 2 * Math.PI) * -1
                    rx = -90
                    ry = 0
                    rz = 0
                } else {
                    py = 1500
                    px = Polaris.util.rand(150, 250) * (Math.random() > 0.5 ? 1 : -1)
                    pz = Polaris.util.rand(150, 250) * (Math.random() > 0.5 ? 1 : -1)
                    rx = -90
                    ry = Polaris.util.rand(-100, 100) / 100
                    rz = Polaris.util.rand(-100, 100) / 100
                }

                return gsap.delayedCall(delay, () => {
                    char.body = this.world.add({
                        type: 'box',
                        size: [
                            char.geometry.boundingBox.max.x - char.geometry.boundingBox.min.x,
                            char.geometry.boundingBox.max.y - char.geometry.boundingBox.min.y,
                            char.geometry.boundingBox.max.z - char.geometry.boundingBox.min.z
                        ],
                        pos: [px, py, pz],
                        rot: [rx, ry, rz],
                        move: true,
                        friction: char.textNum === 0 ? 1000.0 : 0.0005,
                        restitution: char.textNum === 0 ? 0.25 : 0.40,
                    })
                    this.scene.add(char.object)
                })
            })
        ])
    }

    createDemoTimeline() {
        return this.createMainTimeline().repeat(-1)
    }

    createOpenTimeline() {
        return gsap.timeline({ paused: true }).add([
            this.tween({}, { duration: 5, ease: `linear` }),

            this.chars.filter((char) => char.textNum === 0).map((char, i) => {
                return gsap.delayedCall(0, () => {
                    const py = 500
                    const px = +150 * Math.sin(2 * i / 5 * 2 * Math.PI)
                    const pz = -150 * Math.cos(2 * i / 5 * 2 * Math.PI)
                    const rx = -90
                    const ry = Polaris.util.rand(-100, 100) / 100
                    const rz = Polaris.util.rand(-100, 100) / 100

                    char.body = this.world.add({
                        type: 'box',
                        size: [
                            char.geometry.boundingBox.max.x - char.geometry.boundingBox.min.x,
                            char.geometry.boundingBox.max.y - char.geometry.boundingBox.min.y,
                            char.geometry.boundingBox.max.z - char.geometry.boundingBox.min.z
                        ],
                        pos: [px, py, pz],
                        rot: [rx, ry, rz],
                        move: true,
                        friction: 0.0005,
                        restitution: 0.40,
                    })
                    this.scene.add(char.object)
                })
            })
        ])
    }

    resize(width, height) {
        super.resize(width, height)

        if (width > height) {
            this.camera.position.set(0, 1200, 0)
        } else {
            this.camera.position.set(0, 1500, 0)
        }
        this.camera.lookAt(0, 0, 0)
    }

    render(deltaTime) {
        this.world.step(deltaTime)

        this.chars.forEach((char) => {
            if (char.body) {
                char.object.position.copy(char.body.getPosition())
                char.object.quaternion.copy(char.body.getQuaternion())
            }
        })

        this.composer.render()
    }
}

export default Type03