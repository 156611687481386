import { World } from 'oimo'

import gsap from 'gsap'

import Polaris from 'src/lib/Polaris'

import AbstractAnimation from '../abstract'

import Char from '../char'


class Type13 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer, { far: 100 })

        this.composer.addPass(this.renderPass)

        const tanka = [
            `われならで`,
            `下紐解くな`,
            `あさがほの`,
            `夕影待たぬ`,
            `花にはありとも`
        ]

        this.chars = tanka.reduce((carry, chars, i) => {
            return Array.from(chars).reduce((carry, text, j) => {
                const char = new Char(text, { size: 0.100, height: 0.001, face: { color: 0xffffff, opacity: 1.0 }, line: false })
                char.lineNum = i
                char.textNum = j
                carry.push(char)
                return carry
            }, carry)
        }, [])

        this.world = new World({
            broadphase: 3,
            worldscale: 1,
            gravity: [0, -0.4, 0]
        })

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createMainTimeline() {
        return gsap.timeline({ paused: true }).add(() => {
            // 文字を消去
            this.chars.forEach((char) => {
                char.opacity = 0

                if (char.body) {
                    this.scene.remove(char.object)
                    char.body.dispose()
                    char.body = null
                }
            })
        }).add([
            this.chars.map((char, i) => {

                return gsap.timeline().add([
                    gsap.delayedCall(i * 0.4 + char.lineNum * 2.5, () => {
                        char.opacity = 0
                        this.scene.add(char.object)

                        const px = 0.001 * Polaris.util.rand(-150, 150)
                        const py = 0.001 * char.textNum * 20
                        const pz = 0.001 * (2.5 - char.textNum) * 120
                        const rx = Polaris.util.rand(-95, -85)
                        const ry = Polaris.util.rand(-30, +30)
                        const rz = Polaris.util.rand(-10, +10)

                        char.body = this.world.add({
                            type: 'box',
                            move: true,
                            size: [
                                char.geometry.boundingBox.max.x - char.geometry.boundingBox.min.x,
                                char.geometry.boundingBox.max.y - char.geometry.boundingBox.min.y,
                                char.geometry.boundingBox.max.z - char.geometry.boundingBox.min.z
                            ],
                            pos: [px, py, pz],
                            rot: [rx, ry, rz],
                            collidesWith: Math.pow(2, i)
                        })
                        char.body.sleep()
                    })
                ]).add([
                    this.tween(char.object.scale, { x: 0.2, y: 0.2 }, { x: 1, y: 1, z: 1, duration: 3, ease: 'power2.out' }),
                    this.tween(char, { opacity: 1, duration: 2, ease: 'power2.in' })
                ]).add(() => {
                    if (char.body) {
                        char.body.awake()
                        char.body.angularVelocity.x = Polaris.util.rand(-100, 100) / 100
                        char.body.angularVelocity.z = Polaris.util.rand(-100, 100) / 100
                    }
                }).add([
                    this.tween(char, { opacity: 0, duration: 2, ease: 'power2.in' })
                ]).add(() => {
                    if (char.body) {
                        this.scene.remove(char.object)
                        char.body.dispose()
                        char.body = null
                    }
                })
            })
        ])
    }

    createDemoTimeline() {
        return this.createMainTimeline().repeat(-1)
    }

    createOpenTimeline() {
        return gsap.timeline({ paused: true }).add(() => {

            this.chars.filter((char, i) => i < 5).map((char, i) => {

                return gsap.timeline().add([
                    gsap.delayedCall(i * 0.1, () => {
                        char.object.scale.set(1, 1, 1)
                        this.scene.add(char.object)

                        const px = 0.001 * Polaris.util.rand(-150, 150)
                        const py = 0.001 * char.textNum * 20
                        const pz = 0.001 * (2.5 - char.textNum) * 120
                        const rx = Polaris.util.rand(-95, -85)
                        const ry = Polaris.util.rand(-30, +30)
                        const rz = Polaris.util.rand(-10, +10)

                        char.body = this.world.add({
                            type: 'box',
                            move: true,
                            size: [
                                char.geometry.boundingBox.max.x - char.geometry.boundingBox.min.x,
                                char.geometry.boundingBox.max.y - char.geometry.boundingBox.min.y,
                                char.geometry.boundingBox.max.z - char.geometry.boundingBox.min.z
                            ],
                            pos: [px, py, pz],
                            rot: [rx, ry, rz],
                            collidesWith: Math.pow(2, i)
                        })
                        char.body.angularVelocity.x = Polaris.util.rand(-100, 100) / 100
                        char.body.angularVelocity.z = Polaris.util.rand(-100, 100) / 100
                    })
                ])
            })
        }).add([
            this.tween({}, { duration: 2 })
        ])
    }

    resize(width, height) {
        if (width > height) {
            this.camera.position.set(0, 1.000, 0)
        } else {
            this.camera.position.set(0, 1.500, 0)
        }
        this.camera.lookAt(0, 0, 0)
        
        super.resize(width, height)
    }

    render(deltaTime) {
        this.world.step(deltaTime)

        this.chars.forEach((char) => {
            if (char.body) {
                char.object.position.copy(char.body.getPosition())
                char.object.quaternion.copy(char.body.getQuaternion())
            }
        })

        this.composer.render()
    }
}

export default Type13