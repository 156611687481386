import noise from '../../../shaders/noise.glsl'

export default `
uniform float seed;
uniform bool sampling;
uniform bool bubbling;

${noise}

void main() {
    if (sampling) {
        gl_FragColor = vec4( 0.0 );

    } else if (bubbling) {
        vec2 uv = gl_FragCoord.xy / resolution.xy;
        vec3 pos = texture2D( texturePosition, uv ).xyz;
        vec3 vel = texture2D( textureVelocity, uv ).xyz;

        vec3 noise = curlNoise( pos * 0.05 + seed );
        vel.xz += 50.0 * noise.xz;
        vel.y = 150.0 + 100.0 * abs(noise.y);

        gl_FragColor = vec4( vel, 1.0 );

    } else {
        gl_FragColor = texture2D( textureVelocity, gl_FragCoord.xy / resolution.xy );
    }
}
`