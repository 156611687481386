import * as THREE from 'three'

import v_shader from './vert.glsl'
import f_shader from './frag_v.glsl'

const BlurShaderH = {
    uniforms: {
        tDiffuse: { value: null },
        radius: { value: 0 },
        weight: { value: [] },
        resolution: { value: new THREE.Vector2(0, 0) }
    },
    vertexShader: v_shader,
    fragmentShader: f_shader
}

export default BlurShaderH