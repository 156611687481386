import * as THREE from 'three'

import Polaris from 'src/lib/Polaris'
import Config from '../../config'

import { GPUComputationRenderer } from 'three/examples/jsm/misc/GPUComputationRenderer.js'

import bubbles_position from './shaders/bubbles_position.glsl'
import bubbles_vert from './shaders/bubbles_vert.glsl'
import bubbles_frag from './shaders/bubbles_frag.glsl'


class Bubbles {

    SIZE = 64

    constructor(renderer) {

        this.gpgpu = new GPUComputationRenderer(this.SIZE, this.SIZE, renderer)

        this.positionVariable = this.gpgpu.addVariable('texturePosition', bubbles_position, this.gpgpu.createTexture())

        this.positionVariable.material.uniforms['deltaTime'] = { value: Config.deltaTime }

        this.gpgpu.setVariableDependencies(this.positionVariable, [this.positionVariable])
        this.init()

        this.points = new THREE.Points(new THREE.PlaneBufferGeometry(2, 2, this.SIZE - 1, this.SIZE - 1), new THREE.ShaderMaterial({
            uniforms: {
                devicePixelRatio: { value: Polaris.util.clamp(Polaris.device.pixelRatio, 1.0, 2.0) },
                positionTexture: { value: this.gpgpu.getCurrentRenderTarget(this.positionVariable).texture }
            },
            depthTest: false,
            depthWrite: false,
            transparent: true,
            vertexShader: bubbles_vert,
            fragmentShader: bubbles_frag
        }))
    }

    init() {
        this.positionVariable.initialValueTexture = this.gpgpu.createTexture()

        const arrayPos = this.positionVariable.initialValueTexture.image.data

        for (let y = 0, k = 0; y < this.SIZE; y++) {
            for (let x = 0; x < this.SIZE; x++) {
                arrayPos[k + 0] = Polaris.util.rand(-1000, 1000)
                arrayPos[k + 1] = Polaris.util.rand(0, 1000)
                arrayPos[k + 2] = Polaris.util.rand(-1000, 1000)
                arrayPos[k + 3] = 1
                k += 4
            }
        }

        this.gpgpu.init()
    }

    update() {
        this.gpgpu.compute()
    }
}

export default Bubbles