import * as THREE from 'three'

import floor_vert from './shaders/floor_vert.glsl'
import floor_frag from './shaders/floor_frag.glsl'


class Floor {

    START_TIME = Date.now()

    constructor() {

        this.geometry = new THREE.PlaneBufferGeometry(2, 2)

        this.material = new THREE.ShaderMaterial({
            uniforms: {
                time: { value: 0 }
            },
            transparent: true,
            vertexShader: floor_vert,
            fragmentShader: floor_frag
        })

        this.object = new THREE.Mesh(this.geometry, this.material)
        
        this.object.rotation.x = - Math.PI / 2
    }

    set visible(value) {
        this.object.visible = value
    }

    update() {
        this.material.uniforms.time.value = Date.now() - this.START_TIME
    }
}

export default Floor