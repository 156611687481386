import utility from '../../../shaders/utility.glsl'
import noise from '../../../shaders/noise.glsl'

export default `

uniform sampler2D tDiffuse;
uniform sampler2D noiseMap;
// uniform sampler2D randomMap;
uniform float time0;
uniform float time1;
uniform float time2;

varying vec2 vUv;

${utility}
${noise}

float noise(vec2 uv) {
    return texture2D( noiseMap, uv ).x;
}

float random(vec2 uv) {
    return rand(uv);//texture2D( randomMap, uv ).x;
}

void main() {
    float th = random(vUv), sx, sy, dx=0., dy=0.;

    sx = max(0.0, vUv.y - time2);
    sy = max(0.0, vUv.y - time2);

    // ノイズ成分
    if (sx > 0.0) {
        dx += 0.05 * random(vUv + vec2(1.0)) * sx;
        dx += 0.05 * random(vUv - vec2(1.0)) * sy;
    }
    if (th > 0.8) {
        dx += 0.01 * random(vUv + vec2(2.0)) * sin(time0 * 20.0 - vUv.y * 0.1);
        dx += 0.01 * random(vUv - vec2(2.0)) * sin(time0 * 20.0 - vUv.y * 0.1);
    }
    
    // 揺らぎ成分
    if (time1 < 1.0) {
        dx += (1.0 - time1) * 0.5 * noise(vUv * 0.1);
        dy += (1.0 - time1) * 0.5 * noise(vUv * 0.1);
    }

    // 開花
    if (time2 < 1.0) {
        dx += sx * 1.5 * noise(vUv * 0.8);
        dy += sy * 1.5 * noise(vUv * 0.8);
    }
    
    
    // 色合成
    vec4 color1 = texture2D(tDiffuse, vUv);
    vec4 color2 = texture2D(tDiffuse, vec2(vUv.x + dx, vUv.y + dy));

    color1.w *= 0.03;
    color2.y *= 0.90;
    color2.z *= 0.90;

    if (th > 0.8 || color1.x > 0.0) {
    } else {
        color2.w *= 0.1;
    }

    gl_FragColor = blend(blend(color1, color2), vec4(0.0, 0.0, 0.0, 1.0));
}

`