import React, { useRef, useEffect, useContext } from 'react'

import usePrev from 'src/hooks/usePrev'

import { useLocation } from "@reach/router"
import { ColsContext } from 'src/hooks/cols/context'
import { StatesContext } from 'src/hooks/states/context'

import Stage from 'src/lib/visual/index'

import Loading from './loading/index'
import Playlist from './playlist/index'
import Controller from './controller/index'

import styles from './index.module.scss'

const toppageChecker = RegExp(`^/(index.html)?$`)

const Visual = () => {

    const stage = useRef()
    const canvas = useRef()
    const playlist = useRef()

    const { pathname } = useLocation()

    const { cols } = useContext(ColsContext)

    const {
        loaded,
        shuffled,
        setLoaded,
        setFinished,
        setCurrentText,
        playlistVisible,
        setPlaylistVisibility,
        navmenuVisible,
        setNavmenuVisibility
    } = useContext(StatesContext)


    const active = playlistVisible || navmenuVisible
    const visible = !loaded || active || toppageChecker.test(pathname)

    const prevLoaded = usePrev(loaded)
    const prevPathname = usePrev(pathname)
    const prevNavmenuVisible = usePrev(navmenuVisible)
    const prevPlaylistVisible = usePrev(playlistVisible)

    
    // 初期化 & ローディング
    useEffect(() => {
        stage.current = new Stage(canvas.current, playlist.current)

        stage.current.load().then(() => {
            setLoaded(true)
        })

        stage.current.onChange((text) => {
            setCurrentText(text)
        })

    }, [setLoaded, setCurrentText])


    // オープニング
    useEffect(() => {
        if (prevLoaded !== undefined && loaded !== prevLoaded) {
            if (toppageChecker.test(pathname)) {
                if (window.location.search.match(/^\?([0-9]+)$/)) {
                    stage.current.test(parseInt(RegExp.$1))
                    setFinished(true)
                } else {
                    stage.current.startOpening().then(() => {
                        setFinished(true)
                    })
                }
            } else {
                setFinished(true)
                stage.current.hide()
            }
        }
    }, [loaded, prevLoaded, pathname, setFinished])


    // メニュー開閉
    useEffect(() => {
        if (prevNavmenuVisible !== undefined && navmenuVisible !== prevNavmenuVisible) {
            if (navmenuVisible) {
                stage.current.showMenu()

                if (toppageChecker.test(pathname)) {
                    stage.current.pause()
                }
            } else {
                stage.current.hideMenu()

                if (toppageChecker.test(pathname)) {
                    stage.current.resume()
                }
            }
        }
    }, [pathname, navmenuVisible, prevNavmenuVisible])


    // プレイリスト開閉
    useEffect(() => {
        if (prevPlaylistVisible !== undefined && playlistVisible !== prevPlaylistVisible) {
            if (playlistVisible) {
                stage.current.unfocus()
            } else {
                stage.current.focus()
            }
            if (playlistVisible) {
                playlist.current.focus()
            }
        }
    }, [playlistVisible, prevPlaylistVisible])


    // ページ遷移によるイベント管理
    useEffect(() => {
        if (prevPathname !== undefined && pathname !== prevPathname) {
            if (toppageChecker.test(pathname)) {
                stage.current.show()
            } else {
                stage.current.hide()
            }
            setFinished(true)
            setNavmenuVisibility(false)
            setPlaylistVisibility(false)
        }
    }, [pathname, prevPathname, setFinished, setNavmenuVisibility, setPlaylistVisibility])


    // シャッフル
    useEffect(() => {
        stage.current.shuffle(shuffled)
    }, [shuffled])


    // SP・PC切り替え時にメニューを閉じる
    useEffect(() => {
        setNavmenuVisibility(false)
    }, [cols, setNavmenuVisibility])


    return (
        <div className={styles.visual} aria-hidden={!visible}>

            <div className={styles.stage} aria-hidden={!active}>
                <canvas ref={canvas}></canvas>
            </div>

            <Playlist ref={playlist} stage={stage.current} />

            <Controller stage={stage.current} />

            <Loading loaded={loaded} />
        </div>
    )
}

export default Visual
