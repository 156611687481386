import util from '../../shaders/utility.glsl'
import noise from '../../shaders/noise.glsl'

export default `

uniform sampler2D texture;
uniform float aspect;
uniform float noise;
uniform float opacity;
varying vec2 vUv;

const float PI = 3.1415926535;

${util}
${noise}

void main() {
    vec2 uv = vUv;

    if (0.0 < noise && noise < 1.0) {
        float r = length(vec2(uv.x - 0.5, uv.y - 0.5) * vec2(aspect, 1.0));
        float d = noise * 1.4 - r;

        if (0.0 < d && d < 0.4) {
            float dr = 0.2 * sin(d * 2.5 * 3.0 * PI) * exp(- 4.0 * abs(d - 0.2));
            uv.x += dr;
            uv.y += dr;
        }
    }

    vec4 color = texture2D(texture, uv);

    gl_FragColor = blend(vec4(color.rgb, color.w * opacity), vec4(0.0, 0.0, 0.0, 1.0));
}
`