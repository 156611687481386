import gsap from 'gsap'

import { AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass.js'
import { RGBShiftShader } from 'three/examples/jsm/shaders/RGBShiftShader.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

import Polaris from 'src/lib/Polaris'

import AbstractAnimation from '../abstract'

import Char from '../char'
import Dots from './dots'


class Type19 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer, { playlist_size: 1.0 })

        const dpr = Polaris.util.clamp(Polaris.device.pixelRatio, 1.0, 2.0)

        this.camera.position.set(0, 0, 1000)
        this.camera.lookAt(0, 0, 0)

        this.afterimgPass = new AfterimagePass(0.9)
        this.rgbShiftPass = new ShaderPass(RGBShiftShader)

        this.composer.addPass(this.renderPass)
        this.composer.addPass(this.afterimgPass)
        // this.composer.addPass(this.rgbShiftPass)
        this.composer.addPass(this.fxaaPass)


        const text = `世をうみのあまとし人を見るからにめくはせよとも頼まるるかな`

        this.chars = Array.from(text).map((text) => {
            return new Char(text, { size: 250, height: 0, face: { color: 0xffffff }, line: false })
        })

        this.dots = new Dots(renderer, dpr)
        this.dots.sampling(this.chars[0].face)
        this.dots.update()
        this.scene.add(this.dots.points)

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createMainTimeline() {
        const timeline = gsap.timeline({ paused: true, onUpdate: () => this.dots.update() })

        this.chars.forEach((char) => {
            timeline.add(() => {
                this.dots.sampling(char.face)
                this.dots.mode = 0
                this.dots.seed = Math.random()
            }).add([
                this.tween(this.rgbShiftPass.material.uniforms.amount, { value: 0, duration: 0.9 })
            ]).add(() => {
                this.dots.mode = 1
            }).add([
                this.tween(this.rgbShiftPass.material.uniforms.amount, { value: 0.01, duration: 0.6 })
            ])
        })
        
        return timeline
    }

    createDemoTimeline() {
        return this.createMainTimeline().repeat(-1)
    }

    createOpenTimeline() {
        const timeline = gsap.timeline({ paused: true, onUpdate: () => this.dots.update() })

        for (let i = 0; i < 3; i++) {
            timeline.add(() => {
                this.dots.mode = 0
                this.dots.seed = Math.random()
            }).add([
                this.tween(this.rgbShiftPass.material.uniforms.amount, { value: 0, duration: 0.9 })
            ]).add(() => {
                this.dots.mode = 1
            }).add([
                this.tween(this.rgbShiftPass.material.uniforms.amount, { value: 0.01, duration: 0.6 })
            ])
        }

        return timeline
    }
}

export default Type19