import * as THREE from 'three'

const custom_uniforms = `
uniform sampler2D texture;
uniform float opacity;
uniform float offsetX;
uniform float repeatX;
`

const custom_map_fragment = `
#ifdef USE_MAP
    vec2 uv = vec2(offsetX + vUv.x * repeatX, vUv.y);
	vec4 texelColor = texture2D( texture, uv );
	texelColor = mapTexelToLinear( texelColor );
    diffuseColor *= texelColor;

    if (uv.y > 0.75) {
        diffuseColor.a *= 4.0 * (1.0 - uv.y);
    }
#endif
`


class Mirror {

    constructor(texture, offsetX, repeatX) {

        this.geometry = new THREE.PlaneBufferGeometry(2, 2)

        let fragmentShader = THREE.ShaderLib.phong.fragmentShader
        fragmentShader = fragmentShader.replace('#include <map_fragment>', custom_map_fragment)
        fragmentShader = fragmentShader.replace('uniform float opacity;', custom_uniforms)


        this.material = new THREE.ShaderMaterial({
            uniforms: THREE.UniformsUtils.merge([
                THREE.ShaderLib.phong.uniforms,
                { texture: { value: texture } },
                { offsetX: { value: offsetX } },
                { repeatX: { value: repeatX } }
            ]),
            side: THREE.DoubleSide,
            lights: true,
            transparent: true,
            vertexShader: THREE.ShaderLib.phong.vertexShader,
            fragmentShader: fragmentShader
        })

        this.material.map = texture
        this.material.uniforms.texture.value = texture

        this.blackMaterial = new THREE.MeshBasicMaterial({
            side: THREE.DoubleSide,
            color: 0x000000
        })

        this.object = new THREE.Mesh(this.geometry, this.material)
    }

    set scaleX(value) {
        this.object.scale.x = value
    }

    get scaleX() {
        return this.object.scale.x
    }

    set scaleY(value) {
        this.object.scale.y = value
    }

    get scaleY() {
        return this.object.scale.y
    }

    darken() {
        this.object.material = this.blackMaterial
    }

    restore() {
        this.object.material = this.material
    }
}

export default Mirror