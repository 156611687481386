import util from '../../../shaders/utility.glsl'

export default `
uniform sampler2D maskTexture;
uniform sampler2D blurTexture;
uniform sampler2D mainTexture;

varying vec2 vUv;

${util}

void main() {
    vec4 maskColor = texture2D( maskTexture, vUv );
    vec4 blurColor = texture2D( blurTexture, vUv );
    vec4 mainColor = texture2D( mainTexture, vUv );

    if (maskColor.r == 0.0 && maskColor.g == 0.0 && maskColor.b == 0.0) {
        gl_FragColor = blurColor + mainColor;
    } else {
        gl_FragColor = blurColor;
    }
}
`