export default `
uniform float deltaTime;
uniform int mode;

void main() {
    vec2 uv = gl_FragCoord.xy / resolution.xy;

    vec3 ori = texture2D( textureOriginal, uv ).xyz;
    vec3 pos = texture2D( texturePosition, uv ).xyz;
    vec3 vel = texture2D( textureVelocity, uv ).xyz;

    if (mode == 0) {
        pos += (ori - pos) * deltaTime * 12.0 + vel;
    } else {
        pos += vel * deltaTime * 60.0;
    }

    gl_FragColor = vec4( pos, 1.0 );
}
`