import * as THREE from 'three'

import Char from '../char'

import v_shader from './shaders/vert_shader.glsl'
import f_shader from './shaders/frag_shader.glsl'


class StepChar extends Char {

    constructor(text, size) {
        super(text, { size: size, height: 0, face: { color: 0xffffff }, line: false })

        this.face.material = new THREE.ShaderMaterial({
            uniforms: {
                seed: { value: Math.random() },
                opacity: { value: 0.0 },
                threshold: { value: 1.0 }
            },
            transparent: true,
            vertexShader: v_shader,
            fragmentShader: f_shader
        })
    }

    set opacity(value) {
        if (this.face.material.uniforms) {
            this.face.material.uniforms.opacity.value = value
        }
    }

    get opacity() {
        return this.face.material.uniforms.opacity.value
    }

    set threshold(value) {
        this.face.material.uniforms.threshold.value = value
    }

    get threshold() {
        return this.face.material.uniforms.threshold.value
    }
}

export default StepChar