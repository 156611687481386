import * as THREE from 'three'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'

import Char from '../char'


class Typography {

    constructor(renderer, width, height) {
        this.camera = new THREE.PerspectiveCamera(45, 1, 1, 4)
        this.scene = new THREE.Scene()

        this.camera.aspect = width / height
        this.camera.position.set(0, 0, 3)
        this.camera.lookAt(0, 0, 0)
        this.camera.updateProjectionMatrix()

        this.offscreen = new THREE.WebGLRenderTarget(1, 1, {
            magFilter: THREE.LinearFilter,
            minFilter: THREE.LinearFilter
        })

        this.composer = new EffectComposer(renderer, this.offscreen)
        this.renderpass = new RenderPass(this.scene, this.camera)

        this.composer.setSize(width, height)
        this.composer.addPass(this.renderpass)
        this.composer.renderToScreen = false

        const text = [
            `大原や`,
            `小塩の山も`,
            `今日こそは`,
            `神代のことも`,
            `思ひいずらめ`
        ]

        text.forEach((line, i) => {
            line.split('').forEach((text, j) => {
                const char = new Char(text, { size: 0.150, height: 0, face: { color: 0xffffff }, line: false })
                char.x = 0.300 * (2.0 - i)
                char.y = 0.250 * (2.5 - j)
                this.scene.add(char.object)
            })
        })

        this.back = new THREE.Mesh(new THREE.PlaneBufferGeometry(2, 2), new THREE.MeshBasicMaterial({ color: 0x030303 }))
        this.back.position.z = -1
        this.back.scale.y = (this.camera.position.z - this.back.position.z) * Math.tan(this.camera.fov / 2 / 180 * Math.PI)
        this.back.scale.x = this.back.scale.y / height * width
        this.scene.add(this.back)

        this.composer.render()
    }

    get texture() {
        return this.composer.readBuffer.texture
    }
}

export default Typography