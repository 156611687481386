import * as THREE from 'three'

import { World } from 'oimo'

import gsap from 'gsap'

import AbstractAnimation from '../abstract'

import Char from '../char'


class Type23 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer, { near: 0.5, far: 10 })

        this.scene.fog = new THREE.Fog(0x000000, 5, 10)

        this.camera.position.set(0.0, 0.0, 2.5)
        this.camera.lookAt(0.0, 0.0, 0.0)

        this.composer.addPass(this.renderPass)

        this.world = new World({
            broadphase: 3,
            worldscale: 1,
            gravity: [0, -0.0, -0.8]
        })

        this.texts = [
            `世をうみのあまとし人を見るからにめくはせよとも頼まるるかな`,
            `ちはやぶる神代も聞かず龍田河からくれないに水くくるとは`,
            `浪間より見ゆる小島のはまびさし久しくなりぬ君にあひ見で`,
            `むつましと君はしらなみみづがきの久しき世よりいはひそめてき`,

            `月やあらぬ春や昔の春ならぬわが身一つはもとの身にして`,
            `白玉かなにぞと人の問ひし時露とこたへて消えなましものを`,
            `からころも着つつなれにしつましあればはるばる来ぬる旅をしぞ思ふ`,
            `名にし負はばいざこと問はむ都鳥わが思ふ人はありやなしやと`,
            `栗原のあねはの松の人ならば都のつとにいざといはましを`,
            `あだなりと名にこそ立てれ櫻花年にまれなる人も待ちけり`,
            `天雲のよそにのみして経ることはわがゐる山の風はやみなり`,
            `風吹けば沖つ白浪龍田山夜半にや[なければ]君がひとり越ゆらむ`,
            `あづさ弓ま弓つき弓年[ひとしき]を経て[人し]わがせしがごとうるはしみせよ`,
            `みるめなきわが身を浦と知ら[われと]ねばや離れなで海人の足たゆく来る`,
            `おもほえず袖にみなとの[やみぬ]さわぐかな[べき]もろこし船の寄りしばかりに`,
            `いにしへのしずのおだまきくりかえし[ただに]むかしを今になすよしもがな`,
            `われならで下紐解くなあさ[言はでぞ]がほの夕影待たぬ花にはありとも`,
            `葎生ひて荒れたる宿のうれたき[こと]はかりにも鬼のすだくなりけり`,
            `五月待つ花橘の香をかげば[思ふ]むかしの人の袖の香ぞする`,
            `君や来しわれや行きけむおもほえず夢かうつつか寝てかさめてか`,
            `大原や小塩の山も今日こそは神代のことも思ひいずらめ`,
            `忘れては夢かとぞ思ふ思ひきや雪踏みわけて君を見むとは`,
            `世をうみのあまとし人を見るからにめくはせよとも頼まるるかな`,
            `ちはやぶる神代も聞かず龍田河からくれないに水くくるとは`,
            `浪間より見ゆる小島のはまびさし久しくなりぬ君にあひ見で`,
            `むつましと君はしらなみみづがきの久しき世よりいはひそめてき`,

            `月やあらぬ春や昔の春ならぬわが身一つはもとの身にして`,
            `白玉かなにぞと人の問ひし時露とこたへて消えなましものを`,
            `からころも着つつなれにしつましあればはるばる来ぬる旅をしぞ思ふ`,
            `名にし負はばいざこと問はむ都鳥わが思ふ人はありやなしやと`,
        ]

        this.chars = this.texts.map((line) => {
            let fall = true

            return Array.from(line).reduce((carry, text) => {
                if (text !== '[' && text !== ']') {
                    const char = new Char(text, { size: 0.06, height: 0.001, face: { color: 0xffffff }, line: false })
                    char.fall = fall
                    this.scene.add(char.object)
                    carry.push(char)
                } else {
                    fall = !fall
                }

                return carry

            }, [])
        })

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createMainTimeline() {
        return gsap.timeline({ paused: true }).add(()=> {
            this.chars.forEach((chars, i) => {
                chars.forEach((char, j) => {
                    char.opacity = 0
                    char.x = 0.15 * (i - this.texts.length / 2)
                    char.y = 0.15 * (15 - j)
                    char.z = 2.0 + 5.0 * Math.random()//- 3.0 * (1.0 - 2.0 * Math.random())
                    char.object.rotation.set(0, 0, 0)

                    if (char.body) {
                        char.body.dispose()
                        char.body = null
                    }
                })
            })
        }).add([
            this.tween({}, { duration: 1, ease: `linear` })
        ]).add([
            this.chars.reduce((tweens, chars, i) => {
                return chars.reduce((tweens, char, j) => {
                    const timeline = gsap.timeline()
                    const delay = 3 * Math.random()

                    timeline.add([
                        this.tween(char, { delay: delay, opacity: 1, duration: 2, ease: `power2.out` }),
                        this.tween(char, { delay: delay, z: 0, duration: 3, ease: `power2.out` }),
                    ])

                    tweens.push(timeline)

                    return tweens
                }, tweens)
            }, [])
        ]).add([
            this.tween({}, { duration: 1, ease: `linear` })
        ]).add([
            this.chars.reduce((tweens, chars) => {
                return chars.reduce((tweens, char) => {
                    const timeline = gsap.timeline()

                    timeline.add(() => {
                        if (char.fall) {
                            char.body = this.world.add({
                                type: 'box',
                                size: [
                                    char.geometry.boundingBox.max.x - char.geometry.boundingBox.min.x,
                                    char.geometry.boundingBox.max.y - char.geometry.boundingBox.min.y,
                                    char.geometry.boundingBox.max.z - char.geometry.boundingBox.min.z
                                ],
                                pos: [char.x, char.y, char.z],
                                rot: [0, 0, 0],
                                move: true,
                                friction: 0.01,
                                restitution: 0.01,
                            })
                            char.body.sleep()
                        }
                    }).add([
                        this.tween({}, { duration: 3 * Math.random(), ease: `linear` })
                    ]).add(() => {
                        if (char.body) {
                            char.body.awake()
                            char.body.angularVelocity.x = (1 - 2 * Math.random())
                            char.body.angularVelocity.z = (1 - 2 * Math.random())
                        }
                    })

                    tweens.push(timeline)

                    return tweens
                    
                }, tweens)
            }, [])
        ]).add([
            this.tween({}, { duration: 15, ease: `linear` })
        ])
    }

    createDemoTimeline() {
        return this.createMainTimeline().repeat(-1)
    }

    createOpenTimeline() {
        return gsap.timeline({ paused: true }).add(() => {
            this.chars.forEach((chars, i) => {
                chars.forEach((char, j) => {
                    char.opacity = 0
                    char.x = 0.15 * (i - this.texts.length / 2)
                    char.y = 0.15 * (15 - j)
                    char.z = 1.0 + 1.0 * Math.random()
                    char.object.rotation.set(0, 0, 0)
                })
            })
        }).add([
            this.chars.reduce((tweens, chars, i) => {
                return chars.reduce((tweens, char, j) => {
                    const timeline = gsap.timeline()
                    const delay = 1 * Math.random()

                    timeline.add([
                        this.tween(char, { delay: delay, opacity: 1, duration: 1, ease: `power2.out` }),
                        this.tween(char, { delay: delay, z: 0, duration: 2, ease: `power2.out` }),
                    ])

                    tweens.push(timeline)

                    return tweens
                }, tweens)
            }, [])
        ])
    }

    resize(width, height) {
        if (width > height) {
            this.camera.position.set(0.0, 0.0, 2.5)
        } else {
            this.camera.position.set(0.0, 0.0, 3.5)
        }
        this.camera.lookAt(0.0, 0.0, 0.0)

        super.resize(width, height)
    }

    render(deltaTime) {
        this.world.step(deltaTime)

        this.chars.forEach((chars) => {
            chars.forEach((char) => {
                if (char.body) {
                    char.object.position.copy(char.body.getPosition())
                    char.object.quaternion.copy(char.body.getQuaternion())
                }
            })
        })

        this.composer.render()
    }
}

export default Type23