import * as THREE from 'three'

import gsap from 'gsap'

import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
// import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'

import AbstractAnimation from '../abstract'
import Char from '../char'
import Floor from './floor'

// import noise from '../../shaders/noise.glsl'

// const dithering_vert = `
// varying vec2 vUv;

// void main() {
//     vUv = uv;
//     gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
// }
// `

// const dithering_frag = `
// uniform sampler2D texture;
// varying vec2 vUv;

// ${noise}

// void main() {
//     vec4 color = texture2D(texture, vUv);
//     color.rgb += 0.01 *rand(vUv);
//     gl_FragColor = vec4( dithering(color.rgb), color.a );
// }
// `

class Type05 extends AbstractAnimation {

    cameraProps = {
        radius: 1200,
        radian: 0
    }

    constructor(renderer) {
        super(renderer)

        this.camera.far = 5

        this.scene.fog = new THREE.Fog(0x000000, 2, 3)

        this.bloomPass = new UnrealBloomPass(new THREE.Vector2(256, 256), 0.7, 0.5, 0.5)

        // this.ditheringPass = new ShaderPass({
        //     uniforms: {
        //         texture: { value: null }
        //     },
        //     vertexShader: dithering_vert,
        //     fragmentShader: dithering_frag
        // }, 'texture')

        this.composer.addPass(this.renderPass)
        // this.composer.addPass(this.ditheringPass)
        this.composer.addPass(this.bloomPass)

        this.floor = new Floor()
        this.floor.object.scale.x = 1.2
        this.floor.object.scale.y = 1.2
        this.floor.object.position.y = -0.001
        this.floor.object.position.z = 0.4
        this.scene.add(this.floor.object)

        const text1 = [
            `栗原のあねはの`,
            `松の人ならば`
        ]

        const text2 = [
            `都のつとにいざ`,
            `といはましを`
        ]

        this.whiteWrap = new THREE.Object3D()
        this.whiteWrap.position.z = 0.4
        this.scene.add(this.whiteWrap)

        this.whiteChars = text1.reduce((carry, line, i) => {
            line.split('').forEach((text, j) => {
                const char = new Char(text, { size: 0.050, height: 0, face: { color: 0x0e0e0e, opacity: 1.0 }, line: false })
                char.x = 0.100 * (0.5 - i)
                char.y = 0.050 + (line.length - j) * 0.075
                this.whiteWrap.add(char.object)
                carry.push(char)
            })
            return carry
        }, [])

        this.blackWrap = new THREE.Object3D()
        this.blackWrap.position.z = 0.4
        this.blackWrap.rotation.x = - Math.PI / 2
        this.scene.add(this.blackWrap)

        this.blackChars = text2.reduce((carry, line, i) => {
            line.split('').forEach((text, j) => {
                const char = new Char(text, { size: 0.050, height: 0, face: { color: 0x000000, opacity: 1.0 }, line: false })
                char.x = 0.100 * (0.5 - i)
                char.y = 0.050 + (line.length - j) * 0.075
                this.blackWrap.add(char.object)
                carry.push(char)
            })
            return carry
        }, [])

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createTimeline(delay, after) {
        const timeline = gsap.timeline({ paused: true })

        // 初期化
        timeline.add(() => {
            this.floor.intensity = 0
            this.cameraProps.radian = 0
            this.blackWrap.scale.y = 0.5

            this.whiteChars.forEach((char) => {
                char.face.material.color = { r: 0.01, g: 0.01, b: 0.01 }
                char.lighting = false
            })
        })

        // 1文字ずつランダムに点灯
        timeline.add([
            this.whiteChars.map((char) => {
                return gsap.timeline().delay(delay + Math.random()).add(() => {
                    char.face.material.color = { r: 1.0, g: 1.0, b: 1.0 }
                    char.face.lighting = true
                    this.floor.intensity += 1 / 20
                })
            })
        ])

        // カメラアングルアニメーション
        timeline.add([
            this.tween({}, { duration: 1 })
        ]).add([
            this.tween(this.cameraProps, { radian: 30 / 180 * Math.PI, duration: 5, ease: `power2.inOut` }),
            this.tween(this.blackWrap.scale, { y: 1.2, duration: 5, ease: `power2.inOut` })
        ]).add([
            this.tween({}, { duration: after })
        ])

        return timeline
    }

    createMainTimeline() {
        return this.createTimeline(2, 3)
    }

    createDemoTimeline() {
        return this.createTimeline(0, 1).repeat(-1)
    }

    createOpenTimeline() {
        const timeline = gsap.timeline({ paused: true })
        
        timeline.add([
            // 1文字ずつランダムに点灯
            this.whiteChars.map((char) => {
                return gsap.timeline().delay(0.5 * Math.random()).add(() => {
                    char.face.material.color = { r: 1.0, g: 1.0, b: 1.0 }
                    char.face.lighting = true
                    this.floor.intensity += 1 / 20
                })
            }),

            // カメラアングルアニメーション
            this.tween(this.cameraProps, { radian: 30 / 180 * Math.PI, duration: 2, ease: `power2.inOut` }),
            this.tween(this.blackWrap.scale, { y: 1.2, duration: 2, ease: `power2.inOut` })
        ])

        return timeline
    }

    resize(width, height) {
        super.resize(width, height)

        if (width > height) {
            this.cameraProps.radius = 1.5
        } else {
            this.cameraProps.radius = 2.0
        }
    }

    render() {
        this.camera.position.y = 1.0
        this.camera.position.x = this.cameraProps.radius * Math.sin(this.cameraProps.radian)
        this.camera.position.z = this.cameraProps.radius * Math.cos(this.cameraProps.radian)
        this.camera.lookAt(0, 0.05, 0)
        this.composer.render()
    }
}

export default Type05