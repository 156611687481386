import React, { useContext } from 'react'

import { StatesContext } from 'src/hooks/states/context'

import styles from './button.module.scss'


const Button = () => {
    
    const { sleep, playlistVisible, navmenuVisible, setNavmenuVisibility } = useContext(StatesContext)

    const classNames = [styles.button]

    if (sleep && !playlistVisible) {
        classNames.push(styles.sleeping)
    }

    const click = () => {
        setNavmenuVisibility(!navmenuVisible)
    }

    return (
        <button onClick={click} className={classNames.join(' ')} aria-label="メニューを開く" aria-expanded={navmenuVisible} aria-hidden={playlistVisible}>
            <span></span>
        </button>
    )
}

export default Button
