import React from 'react'

import Lottie from 'react-lottie'

import { CSSTransition } from 'react-transition-group'

import data from './loading.json'

import styles from './index.module.scss'


const Loading = ({ loaded }) => {

    const optiosn = {
        loop: true,
        autoplay: true,
        animationData: data,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    return (
        <CSSTransition in={!loaded} timeout={1000} unmountOnExit classNames={ { exit: styles.exit } }>
            <div className={styles.loading}>
                <div className={styles.svg}>
                    <Lottie options={optiosn} width={110} height={30}></Lottie>
                </div>
            </div>
        </CSSTransition>
    )
}

export default Loading