import * as THREE from 'three'

import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

import blur_vert from './shaders/blur_vert.glsl'
import blur_frag from './shaders/blur_frag.glsl'


class BlurPass {

    constructor(radius) {

        this.pass = new ShaderPass({
            uniforms: {
                tDiffuse: { value: null },
                radius: { value: 0 },
                weight: { value: [] },
                resolution: { value: new THREE.Vector2(0, 0) }
            },
            vertexShader: blur_vert,
            fragmentShader: blur_frag
        })

        this.blur = radius
    }

    set blur(radius) {
        this._blur = radius

        const weight = new Array(10);

        let t = 0.0;
        let d = radius * radius / 100;

        for (let i = 0; i < 10; i++) {
            var r = i;
            var w = Math.exp(-0.5 * (r * r) / d);
            weight[i] = w;
            t += w;
        }
        for (let i = 0; i < 10; i++) {
            weight[i] /= t;
        }

        this.pass.uniforms.radius.value = radius
        this.pass.uniforms.weight.value = weight
    }

    resize(width, height) {
        this.pass.uniforms.resolution.value.x = width
        this.pass.uniforms.resolution.value.y = height
    }
}

export default BlurPass