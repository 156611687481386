import { Vector2, PlaneBufferGeometry, ShaderMaterial } from 'three'

import AbstractScreen from '../screen'

import particle_vert from './shaders/water_vert.glsl'
import particle_frag from './shaders/water_frag.glsl'


class Water extends AbstractScreen {

    constructor(typoTexture) {
        super(new PlaneBufferGeometry(2, 2), new ShaderMaterial({
            uniforms: {
                resolution: { value: new Vector2(1, 1) },
                typoTexture: { value: typoTexture },
                heightmap: { value: null },
                deltaY: { value: 0 }
            },
            depthTest: false,
            depthWrite: false,
            transparent: true,
            vertexShader: particle_vert,
            fragmentShader: particle_frag
        }))
    }

    fit(canvasW, canvasH, camera) {
        super.fit(canvasW, canvasH, camera)
        this.material.uniforms.resolution.value.x = canvasW
        this.material.uniforms.resolution.value.y = canvasH
    }
}

export default Water