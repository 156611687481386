
import { World } from 'oimo'

import Polaris from 'src/lib/Polaris'

import gsap from 'gsap'

import AbstractAnimation from '../abstract'

import Char from '../char'


class Type21 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer, { playlist_size: 1.0, far: 10 })

        this.camera.position.set(-1, 2, 1)
        this.camera.lookAt(1, 0, 0)

        this.composer.addPass(this.renderPass)

        const texts = [
            `浪間より見ゆる小島のはまびさし久しくなりぬ君にあひ見で`,
            `見ゆる小島のはまびさし久しくなりぬ君にあひ見で浪間より`,
            `はまびさし久しくなりぬ君にあひ見で浪間より見ゆる小島の`,
            `久しくなりぬ君にあひ見で浪間より見ゆる小島のはまびさし`,
            `君にあひ見で浪間より見ゆる小島のはまびさし久しくなりぬ`,
        ]

        this.chars = texts.map((line) => {
            return Array.from(line).map((text) => {
                return new Char(text, { size: 0.2, height: 0.05, line: { color: 0xa0a0a0 }, face: false })
            })
        })

        this.world = new World({
            broadphase: 3,
            worldscale: 1,
            gravity: [0, -9.8, 0.7]
        })

        this.ground = this.world.add({
            type: 'box',
            size: [100, 10, 100],
            pos: [0, -5, 0],
            friction: 1.0,
            restitution: 1.0,
        })

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createMainTimeline() {
        return gsap.timeline({ paused: true }).add(() => {
            // 文字を消去
            this.chars.forEach((chars) => {
                chars.forEach((char) => {
                    this.scene.remove(char.object)
                    char.opacity = 0

                    if (char.body) {
                        char.body.dispose()
                        char.body = null
                    }
                })
            })
        }).add([
            this.chars.map((chars, i) => {
                return gsap.timeline().add([
                    this.tween({}, { duration: 1.0 + i * 5, ease: `linear` })
                ]).add(() => {
                    chars.forEach((char, j) => {
                        const px = j * 0.2
                        const py = 0.025
                        const pz = 1.0 + 0.5 * Math.random()

                        char.body = this.addChar(char, px, py, pz)
                        char.body.linearVelocity.x = Polaris.util.rand(-100, 100) * 0.005
                        char.body.linearVelocity.z = Polaris.util.rand(80, 120) * -0.016
                        this.scene.add(char.object)
                    })
                }).add([
                    chars.map((char) => {
                        return this.tween(char, { opacity: 1, duration: 1, ease: `power2.in` })
                    })
                ]).add([
                    this.tween({}, { duration: 4.5, ease: `linear` })
                ]).add([
                    chars.map((char) => {
                        return this.tween(char, { opacity: 0, duration: 1.5, ease: `power2.out` })
                    })
                ]).add(() => {
                    chars.forEach((char) => {
                        this.scene.remove(char.object)

                        if (char.body) {
                            char.body.dispose()
                            char.body = null
                        }
                    })
                })
            })
        ])
    }

    createDemoTimeline() {
        return this.createMainTimeline().repeat(-1)
    }

    createOpenTimeline() {
        return gsap.timeline({ paused: true }).add(() => {
            // 文字を消去
            this.chars.forEach((chars) => {
                chars.forEach((char) => {
                    this.scene.remove(char.object)
                    char.opacity = 0

                    if (char.body) {
                        char.body.dispose()
                        char.body = null
                    }
                })
            })
        }).add([
            this.chars.map((chars, i) => {
                return gsap.timeline().add([
                    this.tween({}, { duration: i * 5, ease: `linear` })
                ]).add(() => {
                    chars.forEach((char, j) => {
                        const px = j * 0.2
                        const py = 0.025
                        const pz = 1.0 + 0.5 * Math.random()

                        char.body = this.addChar(char, px, py, pz)
                        char.body.linearVelocity.x = Polaris.util.rand(-100, 100) * 0.005
                        char.body.linearVelocity.z = Polaris.util.rand(80, 120) * -0.016
                        char.opacity = 1
                        this.scene.add(char.object)
                    })
                }).add([
                    this.tween({}, { duration: 4, ease: `linear` })
                ]).add([
                    chars.map((char) => {
                        return this.tween(char, { opacity: 0, duration: 1.5, ease: `power2.out` })
                    })
                ]).add(() => {
                    chars.forEach((char) => {
                        this.scene.remove(char.object)

                        if (char.body) {
                            char.body.dispose()
                            char.body = null
                        }
                    })
                })
            })
        ])
    }

    addChar(char, x, y, z) {
        return this.world.add({
            type: 'box',
            size: [
                char.geometry.boundingBox.max.x - char.geometry.boundingBox.min.x,
                char.geometry.boundingBox.max.y - char.geometry.boundingBox.min.y,
                char.geometry.boundingBox.max.z - char.geometry.boundingBox.min.z
            ],
            pos: [x, y, z],
            rot: [-90, 0, 0],
            move: true,
            friction: 0.0001,
            restitution: 0.01,
        })
    }

    render(deltaTime) {
        this.world.step(deltaTime)

        this.chars.forEach((chars) => {
            chars.forEach((char) => {
                if (char.body) {
                    char.object.position.copy(char.body.getPosition())
                    char.object.quaternion.copy(char.body.getQuaternion())
                }
            })
        })

        this.composer.render()
    }
}

export default Type21