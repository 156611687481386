import noise from '../../../shaders/noise.glsl'

export default `
uniform float seed;
uniform float opacity;
uniform float threshold;

varying vec2 vUv;

${noise}

float getNoise( vec2 uv ) {
	return fbm3( uv + seed );
}

void main() {
    
    float noise = (1.0 + getNoise(vUv * 30.0)) * 0.5;

    if (noise >= threshold) {
        gl_FragColor = vec4(vec3(1.0), opacity);
    } else if (noise >= threshold - 0.3) {
        gl_FragColor = vec4(vec3(noise), opacity * noise);
    } else {
        gl_FragColor = vec4(0.0);
    }
}

`