import React, { useContext, useState, useEffect } from 'react'

import { StatesContext } from 'src/hooks/states/context'

import Link from '../link/index'

import styles from './navpc.module.scss'
import sleeping from '../sleep/index.module.scss'


const NavPC = () => {

    const { sleep, playlistVisible } = useContext(StatesContext)

    const [ hidden, setHidden ] = useState(false)

    const classNames = [styles.navpc]

    if (sleep) {
        classNames.push(sleeping.sleeping)
    }

    // プレイリストを非表示にしたときに遅れて表示させる処理
    useEffect(() => {
        if (playlistVisible) {
            setHidden(true)
        } else {
            setTimeout(() => {
                setHidden(false)
            }, 2500)
        }
    }, [playlistVisible, setHidden])


    return (
        <div className={classNames.join(' ')} aria-hidden={hidden}>
            <ul>
                <li><Link href="/books/">Books</Link></li>
                <li><Link href="https://note.com/akashikurihara">Blog</Link></li>
            </ul>
        </div>
    )
}

export default NavPC
