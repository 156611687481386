import React, { useEffect, useContext } from 'react'

import { ColsContext } from 'src/hooks/cols/context'
import { StatesContext } from 'src/hooks/states/context'

import Title from './title'
import Button from './button'
import NavSP from './navsp'
import NavPC from './navpc'

import styles from './index.module.scss'


const Header = () => {
    const { cols } = useContext(ColsContext)

    const { finished, playlistVisible, navmenuVisible } = useContext(StatesContext)

    // 
    useEffect(() => {
        document.body.className = playlistVisible ? styles.listOpened : ''
    }, [playlistVisible])

    useEffect(() => {
        document.body.className = navmenuVisible ? styles.menuOpened : ''
    }, [navmenuVisible])


    return (
        <header className={styles.header} aria-hidden={!finished}>
            <Title />

            {cols > 6 ? (
                <NavPC />
            ) : (
                <React.Fragment>
                    <NavSP opened={navmenuVisible} />
                    <Button />
                </React.Fragment>
            )}
        </header>
    )
}

export default Header
