import * as THREE from 'three'

import { GPUComputationRenderer } from 'three/examples/jsm/misc/GPUComputationRenderer.js'
import { MeshSurfaceSampler } from 'three/examples/jsm/math/MeshSurfaceSampler.js';

import compute_position from './shaders/compute_position.glsl'
import compute_velocity from './shaders/compute_velocity.glsl'

import dotschar_vert from './shaders/dotschar_vert.glsl'
import dotschar_frag from './shaders/dotschar_frag.glsl'

import Char from '../char'


class DotsChar {

    SIZE = 40

    constructor(renderer, text, size, dpr) {

        this.gpgpu = new GPUComputationRenderer(this.SIZE, this.SIZE, renderer)

        this.char = new Char(text, { size: size, height: 0, line: false })

        this.sampler = new MeshSurfaceSampler(this.char.face)

        this.char.dispose()
        this.sampler.build()

        this.positionVariable = this.gpgpu.addVariable('texturePosition', compute_position, this.gpgpu.createTexture())
        this.velocityVariable = this.gpgpu.addVariable('textureVelocity', compute_velocity, this.gpgpu.createTexture())

        this.velocityVariable.material.uniforms['mode'] = { value: 0 }
        this.velocityVariable.material.uniforms['offset'] = { value: 0.0 }

        this.gpgpu.setVariableDependencies(this.positionVariable, [this.positionVariable, this.velocityVariable])
        this.gpgpu.setVariableDependencies(this.velocityVariable, [this.positionVariable, this.velocityVariable])


        this.points = new THREE.Points(new THREE.PlaneBufferGeometry(2, 2, this.SIZE - 1, this.SIZE - 1), new THREE.ShaderMaterial({
            uniforms: {
                devicePixelRatio: { value: dpr },
                positionTexture: { value: null },
                opacity: { value: 0.0 }
            },
            depthTest: false,
            depthWrite: false,
            transparent: true,
            vertexShader: dotschar_vert,
            fragmentShader: dotschar_frag
        }))
    }

    set visible(visilbe) {
        this.points.visible = visilbe
    }

    set opacity(opacity) {
        this.points.material.uniforms.opacity.value = opacity
    }

    get opacity() {
        return this.points.material.uniforms.opacity.value
    }

    set mode(value) {
        this.velocityVariable.material.uniforms.mode.value = value
    }

    set offset(value) {
        this.velocityVariable.material.uniforms.offset.value = value
    }

    sampling() {
        this.positionVariable.initialValueTexture = this.gpgpu.createTexture()
        this.velocityVariable.initialValueTexture = this.gpgpu.createTexture()

        const positionArray = this.positionVariable.initialValueTexture.image.data

        const _position = new THREE.Vector3()
        const _normal = new THREE.Vector3()

        for (let y = 0, k = 0; y < this.SIZE; y++) {
            for (let x = 0; x < this.SIZE; x++) {
                this.sampler.sample(_position, _normal)
                positionArray[k + 0] = _position.x
                positionArray[k + 1] = _position.y
                positionArray[k + 2] = _position.z
                positionArray[k + 3] = 1
                k += 4
            }
        }

        this.gpgpu.init()
    }

    update() {
        this.gpgpu.compute()
        this.points.material.uniforms.positionTexture.value = this.gpgpu.getCurrentRenderTarget(this.positionVariable).texture
    }
}

export default DotsChar