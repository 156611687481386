import React, { useRef, useContext, useEffect } from 'react'

import { useLocation } from "@reach/router"
import { StatesContext } from 'src/hooks/states/context'

import styles from './buttons.module.scss'
import sleeping from '../../sleep/index.module.scss'


const Buttons = ({ stage }) => {

    const available = useRef(false)

    const { pathname } = useLocation()

    const { finished, sleep, shuffled, setShuffled, playlistVisible } = useContext(StatesContext)

    const classNames = [styles.buttons]


    if (sleep) {
        classNames.push(sleeping.sleeping)
    }


    // キーボード操作を受け付けるか判定
    useEffect(() => {
        available.current = finished && !playlistVisible && RegExp(`^/(index.html)?$`).test(pathname)
    }, [finished, pathname, playlistVisible])

    
    // キーボードイベント登録
    useEffect(() => {
        if (stage) {
            
            document.body.addEventListener('keydown', (e) => {
                if (available.current) {
                    // UP or LEFT
                    if (e.keyCode === 33 || e.keyCode === 37 || e.keyCode === 38) e.preventDefault()

                    // DOWN or RIGHT
                    if (e.keyCode === 34 || e.keyCode === 39 || e.keyCode === 40) e.preventDefault()
                }
            })

            document.body.addEventListener('keyup', (e) => {
                if (available.current) {
                    // UP or LEFT
                    if (e.keyCode === 33 || e.keyCode === 37 || e.keyCode === 38) stage.prev()

                    // DOWN or RIGHT
                    if (e.keyCode === 34 || e.keyCode === 39 || e.keyCode === 40) stage.next()

                    // HOME
                    if (e.keyCode === 36) stage.head()

                    // END
                    if (e.keyCode === 35) stage.last()
                }
            })
        }
    }, [stage])

    return (
        <div className={classNames.join(' ')}>
            <button onClick={() => stage.prev()} className={styles.prev}><img src="/img/prev.svg" alt="前の短歌へ" /></button>
            <button onClick={() => stage.next()} className={styles.next}><img src="/img/next.svg" alt="次の短歌へ" /></button>
            <button onClick={() => setShuffled(!shuffled)} className={styles.shuffle} aria-pressed={shuffled}><img src="/img/shuffle.svg" alt="シャッフル" /></button>
        </div>
    )
}

export default Buttons