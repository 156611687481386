import React from 'react'

import { CursorContext } from './context'


const Provider = (props) => {

    const [hover, setHover] = React.useState(false)

    const [text, setText] = React.useState('')

    return (
        <CursorContext.Provider value={{ hover, setHover, text, setText }}>
            {props.children}
        </CursorContext.Provider>
    )
}

export default Provider
