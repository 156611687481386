import { Points, PlaneBufferGeometry, ShaderMaterial } from 'three'

import { GPUComputationRenderer } from 'three/examples/jsm/misc/GPUComputationRenderer.js'

import compute_mountain from './shaders/compute_mountain.glsl'
import mountain_vert from './shaders/mountain_vert.glsl'
import mountain_frag from './shaders/mountain_frag.glsl'


class Mountain {

    SIZE = 200

    START_TIME = Date.now()

    constructor(renderer, dpr) {

        this.gpgpu = new GPUComputationRenderer(this.SIZE, this.SIZE, renderer)

        this.potentialVariable = this.gpgpu.addVariable('texturePotential', compute_mountain, this.initTextures())
        this.potentialVariable.material.uniforms['time'] = { value: 0.0 }

        this.gpgpu.setVariableDependencies(this.potentialVariable, [this.potentialVariable])
        this.gpgpu.init()

        this.geometry = new PlaneBufferGeometry(2, 2, this.SIZE - 1, this.SIZE - 1)

        this.material = new ShaderMaterial({
            uniforms: {
                devicePixelRatio: { value: dpr },
                potentialTexture: { value: this.gpgpu.getCurrentRenderTarget(this.potentialVariable).texture }
            },
            depthTest: false,
            depthWrite: false,
            transparent: true,
            vertexShader: mountain_vert,
            fragmentShader: mountain_frag
        })

        this.particles = new Points(this.geometry, this.material)

        this.update()
    }

    initTextures() {
        const potentialTexture = this.gpgpu.createTexture()
        const potentialArray = potentialTexture.image.data
        const filedSize = 2500

        for (let y = 0, k = 0; y < this.SIZE; y++) {
            for (let x = 0; x < this.SIZE; x++) {
                potentialArray[k + 0] = (x - this.SIZE / 2) / this.SIZE * filedSize
                potentialArray[k + 1] = 0
                potentialArray[k + 2] = (y - this.SIZE / 2) / this.SIZE * filedSize
                potentialArray[k + 3] = 1
                k += 4
            }
        }

        return potentialTexture
    }
    
    update() {
        this.potentialVariable.material.uniforms.time.value = (Date.now() - this.START_TIME) * 0.00005
        this.gpgpu.compute()
    }
}

export default Mountain