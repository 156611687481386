import * as THREE from 'three'

import floor_vert from './shaders/floor_vert.glsl'
import floor_frag from './shaders/floor_frag.glsl'


class Floor {

    constructor() {

        this.geometry = new THREE.PlaneBufferGeometry(2, 2)

        this.material = new THREE.ShaderMaterial({
            uniforms: {
                intensity: { value: 0.0 }
            },
            transparent: true,
            vertexShader: floor_vert,
            fragmentShader: floor_frag
        })

        this.object = new THREE.Mesh(this.geometry, this.material)
        
        this.object.scale.x = 1
        this.object.scale.y = 1
        this.object.rotation.x = - Math.PI / 2
    }

    set intensity(value) {
        this.material.uniforms.intensity.value = value
    }

    get intensity() {
        return this.material.uniforms.intensity.value
    }
}

export default Floor