import * as THREE from 'three'

import Polaris from 'src/lib/Polaris'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js'

import BlurShaderH from '../../shaders/blur/BlurShaderH'
import BlurShaderV from '../../shaders/blur/BlurShaderV'

import Char from '../char'

import back_vert from './shaders/back_vert.glsl'
import back_frag from './shaders/back_frag.glsl'


class Typography {

    constructor(renderer, width, height) {
        this.camera = new THREE.PerspectiveCamera(45, 1, 1, 5)
        this.scene = new THREE.Scene()

        this.camera.aspect = width / height
        this.camera.position.set(0, 0, 5)
        this.camera.lookAt(0, 0, 0)
        this.camera.updateProjectionMatrix()

        this.offscreen = new THREE.WebGLRenderTarget(1, 1, {
            magFilter: THREE.LinearFilter,
            minFilter: THREE.LinearFilter
        })

        this.composer = new EffectComposer(renderer, this.offscreen)
        this.renderpass = new RenderPass(this.scene, this.camera)
        this.bloomPass = new UnrealBloomPass(new THREE.Vector2(512, 512), 0.2, 0.2, 0.9)
        this.fxaaPass = new ShaderPass(FXAAShader)

        this.blurPassH = new ShaderPass(BlurShaderH)
        this.blurPassV = new ShaderPass(BlurShaderV)

        this.blurPassH.uniforms.resolution.value.x = this.blurPassV.uniforms.resolution.value.x = width
        this.blurPassH.uniforms.resolution.value.y = this.blurPassV.uniforms.resolution.value.y = height
        this.blur = 25

        this.fxaaPass.uniforms.resolution.value.x = 1 / width
        this.fxaaPass.uniforms.resolution.value.y = 1 / height

        this.composer.setSize(width, height)
        this.composer.addPass(this.renderpass)
        // this.composer.addPass(this.bloomPass)
        this.composer.addPass(this.blurPassH)
        this.composer.addPass(this.blurPassV)
        this.composer.addPass(this.fxaaPass)
        this.composer.renderToScreen = false

        const text = [
            `つひに行く`,
            `道とはかねて`,
            `聞きしかど`,
            `昨日今日とは`,
            `思はざりしを`
        ]

        let count = 0

        text.forEach((line, i) => {
            const y = height * 0.8 * Polaris.util.rand(-100, -20) / 100

            line.split('').forEach((text, j) => {
                const char = new Char(text, { size: 0.080, height: 0, face: { color: 0xffffff, opacity: 1.0 }, line: false })
                char.x = 0.120 * (count - 15)
                char.y = 0.001 * y + j * 0.360
                char.object.rotation.set(Math.PI, 0, - Math.PI / 2)
                char.object.scale.x = 5
                this.scene.add(char.object)
                count++
            })
        })

        const back = new THREE.Mesh(new THREE.PlaneGeometry(2, 2), new THREE.ShaderMaterial({
            vertexShader: back_vert,
            fragmentShader: back_frag
        }))

        const _h = this.camera.position.z * Math.tan(this.camera.fov / 2 / 180 * Math.PI)
        back.scale.y = _h
        back.scale.x = _h / height * width

        this.scene.add(back)

        this.composer.render()

        this.object = new THREE.Mesh(new THREE.PlaneBufferGeometry(2, 2), new THREE.MeshBasicMaterial({ map: this.texture }))
    }

    get texture() {
        return this.composer.readBuffer.texture
    }

    set blur(radius) {
        this._blur = radius

        const weight = new Array(10);

        let t = 0.0;
        let d = radius * radius / 100;

        for (let i = 0; i < 10; i++) {
            var r = 1.0 + 2.0 * i;
            var w = Math.exp(-0.5 * (r * r) / d);
            weight[i] = w;
            if (i > 0) { w *= 2.0; }
            t += w;
        }
        for (let i = 0; i < 10; i++) {
            weight[i] /= t;
        }

        this.blurPassH.uniforms.radius.value = radius
        this.blurPassV.uniforms.radius.value = radius

        this.blurPassH.uniforms.weight.value = weight
        this.blurPassV.uniforms.weight.value = weight

        this.needsUpdate = true
    }
}

export default Typography