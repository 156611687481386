export default `

uniform vec2 center;

void main() {
    vec2 cellSize = 1.0 / resolution.xy;

    vec2 uv = gl_FragCoord.xy / resolution.xy;

    // z成分に現在の値を格納
    // w成分に前回の値を格納
    vec4 height = texture2D( textureWater, uv );

    vec4 n = texture2D( textureWater, uv + vec2( 0.0, + cellSize.y ) );
	vec4 s = texture2D( textureWater, uv + vec2( 0.0, - cellSize.y ) );
	vec4 e = texture2D( textureWater, uv + vec2( + cellSize.x, 0.0 ) );
    vec4 w = texture2D( textureWater, uv + vec2( - cellSize.x, 0.0 ) );
    
    float newHeight = ( ( n.z + s.z + e.z + w.z ) * 0.5 - height.w ) * 0.995;


    if (center.x != 0.0) {
        float len = length(uv - center);

        if (len < 0.02) {
            newHeight += 50.0 * (0.02 - len);
        }
    }

    height.w = height.z;
	height.z = newHeight;

	gl_FragColor = height;
}
`