import React, { useContext } from 'react'

import Link from '../../link/index'

import { StatesContext } from 'src/hooks/states/context'

import styles from './closer.module.scss'


const Closer = () => {

    const { playlistVisible, setPlaylistVisibility } = useContext(StatesContext)

    return (
        <Link onClick={() => setPlaylistVisibility(false)} className={styles.closer} aria-hidden={!playlistVisible}>Close</Link>
    )
}

export default Closer