import React from 'react'

import { StatesContext } from './context'


const Provider = (props) => {

    // ロード完了フラグ
    const [loaded, setLoaded] = React.useState(false)

    // オープニング完了フラグ
    const [finished, setFinished] = React.useState(false)

    // スリープ中フラグ
    const [sleep, setSleep] = React.useState(false)

    // シャッフル中フラグ
    const [shuffled, setShuffled] = React.useState(false)

    // 短歌テキスト
    const [currentText, setCurrentText] = React.useState('')


    // プレイリストの表示制御
    const [playlistVisible, setPlaylistVisibility] = React.useState(false)

    // SPナビゲーションメニューの表示制御
    const [navmenuVisible, setNavmenuVisibility] = React.useState(false)



    return (
        <StatesContext.Provider value={{
            loaded,
            setLoaded,
            finished,
            setFinished,
            sleep,
            setSleep,
            shuffled,
            setShuffled,
            currentText,
            setCurrentText,
            playlistVisible,
            setPlaylistVisibility,
            navmenuVisible,
            setNavmenuVisibility
        }}>
            {props.children}
        </StatesContext.Provider>
    )
}

export default Provider
