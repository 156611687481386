import * as THREE from 'three'

import gsap from 'gsap'

import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'

import AbstractAnimation from '../abstract'

import Char from '../char'


class Loader extends AbstractAnimation {

    constructor(renderer) {
        super(renderer, { far: 3 })
        
        this.renderPass = new RenderPass(this.scene, this.camera)

        this.composer.addPass(this.renderPass)

        this.object = new THREE.Mesh(new THREE.PlaneBufferGeometry(2, 2), new THREE.MeshBasicMaterial({
            opacity: 0,
            transparent: true,
            map: this.composer.readBuffer.texture
        }))
    }

    resize(width, height, cameraZ, cameraAspect) {
        super.resize(width, height)

        if (width > height) {
            this.camera.position.set(0.64, 0.8, 0.8)
        } else {
            this.camera.position.set(0.96, 1.2, 1.0)
        }

        this.camera.lookAt(0, 0, 0)

        const _h = (cameraZ - this.object.position.z) * cameraAspect
        this.object.scale.y = _h
        this.object.scale.x = _h / height * width
    }

    progress(rate) {
        if (this.nums) {
            this.nums.forEach((char) => {
                this.scene.remove(char.object)
                char.dispose()
            })
        }

        const texts = '' + parseInt(rate * 100)

        this.nums = Array.from(texts).map((num, i) => {
            const char = new Char(num, { size: 0.3, height: 0.04, face: false, line: { color: 0x3c3c3c } })
            char.object.position.x = 0.22 * (i - (texts.length - 1) / 2)
            char.object.rotation.x = - Math.PI / 2
            this.scene.add(char.object)
            return char
        })
    }

    fadeIn() {
        return gsap.timeline().add([
            this.tween(this.object.material, { opacity: 1, duration: 0.5, ease: `power2.in` })
        ])
    }

    fadeOut() {
        return gsap.timeline().add([
            this.tween(this.object.material, { opacity: 0, duration: 0.5, ease: `power2.in` })
        ])
    }
}

export default Loader