import gsap from 'gsap'

import AbstractAnimation from '../abstract'

import Typography from './typography'

import Back from './back'

import Drop from './drop'

import Raytrace from './raytrace'


class Type02 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer)

        this.camera.position.set(0, 0, 2.5)
        this.camera.lookAt(0, 0, 0)
        this.camera.far = 3

        this.composer.addPass(this.renderPass)


        // 短歌を描画したオフスクリーン
        this.typography = new Typography(renderer)

        // 背景
        this.back = new Back(renderer, this.typography.texture)
        this.scene.add(this.back.object)

        // 落下滴
        const args = [
            [0.1, +1.00, 1.5, 0.5],
            [0.2, +0.40, 1.2, 0.5],
            [0.1, -0.30, 1.2, 0.5],
            [0.2, -0.80, 1.8, 0.5],
            [0.3, +0.40, 1.2, 1.0],
            [0.1, -0.20, 1.4, 1.0],
            [0.2, -0.80, 1.2, 1.0],
            [0.2, -0.15, 1.5, 1.5]
        ]

        this.drops = args.map((param) => {
            return new Drop(
                param[0],
                param[1] + 0.01 * Math.random(),
                param[2] + 0.01 * Math.random(),
                param[3] + 0.01 * Math.random()
            )
        })

        // レイトレーシング
        this.raytrace = new Raytrace(this.drops, this.typography.texture)
        this.raytrace.object.position.z = 0.1
        this.scene.add(this.raytrace.object)

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    initDrops() {
        this.back.blur = 1
        this.back.scale = 1

        this.drops.forEach((drop, i) => {
            drop.y = drop.iniY
            this.raytrace.material.uniforms.drops.value[i].x = drop.x
            this.raytrace.material.uniforms.drops.value[i].y = drop.y
            this.raytrace.material.uniforms.drops.value[i].z = drop.z
        })
    }

    createMainTimeline() {
        return gsap.timeline({ paused: true, onUpdate: this.onUpdateMain.bind(this) }).add(() => {
            this.initDrops()
        }).add([
            // 背景ぼかしアニメーション
            this.tween(this.back, { blur: 100, scale: 0.95, duration: 3, ease: `power2.inOut` }),

            // 水滴落下アニメーション
            this.drops.map((drop) => {
                return this.tween(drop, { y: drop.iniY - 3, delay: 2.0, duration: 1, ease: `linear` })
            })
        ])
    }

    createDemoTimeline() {
        return gsap.timeline({ paused: true, onUpdate: this.onUpdateDemo.bind(this), repeat: -1 }).add(() => {
            this.initDrops()
        }).add([
            this.drops.map((drop) => {
                return this.tween(drop, { y: drop.iniY - 3, duration: 6, ease: `linear` })
            })
        ])
    }

    createOpenTimeline() {
        return gsap.timeline({ paused: true, onUpdate: this.onUpdateDemo.bind(this) }).add(() => {
            this.initDrops()
        }).add([
            this.drops.map((drop) => {
                return this.tween(drop, { y: drop.iniY - 3, duration: 6, ease: `linear` })
            })
        ])
    }

    updatePosition() {
        this.drops.forEach((drop, i) => {
            this.raytrace.material.uniforms.drops.value[i].x = drop.x
            this.raytrace.material.uniforms.drops.value[i].y = drop.y
            this.raytrace.material.uniforms.drops.value[i].z = drop.z
        })
    }

    onUpdateMain() {
        this.updatePosition()

        const isSlow = this.drops.reduce((slow, drop) => {
            return slow || (-0.3 < drop.y && drop.y < 0.3)
        }, false)

        if (isSlow) {
            this.mainTimeline.timeScale(0.05)
        } else {
            this.mainTimeline.timeScale(1.0)
        }
    }

    onUpdateDemo() {
        this.updatePosition()
    }

    resize(width, height) {
        super.resize(width, height)

        this.typography.update(width, height, this.camera)
        this.back.update(width, height, this.camera)
        this.raytrace.update(width, height, this.camera)
    }

    render() {
        this.back.render()
        super.render()
    }
}

export default Type02