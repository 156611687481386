
export default [
    {
        "id": "01",
        "source": "01. 『伊勢物語』 Ⅰ・若き日々",
        "text": "月やあらぬ春や昔の春ならぬわが身一つはもとの身にして"
    },
    {
        "id": "02",
        "source": "02. 『伊勢物語』 Ⅰ・若き日々",
        "text": "白玉かなにぞと人の問ひし時露とこたへて消えなましものを"
    },
    {
        "id": "03",
        "source": "03. 『伊勢物語』 Ⅱ・放浪",
        "text": "からころも着つつなれにしつましあればはるばる来ぬる旅をしぞ思ふ"
    },
    {
        "id": "04",
        "source": "04. 『伊勢物語』 Ⅱ・放浪",
        "text": "名にし負はばいざこと問はむ都鳥わが思ふ人はありやなしやと"
    },
    {
        "id": "05",
        "source": "05. 『伊勢物語』 Ⅱ・放浪",
        "text": "栗原のあねはの松の人ならば都のつとにいざといはましを"
    },
    {
        "id": "06",
        "source": "06. 『伊勢物語』 Ⅲ・青春が終わって",
        "text": "あだなりと名にこそ立てれ櫻花年にまれなる人も待ちけり"
    },
    {
        "id": "07",
        "source": "07. 『伊勢物語』 Ⅲ・青春が終わって",
        "text": "天雲のよそにのみして経ることはわがゐる山の風はやみなり"
    },
    {
        "id": "08",
        "source": "08. 『伊勢物語』 Ⅲ・青春が終わって",
        "text": "風吹けば沖つ白浪龍田山夜半にや君がひとり越ゆらむ"
    },
    {
        "id": "09",
        "source": "09. 『伊勢物語』 Ⅲ・青春が終わって",
        "text": "あづさ弓ま弓つき弓年を経てわがせしがごとうるはしみせよ"
    },
    {
        "id": "10",
        "source": "10. 『伊勢物語』 Ⅲ・青春が終わって",
        "text": "みるめなきわが身を浦と知らねばや離れなで海人の足たゆく来る"
    },
    {
        "id": "11",
        "source": "11. 『伊勢物語』 Ⅲ・青春が終わって",
        "text": "おもほえず袖にみなとのさわぐかなもろこし船の寄りしばかりに"
    },
    {
        "id": "12",
        "source": "12. 『伊勢物語』 Ⅲ・青春が終わって",
        "text": "いにしへのしずのおだまきくりかえしむかしを今になすよしもがな"
    },
    {
        "id": "13",
        "source": "13. 『伊勢物語』 Ⅲ・青春が終わって",
        "text": "われならで下紐解くなあさがほの夕影待たぬ花にはありとも"
    },
    {
        "id": "14",
        "source": "14. 『伊勢物語』 Ⅲ・青春が終わって",
        "text": "葎生ひて荒れたる宿のうれたきはかりにも鬼のすだくなりけり"
    },
    {
        "id": "15",
        "source": "15. 『伊勢物語』 Ⅲ・青春が終わって",
        "text": "五月待つ花橘の香をかげばむかしの人の袖の香ぞする"
    },
    {
        "id": "16",
        "source": "16. 『伊勢物語』 Ⅳ・神への挑戦",
        "text": "君や来しわれや行きけむおもほえず夢かうつつか寝てかさめてか"
    },
    {
        "id": "17",
        "source": "17. 『伊勢物語』 Ⅴ・老年",
        "text": "大原や小塩の山も今日こそは神代のことも思ひいずらめ"
    },
    {
        "id": "18",
        "source": "18. 『伊勢物語』 Ⅴ・老年",
        "text": "忘れては夢かとぞ思ふ思ひきや雪踏みわけて君を見むとは"
    },
    {
        "id": "19",
        "source": "19. 『伊勢物語』 Ⅴ・老年",
        "text": "世をうみのあまとし人を見るからにめくはせよとも頼まるるかな"
    },
    {
        "id": "20",
        "source": "20. 『伊勢物語』 Ⅴ・老年",
        "text": "ちはやぶる神代も聞かず龍田河からくれないに水くくるとは"
    },
    {
        "id": "21",
        "source": "21. 『伊勢物語』 Ⅴ・老年",
        "text": "浪間より見ゆる小島のはまびさし久しくなりぬ君にあひ見で"
    },
    {
        "id": "22",
        "source": "22. 『伊勢物語』 Ⅴ・老年",
        "text": "むつましと君はしらなみみづがきの久しき世よりいはひそめてき"
    },
    {
        "id": "23",
        "source": "23. 『伊勢物語』 Ⅵ・死",
        "text": "思ふこと言はでぞただにやみぬべきわれとひとしき人しなければ"
    },
    {
        "id": "24",
        "source": "24. 『伊勢物語』 Ⅵ・死",
        "text": "つひに行く道とはかねて聞きしかど昨日今日とは思はざりしを"
    }
]