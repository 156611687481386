import noise from '../../../shaders/noise.glsl'

export default `
varying vec2 vUv;

${noise}

void main() {
    float color = 0.015 * rand(vUv);
    gl_FragColor = vec4(color, color, color, 1.0);
}

`