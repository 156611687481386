
class Drop {
    constructor(radius, x, y, z) {
        this.r = radius
        this.x = x
        this.y = y
        this.z = z

        this.iniX = x
        this.iniY = y
        this.iniZ = z
    }
}

export default Drop