
import noise from '../noise.glsl'

export default `

uniform float seed;

${noise}

float getNoise( vec2 uv ) {
	return fbm3( vec2( fbm3( uv + seed ) ) );
}

void main() {
    vec2 uv = gl_FragCoord.xy / resolution.xy;
    gl_FragColor = vec4( getNoise( uv ) );
}

`