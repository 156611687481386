export default `

uniform float opacity;

void main() {
    vec2 center = 2.0 * gl_PointCoord - 1.0;

    float r = length(center);

    float alpha = 1.0 - r;

    gl_FragColor = vec4( 1.0, 1.0, 1.0, opacity * pow( alpha + 0.1, 5.0 ) );
}
`