import v_shader from './vert_shader.glsl'
import f_shader from './frag_shader.glsl'

const CustomShader = {
    uniforms: {
        tDiffuse: { value: null },
        noiseMap: { value: null },
        // randomMap: { value: null },
        time0: { value: 0.0 },
        time1: { value: 0.0 },
        time2: { value: 0.0 }
    },
    vertexShader: v_shader,
    fragmentShader: f_shader
}

export default CustomShader