import { Vector2 } from 'three'

import gsap from 'gsap'

import Polaris from 'src/lib/Polaris'

import AbstractAnimation from '../abstract'

import { GPUComputationRenderer } from 'three/examples/jsm/misc/GPUComputationRenderer.js'

import Typography from './typography'

import Water from './water'

import compute_water from './shaders/compute_water.glsl'
import compute_smooth from './shaders/compute_smooth.glsl'


class Type04 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer)

        this.SIZE = 512

        this.camera.position.set(0, 0, 2)
        this.camera.lookAt(0, 0, 0)
        this.camera.far = 2.5

        this.composer.addPass(this.renderPass)

        this.typography = new Typography(renderer)

        this.water = new Water(this.typography.texture)
        this.scene.add(this.water.object)


        this.gpuCompute = new GPUComputationRenderer(this.SIZE, this.SIZE, renderer)

        const { waterTexture, smoothTexture } = this.initTextures()

        this.waterVariable = this.gpuCompute.addVariable('textureWater', compute_water, waterTexture)
        this.smoothVariable = this.gpuCompute.addVariable('textureSmooth', compute_smooth, smoothTexture)

        this.waterVariable.material.uniforms['center'] = { value: new Vector2(0, 0) }

        this.gpuCompute.setVariableDependencies(this.waterVariable, [this.waterVariable])
        this.gpuCompute.setVariableDependencies(this.smoothVariable, [this.waterVariable, this.smoothVariable])
        this.gpuCompute.init()

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createMainTimeline() {
        return gsap.timeline({ paused: true }).add([
            // 文字移動アニメーション1
            this.tween(this.water.material.uniforms.deltaY, { value: 0.5 }, { value: -0.5, duration: 30, ease: `linear` }),

            // 文字移動アニメーション2
            this.typography.chars.map((char) => {
                return this.tween(char, { x: char.x + Polaris.util.rand(-30, 30) / 1000, duration: 30, ease: `ease.inOut` })
            }),

            // 波紋アニメーション
            gsap.timeline().repeat(4).add(() => {
                this.birdX = Polaris.util.rand(20, 80) / 100 * (this.canvasW > this.canvasH ? 1 : this.canvasW / this.canvasH)
                this.birdY = Polaris.util.rand(10, 90) / 100 * (this.canvasW > this.canvasH ? this.canvasH / this.canvasW : 1)
            }).add(() => {
                this.waterVariable.material.uniforms.center.value.x = this.birdX
                this.waterVariable.material.uniforms.center.value.y = this.birdY
            }).add([
                this.tween({}, { delay: 0.3 })
            ]).add(() => {
                this.waterVariable.material.uniforms.center.value.x = this.birdX + Polaris.util.rand(-10, 10) / 100
                this.waterVariable.material.uniforms.center.value.y = this.birdY + Polaris.util.rand(-10, 10) / 100
            }).add([
                this.tween({}, { delay: 5.0 })
            ])
        ])
    }

    createDemoTimeline() {
        return gsap.timeline({ paused: true, repeat: -1 }).add(() => {
            this.water.material.uniforms.deltaY.value = 0
        }).add([
            // 波紋アニメーション
            gsap.timeline().add(() => {
                this.birdX = Polaris.util.rand(20, 80) / 100 * (this.canvasW > this.canvasH ? 1 : this.canvasW / this.canvasH)
                this.birdY = Polaris.util.rand(10, 90) / 100 * (this.canvasW > this.canvasH ? this.canvasH / this.canvasW : 1)
            }).add(() => {
                this.waterVariable.material.uniforms.center.value.x = this.birdX
                this.waterVariable.material.uniforms.center.value.y = this.birdY
            }).add([
                this.tween({}, { delay: 0.3 })
            ]).add(() => {
                this.waterVariable.material.uniforms.center.value.x = this.birdX + Polaris.util.rand(-10, 10) / 100
                this.waterVariable.material.uniforms.center.value.y = this.birdY + Polaris.util.rand(-10, 10) / 100
            }).add([
                this.tween({}, { delay: 5.0 })
            ])
        ])
    }

    createOpenTimeline() {
        return this.createDemoTimeline()
    }

    initTextures() {
        const waterTexture = this.gpuCompute.createTexture()
        const smoothTexture = this.gpuCompute.createTexture()
        const waterArray = waterTexture.image.data
        const filedSize = 500

        for (let y = 0, k = 0; y < this.SIZE; y++) {
            for (let x = 0; x < this.SIZE; x++) {
                waterArray[k + 0] = x / this.SIZE * filedSize
                waterArray[k + 1] = y / this.SIZE * filedSize
                waterArray[k + 2] = 0.01 * Math.random()
                waterArray[k + 3] = 0
                k += 4
            }
        }

        return { waterTexture, smoothTexture }
    }

    resize(width, height) {
        super.resize(width, height)

        this.typography.resize(width, height)
        this.water.material.uniforms.typoTexture.value = this.typography.texture
        this.water.fit(width, height, this.camera)
    }

    render() {
        // 文字組の再レンダリング
        this.typography.update()

        // GPGPUで水面計算
        this.gpuCompute.compute()
        this.water.material.uniforms.heightmap.value = this.gpuCompute.getCurrentRenderTarget(this.smoothVariable).texture

        // レンダリング実行
        this.composer.render()

        // 波紋リセット
        this.waterVariable.material.uniforms.center.value.x = -1
        this.waterVariable.material.uniforms.center.value.y = -1
    }
}

export default Type04