import * as THREE from 'three'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'

import Char from '../char'


class Screen {

    constructor(renderer) {
        this.camera = new THREE.PerspectiveCamera(45, 1, 0.01, 3)
        this.scene = new THREE.Scene()

        this.camera.position.set(0, 0, 2.5)
        this.camera.lookAt(0, 0, 0)

        this.offscreen = new THREE.WebGLRenderTarget(1, 1, {
            magFilter: THREE.LinearFilter,
            minFilter: THREE.LinearFilter
        })

        this.composer = new EffectComposer(renderer, this.offscreen)
        this.renderpass = new RenderPass(this.scene, this.camera)
        this.composer.addPass(this.renderpass)
        this.composer.renderToScreen = false

        const text = [
            `白玉か`,
            `なにぞと人の`,
            `問ひし時`,
            `露とこたへて`,
            `消えなましものを`,
        ]

        text.forEach((line, i) => {
            line.split('').forEach((text, j) => {
                const char = new Char(text, { size: 0.055, height: 0, face: { color: 0xffffff, opacity: 1.0 }, line: { color: 0xffffff, opacity: 0.5 } })
                char.x = 0.175 * (2 - i)
                char.y = 0.100 * (3 - j)
                this.scene.add(char.object)
            })
        })

        // 黒背景
        this.black = new THREE.Mesh(new THREE.PlaneGeometry(2, 2), new THREE.MeshBasicMaterial({
            color: 0x000000,
            depthTest: false,
            depthWrite: false,
        }))

        this.scene.add(this.black)
    }

    get texture() {
        return this.composer.readBuffer.texture
    }

    update(width, height) {
        this.black.scale.x = width * 2
        this.black.scale.y = height * 2
        
        this.camera.aspect = width / height
        this.camera.updateProjectionMatrix()
        this.composer.setSize(width, height)
        this.composer.render()
    }
}

export default Screen