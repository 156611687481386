import React, { useContext } from 'react'

import { useLocation } from "@reach/router"
import { StatesContext } from 'src/hooks/states/context'

import Link from '../link/index'

import styles from './title.module.scss'
import sleeping from '../sleep/index.module.scss'


const Title = () => {

    const { pathname } = useLocation()

    const { sleep, playlistVisible, navmenuVisible } = useContext(StatesContext)

    const classNames = [styles.title]

    if (sleep && !playlistVisible) {
        classNames.push(sleeping.sleeping)
    }

    if (navmenuVisible) {
        classNames.push(styles.white)
    }

    return (
        <Link href="/" className={classNames.join(' ')}>
            {pathname.match(/^\/(index\.html)?$/) ? (
                <h1>Akashi Kurihara</h1>
            ) : (
                <div>Akashi Kurihara</div>
            )}
        </Link>
    )
}

export default Title
