import gsap from 'gsap'

import { World } from 'oimo'

import Polaris from 'src/lib/Polaris'

import AbstractAnimation from '../abstract'

import Char from '../char'


class Type20 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer, { playlist_size: 1.0 })

        this.composer.addPass(this.renderPass)

        const text = `ちはやぶる神代も聞かず龍田河からくれないに水くくるとは`

        this.chars = Array.from(text).map((text) => {
            return new Char(text, { size: 100, height: 2, face: { color: 0xffffff }, line: false })
        })

        this.world = new World({
            broadphase: 3,
            worldscale: 1,
            gravity: [100, -100, 0]
        })

        this.ground = this.world.add({
            type: 'box',
            size: [10000, 100, 10000],
            pos: [0, -50, 0],
            friction: 1.0
        })

        this.wall1 = this.world.add({
            type: 'box',
            size: [10000, 100, 100],
            pos: [0, 0, 850],
            restitution: 0.2
        })

        this.wall2 = this.world.add({
            type: 'box',
            size: [10000, 100, 100],
            pos: [0, 0, -850],
            restitution: 0.2
        })

        for (let i = 0; i < 10; i++) {
            this.world.add({
                type: 'cylinder',
                size: [30, 100, 30],
                pos: [Polaris.util.rand(-1500, 500), 0, Polaris.util.rand(-500, 500)],
                restitution: 1.0
            })
        }

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createMainTimeline() {
        return gsap.timeline({ paused: true }).add(() => {
            // 文字を消去
            this.chars.forEach((char) => {
                this.scene.remove(char.object)
                char.opacity = 0

                if (char.body) {
                    char.body.dispose()
                    char.body = null
                }
            })
        }).add([
            this.tween({}, { duration: 40, ease: `linear` }),

            this.chars.map((char, i) => {
                return gsap.timeline().add([
                    gsap.delayedCall(i * 1.0, () => {
                        const px = this.canvasW > this.canvasH ? -2500 : -1500
                        const pz = Polaris.util.rand(-750, 750)
                        const rx = -90

                        char.body = this.world.add({
                            type: 'box',
                            size: [
                                char.geometry.boundingBox.max.x - char.geometry.boundingBox.min.x,
                                char.geometry.boundingBox.max.y - char.geometry.boundingBox.min.y,
                                char.geometry.boundingBox.max.z - char.geometry.boundingBox.min.z
                            ],
                            pos: [px, 1, pz],
                            rot: [rx, 0, 0],
                            move: true,
                            friction: 0.0,
                            restitution: 0.2,
                        })

                        char.body.linearVelocity.x = 100.0

                        this.scene.add(char.object)
                    })
                ]).add([
                    this.tween(char, { opacity: 1, duration: 2, ease: 'power2.in' })
                ])
            })
        ])
    }

    createDemoTimeline() {
        return this.createMainTimeline().repeat(-1)
    }

    createOpenTimeline() {
        return gsap.timeline({ paused: true }).add(() => {
            this.chars.forEach((char, i) => {
                char.x = 1500 - i * 150
                char.z = Polaris.util.rand(-500, 500)
                char.object.rotation.x = -Math.PI / 2
                this.scene.add(char.object)
            })

        }).add([
            this.tween({}, { duration: 10, ease: `linear` })
        ])
    }

    resize(width, height) {
        super.resize(width, height)

        if (width > height) {
            this.camera.position.set(1000, 1000, 1000)
        } else {
            this.camera.position.set(2000, 2000, 2000)
        }

        this.camera.lookAt(0, 0, 0)
    }

    render(deltaTime) {
        this.world.step(deltaTime)

        this.chars.forEach((char) => {
            if (char.body) {
                char.object.position.copy(char.body.getPosition())
                char.object.quaternion.copy(char.body.getQuaternion())
                
                if (char.body.linearVelocity.x > 150) {
                    char.body.linearVelocity.x = 150
                }
            }
        })

        this.composer.render()
    }
}

export default Type20