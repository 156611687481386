import React, { useContext } from 'react'

import { StatesContext } from 'src/hooks/states/context'

import Link from '../../link/index'

import styles from './opener.module.scss'
import sleeping from '../../sleep/index.module.scss'


const Opener = () => {

    const { sleep, setPlaylistVisibility } = useContext(StatesContext)

    const classNames = [styles.opener]

    if (sleep) {
        classNames.push(sleeping.sleeping)
    }

    return (
        <div className={classNames.join(' ')}>
            <Link onClick={() => setPlaylistVisibility(true)} className={styles.open}>Playlist</Link>
        </div>
    )
}

export default Opener