
export default `
uniform float devicePixelRatio;
uniform sampler2D positionTexture;

void main() {

    vec4 pos = texture2D( positionTexture, uv );

    vec4 mvPosition = modelViewMatrix * vec4( pos.x, pos.y, pos.z, 1.0 );

    gl_PointSize = devicePixelRatio * 1.0;

    gl_Position = projectionMatrix * mvPosition;
}
`