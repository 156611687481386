import Polaris from 'src/lib/Polaris'

class Drag {

    start = null
    delta = null
    moved = false

    constructor(el) {
        this.el = el

        this.el.addEventListener('MozMousePixelScroll', (e) => {
            this.wheel(-3 * e.detail)
        })

        this.el.addEventListener('mousewheel', (e) => {
            this.wheel(e.wheelDelta)
        })

        this.el.addEventListener('mousedown', (e) => {
            this.mousedown(e)
        })

        this.el.addEventListener('mousemove', (e) => {
            this.mousemove(e)
        })

        this.el.addEventListener('mouseup', () => {
            this.mouseup()
        })

        this.el.addEventListener('mouseout', () => {
            this.mouseup()
        })
    }

    get dragging() {
        return this.moved
    }

    cancel() {
        Polaris.util.offFrame(this.frameID)
    }

    resize(w, h) {
        this.landscape = w > h

        if (this.landscape) {
            this.maxScroll = this.el.firstElementChild.clientWidth - window.innerWidth
        } else {
            this.maxScroll = this.el.firstElementChild.clientHeight - window.innerHeight
        }
    }

    wheel(delta) {
        this.cancel()

        const prop = this.landscape ? `scrollLeft` : `scrollTop`

        if (this.scrollTo === undefined) this.scrollTo = this.el[prop]

        this.scrollTo = Polaris.util.clamp(this.scrollTo - delta, 0, this.maxScroll)

        this.frameID = Polaris.util.onFrame((ct, dt) => {
            this.el[prop] += (this.scrollTo - this.el[prop]) * Math.min(dt, 50) * 0.01

            if (Math.abs(this.el[prop] - this.scrollTo) < 1) {
                this.el[prop] = this.scrollTo
                this.scrollTo = undefined
                return false
            }
        })
    }

    mousedown(e) {
        this.cancel()
        this.scrollTo = undefined
        this.start = this.landscape ? e.clientX : e.clientY
        this.moved = false
    }

    mousemove(e) {
        if (this.start !== null) {
            const t = this.landscape ? e.clientX : e.clientY
            this.delta = t - this.start
            this.start = t
            this.moved = true

            if (this.landscape) {
                this.el.scrollLeft -= this.delta
            } else {
                this.el.scrollTop -= this.delta
            }
        }
    }

    mouseup() {
        this.cancel()

        this.frameID = Polaris.util.onFrame(() => {
            this.delta *= 0.92

            if (this.landscape) {
                this.el.scrollLeft -= this.delta
            } else {
                this.el.scrollTop -= this.delta
            }
            if (Math.abs(this.delta) < 0.5) return false
        })

        this.start = null
    }
}

export default Drag