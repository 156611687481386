import React, { useState, useRef, useContext, useEffect } from 'react'

import $ from 'jquery'

import { StatesContext } from 'src/hooks/states/context'
import { ScrollContext } from './hooks/context'

import Polaris from 'src/lib/Polaris'

import styles from './index.module.scss'


const Scrollin = (props) => {

    const ref = useRef()

    const [ visible, setVisible ] = useState(null)

    const { finished } = useContext(StatesContext)

    const TagName = 'tagName' in props ? props.tagName : 'div'

    const tagProps = Object.assign({}, props)


    if ('tagName' in tagProps) {
        delete tagProps['tagName']
    }
    if ('rate' in tagProps) {
        delete tagProps['rate']
    }

    useEffect(() => {
        if (finished) {
            let $el = $(ref.current)
            let height
            let offset

            const visibleRate = props.rate ? props.rate : 0.5

            const kill = () => {
                Polaris.util.offResize(resizeID)
                Polaris.util.offScroll(scrollID)
            }

            const resizeID = Polaris.util.onResize((t, b) => {
                height = $el.height()
                offset = $el.offset().top
            })

            const scrollID = Polaris.util.onScroll((t, b) => {
                const rate = Polaris.util.visibleAreaRate(offset, height, t, b)

                if (rate > visibleRate) {
                    setVisible(true)
                    setTimeout(kill)
                }
            })

            return kill
        }
    }, [finished, props.rate])


    return (
        <ScrollContext.Provider value={{ visible, setVisible }}>
            <TagName ref={ref} {...tagProps}>
                {props.children}
            </TagName>
        </ScrollContext.Provider>
    )
}


const ScrollItem = (props) => {

    const ref = useRef()

    const { visible } = useContext(ScrollContext)

    const TagName = 'tagName' in props ? props.tagName : 'div'

    const tagProps = Object.assign({}, props)

    if ('tagName' in tagProps) {
        delete tagProps['tagName']
    }

    if ('delay' in tagProps) {
        delete tagProps['delay']
    }

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                if (ref.current) {
                    if (props.className) {
                        ref.current.className = props.className + ' ' + styles.enter
                    } else {
                        ref.current.className = styles.enter
                    }
                }
            }, props.delay);
        }
    }, [visible, props.delay, props.className])

    return (
        <TagName ref={ref} {...tagProps}>
            {props.children}
        </TagName>
    )
}

export { Scrollin, ScrollItem }