export default `
uniform float devicePixelRatio;
uniform sampler2D potentialTexture;
// uniform sampler2D positionTexture;
// uniform sampler2D velocityTexture;

void main() {
    vec4 pos = texture2D( potentialTexture, uv );

    vec4 mvPosition = modelViewMatrix * vec4( pos.xyz, 1.0 );

    gl_PointSize = devicePixelRatio * clamp(pos.z / 200.0, 2.0, 5.0);

    gl_Position = projectionMatrix * mvPosition;
}
`