import v_shader from './passthrough_vert.glsl'
import f_shader from './passthrough_frag.glsl'

const PassthroughShader = {
    uniforms: {
        tDiffuse: { value: null }
    },
    vertexShader: v_shader,
    fragmentShader: f_shader
}

export default PassthroughShader