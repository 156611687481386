import gsap from 'gsap'

import Polaris from 'src/lib/Polaris'

import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

import Passthrough from '../../shaders/passthrough/index'

import AbstractAnimation from '../abstract'

import { AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass.js'

import GpuScreen from './gpuscreen'


class Type07 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer, { playlist_size: 1.0 })

        const size = Math.min(2048, Math.max(window.screen.availWidth, window.screen.availHeight) * Polaris.device.pixelRatio)

        this.camera.position.set(0, 0, 1)
        this.camera.lookAt(-0.5, 0.3, 0.0)
        this.camera.far = 3.5

        this.afterimgPass = new AfterimagePass(0.97)
        this.throughPass = new ShaderPass(Passthrough)

        this.composer.addPass(this.renderPass)
        this.composer.addPass(this.afterimgPass)
        this.composer.addPass(this.throughPass)

        this.screen = new GpuScreen(renderer, size)
        this.screen.object.scale.set(1.5, 1.5, 1.0)
        this.screen.object.rotation.set(Math.PI / 2, 0, 0)
        this.scene.add(this.screen.object)

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createMainTimeline() {
        return gsap.timeline({ paused: true, onUpdate: () => this.screen.update() }).add(() => {
            this.afterimgPass.uniforms.damp.value = 0 // 残像リセット
            this.screen.object.position.set(-1.5, 0.5, -0.5)
        }).add([
            this.tween(this.screen, { time: 0 }, { time: 1, duration: 45, ease: `linear` }),
            this.tween(this.screen.object.position, { x: -1.5 }, { x: 0.75, duration: 45, ease: `linear` })
        ])
    }

    createDemoTimeline() {
        return gsap.timeline({ paused: true, onUpdate: () => this.screen.update(), repeat: -1 }).add(() => {
            this.afterimgPass.uniforms.damp.value = 0 // 残像リセット
            this.screen.object.position.set(-1.2, 0.5, -0.5)
        }).add([
            this.tween(this.screen, { time: 0 }, { time: 1, duration: 60, ease: `linear` })
        ]).add([
            this.tween(this.screen, { time: 1 }, { time: 0, duration: 60, ease: `linear` })
        ])
    }

    createOpenTimeline() {
        return this.createDemoTimeline()
    }

    render() {
        super.render()

        // 残像リセット解除
        if (this.afterimgPass.uniforms.damp.value === 0) {
            this.afterimgPass.uniforms.damp.value = 0.97
        }
    }
}

export default Type07