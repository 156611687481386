export default `
uniform float deltaTime;

void main() {
    vec2 uv = gl_FragCoord.xy / resolution.xy;
    vec3 pos = texture2D( texturePosition, uv ).xyz;

    pos.y = mod( pos.y + 15.0 * deltaTime, 512.0);

    gl_FragColor = vec4( pos, 1.0 );
}
`