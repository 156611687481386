import gsap from 'gsap'

import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

import AbstractAnimation from '../abstract'

import NoiseMap from '../../shaders/noisemap/fbm3'

import Char from '../char'

import scent_vert from './shaders/scent_vert.glsl'
import scent_frag from './shaders/scent_frag.glsl'


class Type15 extends AbstractAnimation {

    START_TIME = Date.now()

    constructor(renderer) {
        super(renderer, { far: 4 })

        this.noiseMap = new NoiseMap(renderer, 2048, 100 * Math.random())

        this.shaderPass = new ShaderPass({
            uniforms: {
                tDiffuse: { value: null },
                noiseMap: { value: null },
                time: { value: 0.0 },
                seek: { value: 1.0 }
            },
            vertexShader: scent_vert,
            fragmentShader: scent_frag
        })

        this.shaderPass.uniforms.noiseMap.value = this.noiseMap.texture

        this.composer.addPass(this.renderPass)
        this.composer.addPass(this.shaderPass)
        this.composer.addPass(this.fxaaPass)

        const text = [
            `五月待つ`,
            `花橘の`,
            `香をかげば`,
            `むかしの人の`,
            `袖の香ぞする`
        ]

        this.lines = text.map((chars) => {
            return Array.from(chars).map((text) => {
                const char = new Char(text, { size: 0.08, height: 0, face: { color: 0xffffff, opacity: 1.0 }, line: false })
                this.scene.add(char.object)
                return char
            })
        })

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
    }

    createMainTimeline() {
        return gsap.timeline({ paused: true, onUpdate: () => this.update() }).add(() => {
            this.shaderPass.uniforms.seek.value = 1
        }).add([
            this.tween({}, { duration: 20, ease: `linear` }),
            this.tween(this.shaderPass.uniforms.seek, { value: 0, duration: 3, ease: `power2.inOut`, delay: 3 })
        ])
    }

    createDemoTimeline() {
        return gsap.timeline({ paused: true, onUpdate: () => this.update(), repeat: -1 }).add(() => {
            this.shaderPass.uniforms.seek.value = 1
        }).add([
            this.tween({}, { duration: 10, ease: `linear` }),
            this.tween(this.shaderPass.uniforms.seek, { value: 0, duration: 3, ease: `power2.inOut`, delay: 3 })
        ]).add([
            this.tween(this.shaderPass.uniforms.seek, { value: 1, duration: 3, ease: `power2.inOut` })
        ])
    }

    createOpenTimeline() {
        return gsap.timeline({ paused: true, onUpdate: () => this.update() }).add(() => {
            this.shaderPass.uniforms.seek.value = 1
        }).add([
            this.tween(this.shaderPass.uniforms.seek, { value: 0, duration: 2, ease: `power2.inOut` })
        ])
    }

    resize(width, height) {
        if (width > height) {
            this.camera.position.set(0, 0, 2.2)
        } else {
            this.camera.position.set(0, 0, 3.5)
        }

        this.camera.lookAt(0, 0, 0)

        const offset = width > height ? [0, 3, 2, 4, 6] : [0, 1, 0, 1, 2]
        const baseX = width > height ? 5.5 : 3.5

        this.lines.forEach((chars, i) => {
            chars.forEach((char, j) => {
                char.x = 0.140 * (j - baseX) + offset[i] * 0.14
                char.y = 0.200 * (2 - i)
            })
        })

        super.resize(width, height)
    }

    update() {
        this.shaderPass.uniforms.time.value = (Date.now() - this.START_TIME) * 0.001
    }
}

export default Type15