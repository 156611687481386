import { Fog } from 'three'

import gsap from 'gsap'

import { World } from 'oimo'

import AbstractAnimation from '../abstract'

import Char from '../char'


class Type14 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer, { playlist_size: 1.0 })

        this.camera.position.set(1000, 1500, 1000)
        this.camera.lookAt(0, 0, 0)

        this.scene.fog = new Fog(0x000000, 2500, 3500)

        this.composer.addPass(this.renderPass)

        const tanka = [
            `葎生ひて`,
            `荒れたる宿の`,
            `うれたきは`,
            `かりにも鬼の`,
            `すだくなりけり`
        ]

        this.chars = []

        this.originalChars = tanka.reduce((carry, chars, i) => {
            return Array.from(chars).reduce((carry, text, j) => {
                const param = {
                    t: text,
                    x: 250 * (2 - i),
                    y: 0,
                    z: 200 * (j - 3) 
                }

                // 下地文字
                const char = new Char(text, { size: 120, height: 1, face: { color: 0xffffff, opacity: 1.0 }, line: false })
                char.x = param.x
                char.y = param.y
                char.z = param.z
                char.object.rotation.x = -0.5 * Math.PI
                this.scene.add(char.object)

                carry.push(param)

                // オープニング表示用の文字
                const _char = new Char(text, { size: 120, height: 120, face: false, line: { color: 0x646464, opacity: 0.6 } })
                _char.x = param.x
                _char.y = param.y + 60
                _char.z = param.z
                _char.object.rotation.x = - Math.PI / 2
                this.chars.push(_char)
                this.scene.add(_char.object)

                return carry
            }, carry)
        }, [])

        this.world = new World({
            broadphase: 3,
            worldscale: 1,
            gravity: [0, -600, 0]
        })

        this.ground = this.world.add({
            type: 'box',
            size: [1500, 100, 1500],
            pos: [0, -50, 0],
            friction: 1.0,
            restitution: 0.1,
        })

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createMainTimeline() {
        return gsap.timeline({ paused: true, repeat: 5 }).add(() => {
            this.originalChars.forEach((param) => {

                const char = new Char(param.t, { size: 120, height: 120, face: false, line: { color: 0x646464, opacity: 0.6 } })

                char.body = this.world.add({
                    type: 'box',
                    size: [
                        char.geometry.boundingBox.max.x - char.geometry.boundingBox.min.x,
                        char.geometry.boundingBox.max.y - char.geometry.boundingBox.min.y,
                        char.geometry.boundingBox.max.z - char.geometry.boundingBox.min.z
                    ],
                    pos: [
                        param.x,
                        param.y,
                        param.z,
                    ],
                    rot: [-90, 0, 0],
                    move: true,
                    friction: 0.01,
                    restitution: 0.7,
                })

                this.chars.push(char)
                this.scene.add(char.object)

                this.tween(char, { opacity: 0 }, { opacity: 1, duration: 0.5, ease: `power2.in` })
            })
        }).add([
            this.tween({}, { duration: 3.0 }) // delay
        ])
    }

    createDemoTimeline() {
        return this.createMainTimeline().repeat(-1)
    }

    createOpenTimeline() {
        return gsap.timeline({ paused: true }).add([
            this.tween({}, { duration: 3.0 })
        ])
    }

    clearChars() {
        this.chars.forEach((char) => {
            this.scene.remove(char.object)
            
            if (char.body) {
                char.body.dispose()
                char.body = null
            }
        })
    }

    startMain() {
        this.clearChars()
        return super.startMain()
    }

    startDemo() {
        this.clearChars()
        return super.startDemo()
    }

    render(deltaTime) {
        this.world.step(deltaTime)

        this.chars.forEach((char) => {
            if (char.body) {
                char.object.position.copy(char.body.getPosition())
                char.object.quaternion.copy(char.body.getQuaternion())
            }
        })

        // 落ちて見えなくなったものは削除
        for (let i=0; i<this.chars.length;) {
            if (this.chars[i].y < -2000) {
                this.scene.remove(this.chars[i].object)
                this.chars[i].dispose()
                this.chars[i].body.dispose()
                this.chars.splice(i, 1)
            } else {
                i++
            }
        }

        this.composer.render()
    }

    resize(width, height) {
        const scale = width > height ? 1.0 : 1.8
        
        this.camera.position.x = 1000 * scale
        this.camera.position.y = 1500 * scale
        this.camera.position.z = 1000 * scale
        this.scene.fog.near = 2500 * scale
        this.scene.fog.far = 3500 * scale

        super.resize(width, height)
    }
}

export default Type14