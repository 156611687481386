import React, { useCallback } from 'react'

import Link from '../link/index'

import $ from 'jquery'

import Polaris from 'src/lib/Polaris'


const Anchor = (props) => {

    const scroll = useCallback((e) => {
        e.preventDefault()
        const currentTop = document.body.scrollTop || document.documentElement.scrollTop
        const offsetTop = props.id ? $(`#${props.id}`).offset().top : 0
        const duration = Polaris.util.clamp(0.8 * Math.abs(offsetTop - currentTop), 200, 1000)
        $('html,body').stop().animate({ scrollTop: offsetTop }, duration, 'ioExp')
    }, [props.id])

    return (
        <Link href={'#' + props.id} onClick={scroll} className={props.className}>
            {props.children}
        </Link>
    )
}

export default Anchor
