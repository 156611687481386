export default `
uniform bool sampling;
uniform float deltaTime;
uniform float seek;

float random(vec2 uv, float seed) {
    return fract(sin(dot(gl_FragCoord.stp + seed, vec3(uv, 0.0))) * 43758.5453 + seed);
}

float aaa(float x0, float x1, float t) {
    return x0 + (x1 - x0) * clamp(t, 0.0, 1.0);
}

void main() {
    vec2 uv = gl_FragCoord.xy / resolution.xy;

    if (sampling) {
        vec4 pos = texture2D( textureSampling, uv );
        pos.y = 0.0;
        gl_FragColor = pos;
    } else {
        if (seek < 1.0) {
            vec4 pos = texture2D( textureSampling, uv );
            pos.y = aaa(pos.y - 50.0 - 100.0 * random(pos.xy, 1.0), pos.y, seek);
            gl_FragColor = pos;
        } else {
            vec4 pos = texture2D( texturePosition, uv );
            vec4 vel = texture2D( textureVelocity, uv );
            pos += vel * deltaTime;
            gl_FragColor = pos;
        }
    }
}
`