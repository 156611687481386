import * as THREE from 'three'

import { GPUComputationRenderer } from 'three/examples/jsm/misc/GPUComputationRenderer.js'

import compute_position from './shaders/compute_position.glsl'

import menu_vert from './shaders/menu_vert.glsl'
import menu_frag from './shaders/menu_frag.glsl'


class Menu {

    SIZE = Math.pow(2, 5) - 1

    constructor(renderer) {
        this.gpgpu = new GPUComputationRenderer(this.SIZE + 1, this.SIZE + 1, renderer)

        this.positionVariable = this.gpgpu.addVariable('texturePosition', compute_position, this.initTexture())

        this.positionVariable.material.uniforms['time'] = { value: 0 }

        this.gpgpu.setVariableDependencies(this.positionVariable, [this.positionVariable])
        this.gpgpu.init()

        this.object = new THREE.Mesh(new THREE.PlaneBufferGeometry(2, 2, this.SIZE + 1, this.SIZE + 1), new THREE.ShaderMaterial({
            uniforms: {
                positionTexture: { value: null },
                opacity: { value: 1 }
            },
            transparent: true,
            vertexShader: menu_vert,
            fragmentShader: menu_frag
        }))
    }

    // set opacity(opacity) {
    //     this.material.uniforms.opacity.value = opacity
    // }

    // get opacity() {
    //     return this.material.uniforms.opacity.value
    // }

    initTexture() {
        const texture = this.gpgpu.createTexture()

        for (let y = 0, k = 0; y <= this.SIZE; y++) {
            for (let x = 0; x <= this.SIZE; x++) {
                texture.image.data[k + 0] = (2 * x - this.SIZE) / this.SIZE
                texture.image.data[k + 1] = (2 * y - this.SIZE) / this.SIZE
                texture.image.data[k + 2] = 0
                texture.image.data[k + 3] = 0
                k += 4
            }
        }

        return texture
    }

    resize(width, height, cameraZ, cameraAspect) {
        const _h = (cameraZ - this.object.position.z) * cameraAspect
        this.object.scale.y = _h
        this.object.scale.x = _h / height * width
    }

    tick(deltaTime, time) {
        this.gpgpu.compute()
        this.object.material.uniforms.positionTexture.value = this.gpgpu.getCurrentRenderTarget(this.positionVariable).texture
    }
}

export default Menu