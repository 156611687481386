import { Object3D } from 'three'

import gsap from 'gsap'

import AbstractAnimation from '../abstract'

import Char from '../char'


class Type09 extends AbstractAnimation {

    constructor(renderer) {
        super(renderer, { playlist_size: 1.0, near: 0.5, far: 7 })

        this.camera.position.set(0.5, 0.5, 0)

        this.composer.addPass(this.renderPass)

        const text = `あづさ弓ま弓つき弓年を経てわがせしがごとうるはしみせよ`

        this.dominoSize = 0.25
        this.dominoGap = this.dominoSize / 2 * Math.sqrt(3)
        this.totalLength = 0

        this.dominos = Array.from(text).map((text, i) => {
            const char = new Char(text, { size: 0.200, height: 0.050, face: { color: 0x000000, opacity: 0.9 }, line: { color: 0x808080 } })
            const domino = new Object3D()

            const realHeight = char.geometry.boundingBox.max.y - char.geometry.boundingBox.min.y

            char.object.position.y = realHeight / 2
            char.object.position.z = 0.025
            domino.position.z = this.totalLength - realHeight * 0.8 - 0.050

            this.totalLength = domino.position.z

            domino.add(char.object)
            this.scene.add(domino)

            return domino
        })

        this.mainTimeline = this.createMainTimeline()
        this.demoTimeline = this.createDemoTimeline()
        this.openTimeline = this.createOpenTimeline()
        this.render()
    }

    createMainTimeline() {
        const timeStep = 0.8

        return gsap.timeline({ paused: true, onUpdate: () => this.update() }).add(() => {
            this.dominos.forEach((domino, i) => {
                domino.rotation.x = 0
                domino.visible = true
            })
        }).add([
            this.tween(this.camera.position, { z: 1 }, { z: 1 + this.totalLength - 0.5, duration: (this.dominos.length + 2) * timeStep, ease: `linear` }),

            this.dominos.map((domino, i) => {
                const timeline = gsap.timeline()

                if (i === this.dominos.length - 1) {
                    timeline.add([
                        this.tween(domino.rotation, { x: - 90 / 180 * Math.PI, duration: timeStep, ease: `power2.in`, delay: i * timeStep })
                    ])
                } else {
                    timeline.add([
                        this.tween(domino.rotation, { x: - 45 / 180 * Math.PI, duration: timeStep, ease: `power2.in`, delay: i * timeStep })
                    ]).add([
                        this.tween(domino.rotation, { x: - 75 / 180 * Math.PI, duration: timeStep, ease: `power2.in` })
                    ])
                }
                return timeline
            })
        ])
    }

    createDemoTimeline() {
        return this.createMainTimeline().repeat(-1)
    }

    createOpenTimeline() {
        return this.createMainTimeline()
    }

    update() {
        this.camera.lookAt(0, 0, this.camera.position.z - 1)
    }
}

export default Type09