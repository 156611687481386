import React, { useContext } from 'react'

import { SwitchTransition, CSSTransition } from 'react-transition-group'

import { StatesContext } from 'src/hooks/states/context'

import Marquee from './marquee'

import styles from './nowplaying.module.scss'


const NowPlaying = () => {

    const { currentText } = useContext(StatesContext)

    return (
        <div className={styles.nowplaying}>
            <em>Now Playing</em>

            <div className={styles.current}>
                <SwitchTransition>
                    <CSSTransition key={currentText} timeout={300} classNames={styles}>
                        <Marquee>{currentText}</Marquee>
                    </CSSTransition>
                </SwitchTransition>
            </div>
        </div>
    )
}

export default NowPlaying